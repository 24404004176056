import React from 'react';

class ExternalLink extends React.Component {

    render() {
        var target = '_blank';
        var href = this.props.href;

        if (this.props.href.trim() === '' || this.props.href.trim() === '#') {
            var defaultUrl = window.location.href.includes('#') ? window.location.href : window.location.href + '#';
            target = '_top';
            href = defaultUrl;
        }

        return (
            <a href={href} target={target} className={this.props.className}>{this.props.children}</a>
        );
    }
}

export default ExternalLink;