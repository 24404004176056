import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoadingButton from './LoadingButton';
import T from 'i18n-react';

class ConfirmModal extends React.Component {

    render() {
        return (

            <Modal className="quick-pay-modal" isOpen={this.props.open} size="lg">
                <ModalHeader toggle={this.props.onToggle} className="bg-primary"><span className="text-white">{this.props.title}</span></ModalHeader>
                <ModalBody>

                    <div className="alert alert-primary p-4 fade in alert-dismissible show d-flex">
                        <div className="d-flex">
                            <FontAwesomeIcon className="mr-3" size="2x" icon={faExclamationCircle} />
                            <div>
                                {this.props.children}
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    {this.props.onToggle !== undefined && <button type="button" className="btn pull-right" onClick={this.props.onToggle}>{T.translate('common.close')}</button>}
                    {this.props.onConfirm !== undefined &&
                        <LoadingButton
                            className="btn btn-primary pull-right"
                            disabled={this.props.loading}
                            loading={this.props.loading ? 'true' : 'false'}
                            onClick={this.props.onConfirm}>
                            {this.props.confirmButtonText}
                        </LoadingButton>}
                </ModalFooter>
            </Modal>

        );
    }
}

export default ConfirmModal;