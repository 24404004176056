import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as CommonState from '../../store/Common';

require(`../../css/${process.env.REACT_APP_CLIENT}/style.css`);
require(`../../css/${process.env.REACT_APP_CLIENT}/responsive.css`);
require(`../../fonts/${process.env.REACT_APP_CLIENT}/fonts.css`);

export const AppRoute = ({ component: Component, layout: Layout, internal, ...rest }) => {

    if (internal && !CommonState.secureStorage.getItem('accountID')) {
        return <Redirect to={{ pathname: '/' }} />;
    } else {
        return (
            <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />
        );
    }
};