import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as PaymentStore from '../../store/Payment';
import PaymentSidebar from './PaymentSidebar';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';
import T from 'i18n-react';

const Scroll = require('react-scroll');
const sha512 = require('js-sha512');

class PayCrypto extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cryptoPayUrl: ''
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('accountPay.pageTitleCrypto');

        this.setCryptoPayUrl();
    }

    componentWillReceiveProps(nextProps) {
        this.setCryptoPayUrl();
    }

    setCryptoPayUrl = () => {

        try {

            if (this.props.account.accountNumber) {
                var accountNumber = this.props.account.accountNumber;
                var amount = this.props.account.accountBalance >= 0 ? 0 : this.props.account.accountBalance * -1;

                var hashed = sha512(`${accountNumber}_${amount}_MYACCT`);
                var link = `${this.props.cryptoPaymentEndpoint}?customer_id=${accountNumber}&usd_amount=${amount}&checksum=${hashed}`;

                this.setState({ cryptoPayUrl: link });
            }

        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div className="payment-section">

                <div className="left-sidebar">
                    <div className="p-main">

                        <LoadingOverlay loading={this.props.paymentLoading} />

                        <div className="page-header">
                            <div className="page-title">{T.translate('accountPay.pageTitleCrypto')}</div>
                            <p className="lead text-dark">{T.translate('accountPay.welcomePaymentSystemCrypto')}</p>
                        </div>

                        <div className="diverter" />

                        {this.props.cryptoPaymentEndpoint && <a className="btn btn-primary" target="_blank" href={this.state.cryptoPayUrl}>{T.translate('accountPay.pageTitleCrypto')}</a>}

                        <MessageError>{this.props.paymentErrorMessage}</MessageError>

                    </div>
                </div>

                <PaymentSidebar />

            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common, ...state.payment }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators }, dispatch)
)(PayCrypto);
