import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../store/Account';
import * as CommonStore from '../store/Common';
import * as UserStore from '../store/User';
import LoadingOverlay from './shared/LoadingOverlay';
import LoadingButton from './shared/LoadingButton';
import MessageError from './shared/MessageError';

const Scroll = require('react-scroll');
const qs = require('querystringify');

class LoginByToken extends Component {

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('autoLogin.title');

        this.props.clearUserState();

        if (this.props.initiated) {
            this.loginByToken();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.initiated !== prevProps.initiated) {
            this.loginByToken();
        }
    }

    loginByToken = () => {
        const params = qs.parse(this.props.location.search);
        if (params.jwt) {
            this.props.loginByToken({ token: params.jwt });
        }
    }

    getMessageError = () => {
        if (this.props.accountSourceError === 'ExpiredToken') {
            var textToAppend = T.translate('autoLogin.manualLogin');
            return this.props.accountErrorMessage + ' ' + textToAppend;
        }

        return this.props.accountErrorMessage;
    }

    render() {
        return (
            <div className="my-account-md-container">
                <div className="my-account-md-content">
                    <div className="page-header">
                        <div className="page-title">{T.translate('autoLogin.pageTitle')}</div>
                    </div>

                    <div className="diverter" />

                    <p>{T.translate('autoLogin.loggingIn')}</p>

                    <LoadingOverlay loading={this.props.accountLoading} />

                    <MessageError>{this.getMessageError()}</MessageError>
                    {this.props.accountErrorMessage && <NavLink to="/" className="a-link ml-0 ml-sm-4 mt-3 mt-sm-0 d-block d-sm-inline-block">{T.translate('forgot.backToLogin')}</NavLink>}

                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.common, ...state.account, ...state.user }),
    dispatch => bindActionCreators({ ...CommonStore.actionCreators, ...AccountStore.actionCreators, ...UserStore.actionCreators }, dispatch)
)(LoginByToken);