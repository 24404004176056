import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonStore from '../store/Common';
import * as UserStore from '../store/User';
import LoadingOverlay from './shared/LoadingOverlay';
import MessageError from './shared/MessageError';

const Scroll = require('react-scroll');
const qs = require('querystringify');

class EnrollmentVerification extends Component {

    constructor(props) {
        super(props);

        const params = qs.parse(props.location.search);

        this.state = {
            verificationCode: params.t
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('enrollmentVerification.title');
    }

    componentDidUpdate(prevProps) {
        if (this.props.initiated !== prevProps.initiated) {
            this.props.validateEnrollmentCode(this.state);
        }
    }

    getErrorMessage = () => {

        if (this.props.userErrorMessage === 'Invalid Token') {
            return T.translate('enrollmentVerification.invalidToken');
        }

        return this.props.userErrorMessage;
    }

    getErrorMessage = () => {

        if (this.props.userErrorMessage === 'Invalid Token') {
            return T.translate('enrollmentVerification.invalidToken');
        }
        else if (this.props.userErrorMessage === 'Enrollment Verification Link expired') {
            return T.translate('enrollmentVerification.expiredToken');
        }

        return this.props.userErrorMessage;
    }

    render() {
        return (
            <div className="my-account-md-container">
                <div className="my-account-md-content">
                    <div className="page-header">
                        <div className="page-title">{T.translate('enrollmentVerification.myAccount')}</div>
                    </div>
                    <div className="diverter" />
                    <h6 className="card-title mb-3">{T.translate('enrollmentVerification.pageTitle')}</h6>

                    {this.props.verifiedEnrollment &&
                        <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                            <div className="d-flex">
                                <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                <p>
                                    {T.translate('enrollmentVerification.verificationCompleted')}
                                </p>
                            </div>
                        </div>
                    }

                    <LoadingOverlay loading={this.props.commonLoading || this.props.userLoading} />

                    <MessageError>{this.getErrorMessage()}</MessageError>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(EnrollmentVerification);
