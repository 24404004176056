import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserStore from '../../store/User';
import * as CommonStore from '../../store/Common';
import * as AccountStore from '../../store/Account';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';
import DataGrid from '../shared/DataGrid';
import T from 'i18n-react';

const Scroll = require('react-scroll');

class TransactionHistory extends Component {

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('transactionHistory.title');

        if (this.props.account.accountNumber) {
            this.props.getTransactiondHistory();
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getTransactiondHistory();
        }
    }

    onExpandableContentBinding = (row) => {
        row.formattedDate = CommonStore.formatDate(row.date, 'MM/DD/YYYY');
        //TODO: Place this in render method if it's not complex
        return (<React.Fragment>
            <td colSpan="3">
                <table className="table-alternating-odd">
                    <tbody>
                        <tr>
                            <td className="td-padding-left" style={{ width: "50%" }}>{T.translate('transactionHistory.dailyUsage', row)}</td>
                            <td className="td-border-left td-numeric">{CommonStore.formatDecimal(row.totalUsage)}</td>
                            <td className="td-border-left" style={{ width: "26%" }}>&nbsp;</td>
                        </tr>
                        {row.averageRate !== 0 && row.averageRate !== 'NaN' && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.avgRate')}</td>
                            <td className="td-border-left td-numeric" >{CommonStore.formatDecimal(row.avgRate) + '¢'}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                        {row.totalTD !== 0 && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.td')}</td>
                            <td className="td-border-left td-numeric" >{'$' + CommonStore.formatDecimal(row.totalTD)}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                        {row.totalPromotion !== 0 && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.promotions')}</td>
                            <td className="td-border-left td-numeric" >{'$' + CommonStore.formatDecimal(row.totalPromotion)}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                        {row.totalReconciliation !== 0 && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.reconciliation')}</td>
                            <td className="td-border-left td-numeric" >{'$' + CommonStore.formatDecimal(row.totalReconciliation)}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                        {row.totalMisc !== 0 && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.misc')}</td>
                            <td className="td-border-left td-numeric" >{'$' + CommonStore.formatDecimal(row.totalMisc)}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                        {row.totalTax !== 0 && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.tax')}</td>
                            <td className="td-border-left td-numeric" >{'$' + CommonStore.formatDecimal(row.totalTax)}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                        {row.changeTotal !== 0 && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.chargeTotal')}</td>
                            <td className="td-border-left td-numeric" >{'$' + CommonStore.formatDecimal(row.changeTotal)}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                        {row.totalDebtRecovery !== 0 && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.debtRecovery')}</td>
                            <td className="td-border-left td-numeric" >{'$' + CommonStore.formatDecimal(row.totalDebtRecovery)}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                        {row.totalAdjustment !== 0 && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.adjustments')}</td>
                            <td className="td-border-left td-numeric" >{'$' + CommonStore.formatDecimal(row.totalAdjustment)}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                        {row.totalPayments !== 0 && <tr>
                            <td className="td-padding-left">{T.translate('transactionHistory.payments')}</td>
                            <td className="td-border-left td-numeric" >{'$' + CommonStore.formatDecimal(row.totalPayments)}</td>
                            <td className="td-border-left">&nbsp;</td>
                        </tr>}
                    </tbody>
                </table>
            </td>
        </React.Fragment >);
    }

    render() {

        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('transactionHistory.pageTitle')}</div>
                </div>
                <div className="diverter" />

                <MessageError>{this.props.accountErrorMessage}</MessageError>

                {this.props.transactionsHistory.length > 0 ? <p>{T.translate('common.displayingLastMonth')}</p> : ""}

                <DataGrid usePaging={true} dataKey="accountTransactionID" downloadData={true} expandibleContent={true} pageSize="15"
                    onExpandableContentBinding={this.onExpandableContentBinding} className="table-alternating">
                    {{
                        rows: this.props.transactionsHistory,
                        columns: [
                            { columnId: 'date', displayText: T.translate('transactionHistory.colTransactionDate'), dataType: 'date', format: 'MM/dd/yyyy', bold: true, italic: true, style: { width: '50%' } },
                            { columnId: 'total', displayText: T.translate('transactionHistory.colAmount'), dataType: 'decimal', className: 'td-border-left td-numeric', headerClass: 'td-border-left' },
                            { columnId: 'accountBalance', displayText: T.translate('transactionHistory.colBalance'), dataType: 'currency', className: 'td-border-left td-numeric', headerClass: 'td-border-left', bold: true, style: { width: '25%' } }
                        ]
                    }}
                </DataGrid>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(TransactionHistory);
