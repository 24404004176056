import T from 'i18n-react';
import React from 'react';
import zxcvbn from 'zxcvbn';
import { Tooltip } from 'reactstrap';

class PasswordInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'password',
            score: 'null',
            value: '',
            tooltipOpen: false
        };
    }

    showHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input'
        });
    }

    passwordStrength = (e) => {
        this.setState({ value: e.target.value });

        if (this.props.onChange) {
            this.props.onChange(e);
        }

        if (e.target.value === '') {
            this.setState({ score: 'null' });
        } else {
            var pw = zxcvbn(e.target.value);
            this.setState({ score: pw.score });
        }
    }

    getValue = () => {
        if (this.state.value !== '') {
            return this.state.value;
        }

        if (this.props.value && this.props.value !== '' && this.props.value !== this.state.value) {
            this.setState({ value: this.props.value });
        }

        return this.props.value;
    }

    render() {

        return (
                <div className="password">
                    <label htmlFor={this.props.id}>{this.props.labelText}</label>
                    <input type={this.state.type} className={"form-control password__input " + this.props.className} onChange={this.passwordStrength} maxLength={this.props.maxLength} id={this.props.id} name={this.props.id} checked={this.props.checked} value={this.getValue()} autoComplete="new-password" />
                    <span className="password__show" onClick={this.showHide}>{this.state.type === 'input' ? T.translate('common.hidePassword') : T.translate('common.showPassword')}</span>
                    {this.props.showPasswordRules && <span id="password__strength" className="password__strength" data-score={this.state.score} onMouseEnter={this.props.showTooltip} onMouseLeave={this.props.hideTooltip} />}
                    <div className="invalid-feedback">{this.props.messageError}</div>

                    {this.props.showPasswordRules && <Tooltip placement="bottom-end" isOpen={this.props.tooltipOpen} target="password__strength">
                        <ul>
                            <li>{T.translate('common.eightCharactersMinimum')}</li>
                            <li>{T.translate('common.oneUppercaseCharacter')}</li>
                            <li>{T.translate('common.oneNumber')}</li>
                            <li>{T.translate('common.cannotContainSpecialCharacters')}</li>
                        </ul>
                    </Tooltip>}

                </div>

        );
    }
}

export default PasswordInput;