import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


class MessageError extends React.Component {
    render() {
        return (
            <div>
                {this.props.children && <div className="alert bg-danger text-white mb-4 fade in show show-error">
                    <div className="d-flex align-self-center"> <FontAwesomeIcon size="2x" className="mr-3" icon={faExclamationCircle} />
                        <div className="d-flex align-self-center">{this.props.children}</div>
                    </div>
                </div>}
            </div>

        );
    }
}

export default MessageError;