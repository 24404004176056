import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as UserStore from '../../store/User';
import * as CommonStore from '../../store/Common';

const Scroll = require('react-scroll');
const eligible = require('../../images/eligible.png');

class PaymentPlan extends Component {

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = 'SmartGridCIS | Payment Plan';
    }

    render() {
        return (
            <div className="p-main">
                <div className="page-header">
                    <div className="page-title">Payment plan</div>
                </div>
                <div className="card bl-green mb-4 mb-sm-5 bl-br">
                    <div className="card-body pay-echeck">
                        <img src={eligible} alt="eligible" />
                        <div>
                            <h6 className="card-title text-success">You're eligible for a Deferred Payment Plan at this time</h6>
                            <p className="lead mb-4"><em>We can move half of your negative balance to a Deferred Payment Plan (DPP) and apply a temporary credit to your account to be paid back weekly. You will need to initiate a payment to cover half of the negative amount you owe in order to qualify for a DPP.</em></p>
                            <NavLink to="/myaccount/dashboard" className="btn btn-primary">request a payment plan</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => state.user,
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(PaymentPlan);
