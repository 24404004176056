import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import LoadingOverlay from '../shared/LoadingOverlay';

const Scroll = require('react-scroll');

class MemberInfo extends Component {

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('memberInfo.title');

        if (this.props.account.accountNumber) {
            this.props.getMemberInfo();
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getMemberInfo();
        }
    }

    render() {
        return (
            <div className="p-main">
                <div className="left-sidebar">
                    <div className="p-main">

                        <LoadingOverlay loading={this.props.accountLoading} />

                        <div className="page-header">
                            <div className="page-title">{T.translate('memberInfo.pageTitle')}</div>

                        </div>

                        {this.props.member &&
                            <div className="card mb-5">
                                <div className="card-body">

                                    <h6 className="card-title">{T.translate('memberInfo.basicData')}</h6>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="row">
                                                        <div className="col-md-7 pt-2 pb-2">
                                                            <p className="mb-0">{T.translate('memberInfo.number')}:</p>
                                                        </div>
                                                        <div className="col-md-5 pt-2 pb-2">
                                                            <span className="text-dark font-weight-normal">{this.props.member.memberNumber}</span>
                                                        </div>
                                                        <div className="col-md-7 pt-2 pb-2">
                                                            <p className="mb-0">{T.translate('memberInfo.type')}:</p>
                                                        </div>
                                                        <div className="col-md-5 pt-2 pb-2">
                                                            <span className="text-dark font-weight-normal">{this.props.member.memberTypeName}</span>
                                                        </div>
                                                        <div className="col-md-7 pt-2 pb-2">
                                                            <p className="mb-0">{T.translate('memberInfo.status')}:</p>
                                                        </div>
                                                        <div className="col-md-5 pt-2 pb-2">
                                                            <span className="text-dark font-weight-normal">{this.props.member.memberStatusName}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <p />
                                    <h6 className="card-title">{T.translate('memberInfo.personData')}</h6>

                                    <div className="row">
                                        <div className="col-lg-12" >
                                            <div className="card mb-4">
                                                <div className="card-body">

                                                    {this.props.member.persons.map((person, i) =>
                                                        <React.Fragment key={i}>
                                                            <div className="row" >
                                                                <div className="col-md-6 align-self-center">
                                                                    <div className="row">
                                                                        <div className="col-md-12 pt-2 pb-2">
                                                                            <p className="mb-0">{T.translate(i === 0 ? 'memberInfo.primaryPerson' : 'memberInfo.secondaryPerson')}</p>
                                                                        </div>

                                                                        {person.businessName && <React.Fragment>
                                                                            <div className="col-md-6 pt-2 pb-2">
                                                                                <p className="mb-0">{T.translate('memberInfo.businessName')}:</p>
                                                                            </div>
                                                                            <div className="col-md-5 pt-2 pb-2">
                                                                                <span className="text-dark font-weight-normal">{person.businessName}</span>
                                                                            </div>
                                                                        </React.Fragment>}

                                                                        <div className="col-md-6 pt-2 pb-2">
                                                                            <p className="mb-0">{T.translate('memberInfo.firstName')}:</p>
                                                                        </div>
                                                                        <div className="col-md-5 pt-2 pb-2">
                                                                            <span className="text-dark font-weight-normal">{(person.businessName ? person.repFirstName : person.firstName)}</span>
                                                                        </div>

                                                                        <div className="col-md-6 pt-2 pb-2">
                                                                            <p className="mb-0">{T.translate('memberInfo.lastName')}:</p>
                                                                        </div>
                                                                        <div className="col-md-5 pt-2 pb-2">
                                                                            <span className="text-dark font-weight-normal">{(person.businessName ? person.repLastName : person.lastName)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.props.member.persons.length !== i + 1 && <hr />}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {!this.props.member && <div>{T.translate('memberInfo.memberNoPresent')}</div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common }),
    dispatch => bindActionCreators({ ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(MemberInfo);
