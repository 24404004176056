import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as UserStore from '../../store/User';
import LoadingOverlay from '../shared/LoadingOverlay';

const Scroll = require('react-scroll');
const planIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/plan-icon.png`);
const pdf = require('../../images/pdf.png');
const download = require('../../images/download.png');

class PlansDocuments extends Component {

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('plansDocuments.title');

        if (this.props.account.accountNumber) {
            this.props.getPlanAndDocuments();
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getPlanAndDocuments();
        }

        if (this.props.accountServicePointID > 0 && this.props.accountServicePointID !== nextProps.accountServicePointID) {
            //if account service point changed then get activity for it
            this.props.getPlanAndDocuments();
        }
    }

    getExternalRenewalUrl = () => {
        var renewalUrl = this.props.systemProperties.myAcctRenewalUrlRedirection.replace("{accountNumber}", this.props.account.accountNumber);
        return renewalUrl;
    }

    hasExternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection !== '') {
            return true;
        }
        return false;
    }

    hasInternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection === '') {
            return true;
        }
        return false;
    }

    hrefValue = (accountDocumentID) => {
        var token = this.props.getPrivateToken();
        var accountID = this.props.account.accountID;
        var url = 'api/data/Document?accountID=' + accountID + '&accountDocumentID=' + accountDocumentID + '&t=' + encodeURIComponent(token.replace('bearer ', ''));
        return url;
    }

    render() {
        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('plansDocuments.pageTitle')}</div>

                    {this.props.account.hasRenewalOffers && <div className="alert bg-success text-white mb-4">
                        <div className="d-flex align-self-center">
                            <FontAwesomeIcon size="2x" className="mr-3" icon={faCheckCircle} />
                            <div className="row d-flex align-self-center banner">
                                <div className="col-11">
                                    {T.translate('dashboard.renewalSuggestion', this.props.systemProperties)}
                                </div>
                                {this.hasInternalRenewalContent(this.props.account.hasRenewalOffers) && <div className="col-1">
                                    <NavLink to="/myaccount/renewal" className="btn btn-primary">{T.translate('dashboard.renewService')}</NavLink>
                                </div>}
                                {this.hasExternalRenewalContent(this.props.account.hasRenewalOffers) && <div className="col-1">
                                    {<a href={this.getExternalRenewalUrl()} target="_blank" className="btn btn-primary">{T.translate('dashboard.renewService')}</a>}
                                </div>}
                            </div>
                        </div>
                    </div>}
                </div>

                {this.props.serviceAgreement.serviceAgreementID && this.props.serviceAgreement.serviceAgreementID > 0 && <React.Fragment>
                    <div className="card mb-5">
                        <div className="card-body">
                            <div className="super-sever-flex-box position-relative">

                                <img src={planIcon} alt="plan icon" />
                                <div className="align-self-center">
                                    <h6 className="card-title">{this.props.serviceAgreement.productName}</h6>
                                </div>
                                {this.props.serviceAgreement.formattedEndDate !== '' && <div className="expires-right text-right">
                                    {T.translate('plansDocuments.expires')}
                                    <div className="expires-badge">{this.props.serviceAgreement.formattedEndDate}</div>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <h6 className="card-title pt-md-4">{T.translate('plansDocuments.planDocumentList')}</h6>
                    {this.props.accountDocuments.length === 0 && <div>{T.translate('common.noRecordsToDisplay')}</div>}
                    <ul className="plans-list">
                        {this.props.accountDocuments.map((doc, i) => (
                            <li key={i}>
                                <img src={pdf} alt="pdf" />
                                <div className="plans-txt">{doc.description}</div>
                                <a href={doc.documentURL ? doc.documentURL : this.hrefValue(doc.accountDocumentID) } className="link-btn ml-auto text-capitalize" target="_blank"><img src={download} alt={T.translate('common.download')} /><span>{T.translate('common.download')}</span></a>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>}
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(PlansDocuments);
