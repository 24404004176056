import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CommonStore from '../../store/Common';
import Select from 'react-select';
import Moment from 'moment';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faDownload, faStar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ExternalLink from '../shared/ExternalLink';
import { NavLink } from 'react-router-dom';
import BasicModal from './BasicModal';
import ConfirmModal from './ConfirmModal';

import T from 'i18n-react';

const download = require('../../images/download.png');


class SuggestedPayment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPrepaid: true,
            suggestedPaymentObject: {},
            showDepositInstallments: true,
            showDPPInstallments: true,
            showBills: true,
            showSuggestedPayment: true,
            showMinimumPayment: true,
            showCurrentAccountBalance: true,
            depositInstallments: [],
            selecteddepositInstallments: [],
            dPPs: [],
            selecteddPPs: [],
            bills: [],
            selectedbills: [],
            totalPayment: 0,
            minPaymentAmount: 0,
            suggestedPaymentAmount: 0,
            currentAccountBalance: 0,
            currentAvailableBalance: 0,
            otherAmount: 0,
            accountPaymentDistributions: [],
            amountOptionID: null,
            billsCheckboxSelected: false,
            suggestedPaymentCheckboxSelected: false,
            currentBalanceCheckboxSelected: false,
            minimumPaymentCheckboxSelected: false,
            otherCheckboxSelected: false,
            lastCheckboxClicked: "",
            otherAmountDisabled: true,
            infoCreditCardFeeModalVisible: false,
            paymentChargeFee: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        this.renderSuggestedPaymentComponent(nextProps);
        this.setState({ paymentChargeFee: nextProps.paymentChargeFee });
    }

    componentDidUpdate(props) {
    }

    renderSuggestedPaymentComponent(nextProps) {
        let isPrepaid = nextProps.account.accountBillingTypeID === 1;
        this.setState({ isPrepaid: isPrepaid });

        if (isPrepaid) {
            this.setState({ showDepositInstallments: false });
            this.setState({ showDPPInstallments: true });
            this.setState({ showBills: false });
            this.setState({ showSuggestedPayment: true });
            this.setState({ showMinimumPayment: true });
            this.setState({ showCurrentAccountBalance: false });
        } else {
            this.setState({ showDepositInstallments: true });
            this.setState({ showDPPInstallments: true });
            this.setState({ showBills: true });
            this.setState({ showSuggestedPayment: false });
            this.setState({ showMinimumPayment: false });
            this.setState({ showCurrentAccountBalance: true });
        }

        if (nextProps.suggestedPaymentObject && nextProps.suggestedPaymentObject.deposits && nextProps.suggestedPaymentObject.deposits.length) {
            let newDeposits = [];
            
            nextProps.suggestedPaymentObject.deposits.map(function (deposit) {

                if (deposit && deposit.accountDepositSchedule && deposit.accountDepositSchedule.length) {
                    deposit.accountDepositSchedule.map(function (depositInstallment) {
                        depositInstallment.label = Moment(depositInstallment.paymentDueDate).format('MM/DD/YY') + " - Installment #" + depositInstallment.installmentNumber + " $" + depositInstallment.amountDue + "/" + deposit.depositAmount;
                        depositInstallment.value = depositInstallment.accountDepositScheduleID;
                        newDeposits.push(depositInstallment);
                    });
                }
            });
            this.setState({ depositInstallments: newDeposits });
        }
        else {
            this.setState({ showDepositInstallments: false });
        }

        if (nextProps.suggestedPaymentObject && nextProps.suggestedPaymentObject.unpaidBills && nextProps.suggestedPaymentObject.unpaidBills.length) {
            let newBills = [];
            nextProps.suggestedPaymentObject.unpaidBills.map(function (bill) {
                if (bill.daysOverDue > 0) {
                    bill.label = Moment(bill.paymentDueDate).format('MM/DD/YY') + ' - Overdue in (' + bill.daysOverDue + ' days) - $' + bill.totalAmountDue;
                } else {
                    bill.label = Moment(bill.paymentDueDate).format('MM/DD/YY') + ' - $' + bill.totalAmountDue;
                }
                
                bill.value = bill.billID;
                newBills.push(bill);
            });
            this.setState({ bills: newBills });
        }
        else {
            this.setState({ showBills: false });
        }

        if (nextProps.suggestedPaymentObject && nextProps.suggestedPaymentObject.dpps && nextProps.suggestedPaymentObject.dpps.length) {
            let newDPPs = [];
            let dppInstallmentCounter = 0;
            nextProps.suggestedPaymentObject.dpps.map(function (dpp) {
                if (dpp && dpp.paymentSchedule && dpp.paymentSchedule.length) {
                    dpp.paymentSchedule.map(function (dppInstallment) {
                        dppInstallmentCounter++;
                        dppInstallment.label = Moment(dppInstallment.paymentDueDate).format('MM/DD/YY') + " - $" + dppInstallment.amountDue;
                        dppInstallment.value = dppInstallment.paymentArrangementScheduleID;
                        newDPPs.push(dppInstallment);
                    });
                }
            });
            this.setState({ dPPs: newDPPs });
        }
        else {
            this.setState({ showDPPInstallments: false });
        }

        if (nextProps.suggestedPaymentObject && nextProps.suggestedPaymentObject.minPaymentAmount) {
            if (nextProps.suggestedPaymentObject.minPaymentAmount < 0) nextProps.suggestedPaymentObject.minPaymentAmount = nextProps.suggestedPaymentObject.minPaymentAmount * -1;
            this.setState({ minPaymentAmount: nextProps.suggestedPaymentObject.minPaymentAmount });
        } else {
            this.setState({ showMinimumPayment: false });
        }

        if (nextProps.suggestedPaymentObject && nextProps.suggestedPaymentObject.suggestedPaymentAmount) {
            if (nextProps.suggestedPaymentObject.suggestedPaymentAmount < 0) nextProps.suggestedPaymentObject.suggestedPaymentAmount = nextProps.suggestedPaymentObject.suggestedPaymentAmount * -1;
            this.setState({ suggestedPaymentAmount: nextProps.suggestedPaymentObject.suggestedPaymentAmount });
        } else {
            this.setState({ showSuggestedPayment: false });
        }

        if (nextProps.suggestedPaymentObject && nextProps.suggestedPaymentObject.currentAccountBalance && nextProps.suggestedPaymentObject.currentAccountBalance < 0) {            
            this.setState({ currentAccountBalance: nextProps.suggestedPaymentObject.currentAccountBalance * -1 });
        } else {
            this.setState({ showCurrentAccountBalance: false });
        }

        if (nextProps.suggestedPaymentObject && nextProps.suggestedPaymentObject.currentAvailableBalance) {
            if (nextProps.suggestedPaymentObject.currentAvailableBalance < 0) nextProps.suggestedPaymentObject.currentAvailableBalance = nextProps.suggestedPaymentObject.currentAvailableBalance * -1;
            this.setState({ currentAvailableBalance: nextProps.suggestedPaymentObject.currentAvailableBalance });
        }

        this.setState({ suggestedPaymentObject: nextProps.suggestedPaymentObject });
    }

    handleChange = (selectedOption) => {
        this.props.changeCreditCardPaymentAccount(selectedOption);
        this.setState({ anotherPaymentMethod: false });
    }

    onDPPSelectChange = (selectedOption) => {
        this.setState({ selecteddPPs: selectedOption }, function () {
            this.calculateTotalPaymentAmount();
        });
    }

    onDepositInstallmentsSelectChange = (selectedOption) => {
        this.setState({ selecteddepositInstallments: selectedOption }, function () {
            this.calculateTotalPaymentAmount();
        });
    }

    onBillSelectChange = (selectedOption) => {
        this.setState({ selectedbills: selectedOption }, function () {
            this.calculateTotalPaymentAmount();
        });
    }

    onChekboxChange = (e) => {
        let checkBoxName = e.target.name;
        let newValue = !this.state[checkBoxName]; 

        if (checkBoxName === "otherCheckboxSelected") {            
            if (newValue) {
                this.setState({ otherAmountDisabled: false });
            } else {
                this.setState({ otherAmount: 0, otherAmountDisabled: true });
            }
        }

        this.setState({ [checkBoxName]: newValue, lastCheckboxClicked: checkBoxName }, function () {
            this.handleExclusionLogic();      
        });
    }   

    onOtherAmountChange = (e) => {
        if (e.target.value >= 0) {
            this.setState({ otherAmount: e.target.value }, function () {
                this.calculateTotalPaymentAmount();
            });
        }       
    }  

    handleExclusionLogic() {
        let checkBoxJustClicked = this.state.lastCheckboxClicked;
        if (this.state.billsCheckboxSelected && checkBoxJustClicked === "billsCheckboxSelected") {
            this.setState({ suggestedPaymentCheckboxSelected: false, currentBalanceCheckboxSelected: false, minimumPaymentCheckboxSelected: false }, function () { this.calculateTotalPaymentAmount(); });
        } else if (this.state.suggestedPaymentCheckboxSelected && checkBoxJustClicked === "suggestedPaymentCheckboxSelected") {
            this.setState({ billsCheckboxSelected: false, currentBalanceCheckboxSelected: false, minimumPaymentCheckboxSelected: false }, function () { this.calculateTotalPaymentAmount(); });
        } else if (this.state.currentBalanceCheckboxSelected && checkBoxJustClicked === "currentBalanceCheckboxSelected") {
            this.setState({ billsCheckboxSelected: false, suggestedPaymentCheckboxSelected: false, minimumPaymentCheckboxSelected: false }, function () { this.calculateTotalPaymentAmount(); });
        } else if (this.state.minimumPaymentCheckboxSelected && checkBoxJustClicked === "minimumPaymentCheckboxSelected") {
            this.setState({ billsCheckboxSelected: false, currentBalanceCheckboxSelected: false, suggestedPaymentCheckboxSelected: false }, function () { this.calculateTotalPaymentAmount(); });
        } else {
            this.calculateTotalPaymentAmount();
        }
    }

    calculateTotalPaymentAmount() {
        let isPrepaid = this.props.account.accountBillingTypeID === 1;        
        let accountPaymentDistributions = [];

        let totalDeposits = 0;
        var deposits = this.state.selecteddepositInstallments;
        if (deposits && deposits.length) {
            deposits.map(function (deposit) {
                totalDeposits = totalDeposits + deposit.amountDue;
                accountPaymentDistributions.push(this.buildAccountPaymentDistributon(3, deposit.accountDepositScheduleID, deposit.amountDue));
            }, this);
        }

        let totalDpp = 0;
        let dpps = this.state.selecteddPPs;
        if (dpps && dpps.length) {
            dpps.map(function (dpp) {
                totalDpp = totalDpp + dpp.amountDue;
                accountPaymentDistributions.push(this.buildAccountPaymentDistributon(2, dpp.paymentArrangementScheduleID, dpp.amountDue));
            }, this);
        }

        let totalBillsAmount = 0;
        let bills = this.state.selectedbills;
        if (this.state.billsCheckboxSelected && bills && bills.length) {
            bills.map(function (bill) {
                totalBillsAmount = totalBillsAmount + bill.totalAmountDue;
                accountPaymentDistributions.push(this.buildAccountPaymentDistributon(1, bill.billID, bill.totalAmountDue));
            }, this);
        }

        let totalSuggestedAmount = 0;
        if (this.state.suggestedPaymentCheckboxSelected) {
            totalSuggestedAmount = this.state.suggestedPaymentAmount;
            accountPaymentDistributions.push(this.buildAccountPaymentDistributon(4, null, totalSuggestedAmount));
        }

        let totalCurrentBalanceAmount = 0;
        if (this.state.currentBalanceCheckboxSelected) {
            totalCurrentBalanceAmount = this.state.currentAccountBalance;
            accountPaymentDistributions.push(this.buildAccountPaymentDistributon(4, null, totalCurrentBalanceAmount));
        }

        let totalMinimumBalanceAmount = 0;
        if (this.state.minimumPaymentCheckboxSelected) {
            totalMinimumBalanceAmount = this.state.minPaymentAmount;
            accountPaymentDistributions.push(this.buildAccountPaymentDistributon(4, null, totalMinimumBalanceAmount));
        }

        let totalOtherAmount = 0;
        if (this.state.otherCheckboxSelected) {
            totalOtherAmount = parseFloat(this.state.otherAmount);
            if (totalOtherAmount > 0) {
                accountPaymentDistributions.push(this.buildAccountPaymentDistributon(4, null, totalOtherAmount));
            } else {
                totalOtherAmount = 0;
            }      
        }        

        let totalPaymentAmount = 0;

        if (isPrepaid) {
            totalPaymentAmount = totalDpp + totalSuggestedAmount + totalMinimumBalanceAmount + totalOtherAmount;
        } else {
            totalPaymentAmount = totalDeposits + totalDpp + totalBillsAmount + totalCurrentBalanceAmount + totalOtherAmount;
        }

        this.setState({ totalPayment: totalPaymentAmount }, function () {
            this.setState({ accountPaymentDistributions: accountPaymentDistributions }, function () {
                this.props.onFinishTotalAmountCalculation(totalPaymentAmount, accountPaymentDistributions);
            });
        });
    }

    buildAccountPaymentDistributon(paymentCategoryID, entityID, amount) {
        var accountPaymentDistribution = {
            accountPaymentDistributionStatusID: 1,
            paymentDistributionCategoryID: paymentCategoryID,
            distributeAmount: amount,
            paymentSourceID: 2
        };
        if (entityID) accountPaymentDistribution.paymentDistributionEntityID = entityID;
        return accountPaymentDistribution;
    }

    open = () => {
        this.setState({ infoCreditCardFeeModalVisible: true });
    }

    close = () => {
        this.setState({ infoCreditCardFeeModalVisible: false });
    }

    render() {

        return (
            <div>
                {this.state.showDepositInstallments === true && <div>
                    <span className="card-title">{T.translate('suggestedPayment.haveDepositsToPay')}</span>
                    <div className="row mt-3">
                        <div className="offset-md-1 col-md-2">
                            <div className="form-group">
                                <label htmlFor="nameOnAccount">{T.translate('suggestedPayment.deposit')}</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Select isMulti className="basic-multi-select" classNamePrefix="select" options={this.state.depositInstallments} onChange={this.onDepositInstallmentsSelectChange} />
                        </div>
                    </div>
                </div>}
                
                {this.state.isPrepaid === true && <span className="card-title mb-3">{T.translate('suggestedPayment.invoiceBalacancePayentTitlePrepay')}</span>}
                {this.state.isPrepaid === false && <span className="card-title mb-3">{T.translate('suggestedPayment.invoiceBalacancePayentTitlePostpay')}</span>}                

                {this.state.showBills === true && <div className="row mt-3">
                    
                    <div className="offset-md-1 col-md-2 mt-3 mb-3">
                        <div className="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" className="custom-control-input" id="billsCheckboxSelected" name="billsCheckboxSelected" checked={this.state.billsCheckboxSelected} onChange={this.onChekboxChange} />
                            <label className="custom-control-label" htmlFor="billsCheckboxSelected">{T.translate('suggestedPayment.invoice')}</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <Select isMulti className="basic-multi-select flex-grow-1 " classNamePrefix="select" options={this.state.bills} onChange={this.onBillSelectChange} />
                    </div>
                </div>}

                {this.state.showSuggestedPayment === true && <div className="row ">
                    <div className="offset-md-1 col-md-8 mt-3 mb-3">
                        <div className="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" className="custom-control-input" id="suggestedPaymentCheckboxSelected" name="suggestedPaymentCheckboxSelected" checked={this.state.suggestedPaymentCheckboxSelected} onChange={this.onChekboxChange} />
                            <label className="custom-control-label" htmlFor="suggestedPaymentCheckboxSelected">{T.translate('suggestedPayment.suggestedPayment')}: ${CommonStore.formatDecimal(this.state.suggestedPaymentAmount)} </label>
                        </div>
                    </div>
                </div>}

                {this.state.showCurrentAccountBalance === true && <div className="row">
                    <div className="offset-md-1 col-md-8 mt-3 mb-3">
                        <div className="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" className="custom-control-input" id="currentBalanceCheckboxSelected" name="currentBalanceCheckboxSelected" checked={this.state.currentBalanceCheckboxSelected} onChange={this.onChekboxChange} />
                            <label className="custom-control-label" htmlFor="currentBalanceCheckboxSelected">{T.translate('suggestedPayment.currentBalance')}: ${CommonStore.formatDecimal(this.state.currentAccountBalance)} </label>
                        </div>
                    </div>
                </div>}

                {this.state.showMinimumPayment === true && <div className="row">
                    <div className="offset-md-1 col-md-8 mt-3 mb-3">
                        <div className="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" className="custom-control-input" id="minimumPaymentCheckboxSelected" name="minimumPaymentCheckboxSelected" checked={this.state.minimumPaymentCheckboxSelected} onChange={this.onChekboxChange} />
                            <label className="custom-control-label" htmlFor="minimumPaymentCheckboxSelected">{T.translate('suggestedPayment.minimumPayment')}: ${CommonStore.formatDecimal(this.state.minPaymentAmount)} </label>
                        </div>
                    </div>
                </div>}

                <div className="row">
                    <div className="offset-md-1 col-md-4 mt-4 mb-4">
                        <div className="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" className="custom-control-input" id="otherCheckboxSelected" name="otherCheckboxSelected" checked={this.state.otherCheckboxSelected} onChange={this.onChekboxChange} />
                            <label className="custom-control-label" htmlFor="otherCheckboxSelected">{T.translate('suggestedPayment.otherAdditional')}:</label>                            
                        </div>
                    </div>
                    <div className="col-md-4">
                        <input type="text" id="otherAmount" name="otherAmount" value={this.state.otherAmount} onChange={this.onOtherAmountChange} className={"form-control flex-grow-1"} autoComplete="off" disabled={this.state.otherAmountDisabled} />
                        {this.props.systemProperties.myacctOtherAmountProcessingFee > 0 && <small>{T.translate('suggestedPayment.otherAdditionalExplanatoryText', this.props.systemProperties)}</small>}
                    </div>
                </div>

                {this.state.showDPPInstallments === true && <div>
                    <span className="card-title mt-5">{T.translate('suggestedPayment.haveDPP')}</span>
                    <div className="row mt-3">
                        <div className="offset-md-1 col-md-2">
                            <div className="form-group">
                                <label htmlFor="nameOnAccount">{T.translate('suggestedPayment.installments')}</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Select isMulti className="basic-multi-select" classNamePrefix="select" options={this.state.dPPs} onChange={this.onDPPSelectChange} />
                        </div>
                    </div>
                </div>}

                {this.props.showFeePaymentAdvertisement &&
                    <div className="card-flex mb-4 mt-5">
                        <h6 className="card-title">{T.translate('suggestedPayment.totalPayment')}: ${CommonStore.formatDecimal(this.state.totalPayment)}</h6>
                        <div className="card-flex-leftlabel">
                            <p>{T.translate('suggestedPayment.paymentsIncursMessageText', this.state.paymentChargeFee)}
                                <FontAwesomeIcon className="card-flex-icon" icon={faInfoCircle} onClick={this.open} />
                            </p>
                        </div>
                    </div>
                }

                {this.props.showFeePaymentAdvertisement &&
                    <ConfirmModal
                        title={T.translate('suggestedPayment.paymentsIncursTitle', this.state.paymentChargeFee)}
                        open={this.state.infoCreditCardFeeModalVisible}
                        onToggle={this.close}>
                        <div>
                            <p>{T.translate('suggestedPayment.paymentsIncursBodyOne', this.state.paymentChargeFee)}</p>
                            <p>{T.translate('suggestedPayment.paymentsIncursBodyTwo')}</p>
                        </div>
                    </ConfirmModal>
                }
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.common }),
    dispatch => bindActionCreators({ ...CommonStore.actionCreators }, dispatch)
)(SuggestedPayment);