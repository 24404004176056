import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n-react';

class LoadingButton extends React.Component {
    render() {
        return (
            <button {...this.props}>
                {this.props.loading === 'true' ? <span><FontAwesomeIcon spin icon={faSync} /><span>&nbsp;&nbsp;{T.translate('common.loading')}</span></span> : <span>{this.props.children}</span>}
            </button>
        );
    }
}

export default LoadingButton;