import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../store/Account';
import * as CommonStore from '../store/Common';
import * as UserStore from '../store/User';
import LoadingButton from './shared/LoadingButton';
import LoadingOverlay from './shared/LoadingOverlay';
import MessageError from './shared/MessageError';


const Scroll = require('react-scroll');

class TwoFactorAuthentication extends Component {

    constructor(props) {
        super(props);

        var fields = [];

        fields['authentication-code'] = { isRequired: true, isValid: true, value: ['_', '_', '_', '_', '_', '_'] };
        fields['action-origin'] = 'field-update';

        this.state = {
            fields: fields
        };

    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('twoFactorAuthentication.title');
    }

    onChange = (e) => {
        let state = this.state;
        var controlIdx = parseInt(e.target.id.replace('authentication-code', '')) - 1;
        var value = e.target.value.trim();

        if (value === '') {
            value = '_';
        }

        state.fields['authentication-code'].value[controlIdx] = value;
        state.fields['action-origin'] = 'field-update';

        this.setState({ state });
    }

    isFormValid = () => {
        let state = this.state;
        var field = state.fields['authentication-code'];
        state.fields['action-origin'] = 'form-validation';

        field.isValid = true;

        var value = field.value.join('');

        if (field.isRequired && (value === '' || value.includes('_'))) {
            field.isValid = false;
        }

        this.setState(state.fields);

        if (field.isValid) {
            this.props.setCommonError('');
        } else {
            this.props.setCommonError(T.translate('common.thereAreMissingFields'));
        }

        return field.isValid;
    }

    submitCode = () => {
        if (this.isFormValid()) {
            var value = this.state.fields['authentication-code'].value.join('');
            this.props.twoFactorAuthCode({ twoFactorAuthCode: value });
        }
    }

    generateNewAuthCode = () => {
        this.props.generateNewAuthCode();
    }

    setValidationStyle = (idx) => {
        let state = this.state;
        var field = state.fields['authentication-code'];
        if (state.fields['action-origin'] !== 'form-validation') {
            return '';
        }
        return field.value[idx] !== '_' ? '' : 'is-invalid';
    }

    getTranslatedError = (error) => {
        switch (error) {
            case 'twoFactorAuthentication.invalidCode':
            case 'twoFactorAuthentication.cantGenerateANewCode':                
                return T.translate(error, this.props.systemProperties);
            default:
                return error;
        }
    }

    render() {
        return (
            <div className="my-account-md-container">
                <div className="my-account-md-content">
                    <div className="page-header">
                        <div className="page-title">{T.translate('twoFactorAuthentication.pageTitle')}</div>
                    </div>
                    <div className="diverter" />

                    <LoadingOverlay loading={this.props.userLoading || this.props.accountLoading} />

                    {this.props.accountPersons && <React.Fragment>
                        <MessageError>{this.getTranslatedError(this.props.accountErrorMessage + this.props.userErrorMessage + this.props.commonErrorMessage)}</MessageError>

                        {this.props.twoFactorAuthentication.isResend && <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                            <div className="d-flex">
                                <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                <p>
                                    {T.translate('twoFactorAuthentication.resendConfirmation', this.props.systemProperties)}
                                </p>
                            </div>
                        </div>}

                        <p>{T.translate('twoFactorAuthentication.paragraph1')}</p>
                        <div className="form-group row mt-4">
                            <div className="col-sm-2">
                                <InputMask mask="9" className={"form-control single-value-input " + this.setValidationStyle(0)} onChange={this.onChange} id="authentication-code1"  />
                            </div>
                            <div className="col-sm-2">
                                <InputMask mask="9" className={"form-control single-value-input " + this.setValidationStyle(1)} onChange={this.onChange} id="authentication-code2"  />
                            </div>
                            <div className="col-sm-2">
                                <InputMask mask="9" className={"form-control single-value-input " + this.setValidationStyle(2)} onChange={this.onChange} id="authentication-code3"  />
                            </div>
                            <div className="col-sm-2">
                                <InputMask mask="9" className={"form-control single-value-input " + this.setValidationStyle(3)} onChange={this.onChange} id="authentication-code4"  />
                            </div>
                            <div className="col-sm-2">
                                <InputMask mask="9" className={"form-control single-value-input " + this.setValidationStyle(4)} onChange={this.onChange} id="authentication-code5"  />
                            </div>
                            <div className="col-sm-2">
                                <InputMask mask="9" className={"form-control single-value-input " + this.setValidationStyle(5)} onChange={this.onChange} id="authentication-code6"  />
                            </div>
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>
                        <div>
                            <LoadingButton className="btn btn-primary btn-lg" onClick={this.submitCode}>{T.translate('twoFactorAuthentication.submit')}</LoadingButton>
                            <a href="javascript:;" onClick={this.generateNewAuthCode} className="a-link ml-0 ml-sm-4 mt-3 mt-sm-0 d-block d-sm-inline-block">{T.translate('twoFactorAuthentication.didntReceivedCode')}</a>
                            {false && <NavLink to="/" className="a-link ml-0 ml-sm-4 mt-3 mt-sm-0 d-block d-sm-inline-block">{T.translate('twoFactorAuthentication.didntReceivedCode')}</NavLink>}
                        </div>

                        {false && <NavLink to="/" className="a-link ml-0 ml-sm-4 mt-3 mt-sm-0 d-block d-sm-inline-block">{T.translate('twoFactorAuthentication.backToLogin')}</NavLink>}
                    </React.Fragment>}

                    {!this.props.accountPersons && <React.Fragment>
                        <MessageError>{T.translate('common.accessDenied')}</MessageError>
                        <NavLink to="/" className="a-link ml-0 ml-sm-4 mt-3 mt-sm-0 d-block d-sm-inline-block">{T.translate('twoFactorAuthentication.backToLogin')}</NavLink>
                    </React.Fragment>}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(TwoFactorAuthentication);
