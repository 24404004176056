import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n-react';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as PaymentStore from '../../store/Payment';
import ConfirmModal from '../shared/ConfirmModal';

const visa = require('../../images/visa.png');
const mc = require('../../images/mastercard.png');
const dis = require('../../images/discover.png');
const amex = require('../../images/american-express.png');
const bank = require('../../images/bank.png');

class PaymentMethodCollapse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapse: false
        };
    }

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    }

    onClickDelete = (pa) => {
        this.props.toggleDeletePaymentAccountConfirmModal(pa.paymentAccountID);
    }

    onDelete = (pa) => {
        this.props.deletePaymentAccount();
    }

    render() {

        let img = visa;

        if (this.props.pa.paymentAccountTypeID === 4) {
            img = bank; //eCheck
        } else {
            if (this.props.pa.cardTypeID === 2) {
                img = mc;
            } else if (this.props.pa.cardTypeID === 3) {
                img = dis;
            } else if (this.props.pa.cardTypeID === 4) {
                img = amex;
            } 
        }

        return (
            <div className="panel-group" key={this.props.key}>
                <div className="panel panel-default">
                    <div className="panel-heading accordian-card">
                        <div className="panel-title">
                            <a className="accordion-toggle" onClick={this.toggle} href="javascript:;">
                                <img src={img} className="mr-3" alt="card" />
                                {this.props.pa.accountName} {this.props.pa.accountNumber}
                                <FontAwesomeIcon className="pull-right" icon={this.state.collapse ? faAngleUp : faAngleDown} />
                                <span className="pull-right exp-date">{this.props.pa.expireMonth ? 'EXP ' + this.props.pa.expireMonth + '/' + this.props.pa.expireYear : ''}</span>
                            </a>
                        </div>
                    </div>
                    <Collapse isOpen={this.state.collapse}>
                        <div className="panel-body">
                            <div className="text-right open-accordian">
                                <a href="javascript:;"
                                    className="a-link"
                                    onClick={() => { this.onClickDelete(this.props.pa); }}>
                                    {T.translate('common.delete')}
                                </a>
                            </div>
                        </div>
                    </Collapse>
                </div>

                <ConfirmModal
                    title={'Confirm Delete Payment Method'}
                    confirmButtonText={'Delete Payment Method'}
                    open={this.props.showDeletePaymentAccountConfirmModal}
                    onToggle={() => { this.props.toggleDeletePaymentAccountConfirmModal(0); }}
                    loading={this.props.paymentLoading}
                    onConfirm={() => { this.onDelete(this.props.pa); }}>
                    Are you sure you want to delete this Payment Method?
                </ConfirmModal> 
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common, ...state.payment }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators }, dispatch)
)(PaymentMethodCollapse);