import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingButton from '../shared/LoadingButton';
import MessageError from '../shared/MessageError';
import ConfirmModal from '../shared/ConfirmModal';
import ExternalLink from '../shared/ExternalLink';
import validator from 'validator';
import InputMask from 'react-input-mask';
import * as AccountStore from '../../store/Account';
import * as UserStore from '../../store/User';
import * as CommonStore from '../../store/Common';
import * as ProfileStore from '../../store/Profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faPinterest, faDribbble } from '@fortawesome/free-brands-svg-icons';

import T from 'i18n-react';

const Scroll = require('react-scroll');
const contactIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/contact-icon.png`);

class ContactUs extends Component {

    constructor(props) {
        super(props);

        var fields = [];

        fields['customer-name'] = { isRequired: false, isValid: true, value: '' };
        fields['account-number'] = { isRequired: true, isValid: true, value: '' };
        fields['email-address'] = { isRequired: true, isValid: true, value: '', validationType: 'email' };
        fields['phone-number'] = { isRequired: true, isValid: true, value: '', validationType: 'phone' };
        fields['how-can-we'] = { isRequired: true, isValid: true, value: '' };
        
        if (props.account.accountNumber) {
            fields['account-number'].value = props.account.accountNumber;
        }

        if (props.account.person) {
            if (props.account.accountTypeID == 1) {
                fields['customer-name'].value = props.account.person.fullName;
            }
            else {
                fields['customer-name'].value = props.account.person.businessName;
            }
            fields['email-address'].value = props.account.person.emailAddress;
            if (props.account.person.personContacts.length != 0)
                fields['phone-number'].value = props.account.person.personContacts[0].contactValue;
        }

        this.state = {
            fields: fields
        };

    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('contactUs.title');
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            let state = this.state;
            var accountNumber = nextProps.account.accountNumber === undefined ? '' : nextProps.account.accountNumber;
            state.fields['account-number'].value = accountNumber;

            if (nextProps.
                account.accountTypeID == 1) {
                state.fields['customer-name'].value = nextProps.account.person.fullName;
            }
            else {
                state.fields['customer-name'].value = nextProps.account.person.businessName;
            }
            state.fields['email-address'].value = nextProps.account.person.emailAddress;
            if (nextProps.account.person.personContacts.length != 0)
                state.fields['phone-number'].value = nextProps.account.person.personContacts[0].contactValue;

            this.setState(state.fields);
        }
    }

    onChange = (e) => {
        let state = this.state;
        var field = state.fields[e.target.id];

        field.value = e.target.value.trim();

        this.setState({ state });
    }

    isFormValid = () => {
        let state = this.state;
        var valid = true;
        var objProperties = Object.keys(state.fields);
        objProperties.map((propertyName) => {
            var field = state.fields[propertyName];
            field.isValid = true;

            if (field.isRequired && field.value.trim() === '') {
                field.isValid = false;
                valid = valid && false;
            }

            if (field.isValid && field.validationType === 'phone') {
                field.isValid = !field.value.includes('_');
            }

            if (field.isValid && field.validationType === 'email') {
                field.isValid = validator.isEmail(field.value);
            }
        });

        this.setState(state.fields);

        if (valid) {
            this.props.setCommonError('');
        } else {
            this.props.setCommonError(T.translate('common.thereAreMissingFields'));
        }

        return valid;
    }

    onSubmit = (e) => {
        if (this.isFormValid()) {
            this.props.createTask(this.state.fields);
        }
    }

    setValidationStyle = (fieldName) => {
        let state = this.state;
        var field = state.fields[fieldName];
        return field.isValid ? '' : 'is-invalid';
    }

    goToDashboard = (e) => {
        this.props.toggleTaskCreationSuccessModal();
        this.props.history.push('/myaccount/dashboard');
    }

    isSocialMediaVisible = () => {
        return (
            (this.props.systemProperties.marketerFacebookUrl !== '' && this.props.systemProperties.marketerFacebookUrl !== '#') ||
            (this.props.systemProperties.marketerTwitterUrl !== '' && this.props.systemProperties.marketerTwitterUrl !== '#') ||
            (this.props.systemProperties.marketerLinkedinUrl !== '' && this.props.systemProperties.marketerLinkedinUrl !== '#')
        );
    }

    getErrorText = () => {
        if (this.props.commonErrorMessage.trim() !== '') {
            return this.props.commonErrorMessage;
        }
        if (this.props.profileErrorMessage.trim() !== '') {
            if (this.props.profileErrorMessage === 'contactUs.taskCreationError') {
                return T.translate('contactUs.taskCreationError');
            }
            else {
                return this.props.profileErrorMessage;
            }
        }
        return '';
    }

    render() {
        return (
            <div className="p-main">

                <div className="page-header">
                    <div className="page-title">{T.translate('contactUs.pageTitle')}</div>
                </div>
                <div className="diverter" />

                <MessageError>{this.getErrorText()}</MessageError>

                <div className="row">
                    <div className="col-lg-8">
                        <h6 className="card-subtitle mt-0 mb-5">{T.translate('contactUs.formTitle')}</h6>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label htmlFor="customer-name">{T.translate('contactUs.customerName')}</label>
                                <input type="text" maxLength="200" className={"form-control " + this.setValidationStyle('customer-name')} onChange={this.onChange} id="customer-name" value={this.state.fields['customer-name'].value} />
                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                            </div>
                            <div className="col-md-6 form-group">
                                <label htmlFor="account-number">{T.translate('contactUs.accountNumber') + '*'}</label>
                                <input type="text" maxLength="20" className={"form-control " + this.setValidationStyle('account-number')} onChange={this.onChange} id="account-number" value={this.state.fields['account-number'].value} />
                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label htmlFor="email-address">{T.translate('contactUs.emailAddress') + '*'}</label>
                                <input type="text" maxLength="100" className={"form-control " + this.setValidationStyle('email-address')} onChange={this.onChange} id="email-address" value={this.state.fields['email-address'].value} />
                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                            </div>
                            <div className="col-md-6 form-group">
                                <label htmlFor="phone-number">{T.translate('contactUs.phoneNumber') + '*'}</label>
                                <InputMask mask="(999) 999-9999" placeholder="### #### ####" className={"form-control phone-number " + this.setValidationStyle('phone-number')} onChange={this.onChange} id="phone-number" value={this.state.fields['phone-number'].value}  />
                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="how-can-we">{T.translate('contactUs.messageContent')}</label>
                            <textarea className={"form-control " + this.setValidationStyle('how-can-we')} onChange={this.onChange} maxLength="3900" rows="6" id="how-can-we" />
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>
                        <LoadingButton
                            disabled={this.props.profileLoading}
                            className="btn btn-primary mb-5"
                            onClick={this.onSubmit}
                            loading={this.props.profileLoading ? 'true' : 'false'}
                        >
                            {T.translate('common.submit')}
                        </LoadingButton>
                    </div>
                    <div className="col-lg-4">
                        <div className="card contact-right">
                            <div className="card-body">
                                <div className="phone-no"><img src={contactIcon} alt={T.translate('contactUs.contactPhone')} />{this.props.systemProperties.customerServicePhone}</div>
                                <p>
                                    <strong>{T.translate('contactUs.customerCareHours')}</strong><br />
                                    {CommonStore.textWrap(this.props.systemProperties['customerServiceHours' + this.props.lang.toUpperCase()], ';')}
                                </p>
                                <hr />
                                <p>
                                    <strong>{this.props.systemProperties.messagingUtilityName}</strong><br />
                                    {CommonStore.textWrap(this.props.systemProperties.marketerMailingAddress)}
                                </p>
                                {this.isSocialMediaVisible() &&
                                    <React.Fragment>
                                        <hr />
                                        <p className="mb-2"><strong>{T.translate('contactUs.socialMedia')}</strong></p>
                                        <ul className="social-ul">
                                            {this.props.systemProperties.marketerFacebookUrl !== '' && this.props.systemProperties.marketerFacebookUrl !== '#' && <li><ExternalLink href={this.props.systemProperties.marketerFacebookUrl} className="social-ul-fb"><FontAwesomeIcon icon={faFacebookF} /></ExternalLink></li>}
                                            {this.props.systemProperties.marketerTwitterUrl !== '' && this.props.systemProperties.marketerTwitterUrl !== '#' && <li><ExternalLink href={this.props.systemProperties.marketerTwitterUrl} target="_blank" className="social-ul-tw"><FontAwesomeIcon icon={faTwitter} /></ExternalLink></li>}
                                            {this.props.systemProperties.marketerLinkedinUrl !== '' && this.props.systemProperties.marketerLinkedinUrl !== '#' && <li><ExternalLink href={this.props.systemProperties.marketerLinkedinUrl} target="_blank" className="social-ul-in"><FontAwesomeIcon icon={faLinkedinIn} /></ExternalLink></li>}
                                            {false && <li><a href="javascript:;" target="_blank" className="social-ul-pn"><FontAwesomeIcon icon={faPinterest} /></a></li>}
                                            {false && <li><a href="javascript:;" target="_blank" className="social-ul-dr"><FontAwesomeIcon icon={faDribbble} /></a></li>}
                                        </ul>
                                    </React.Fragment>}
                            </div>
                        </div>
                    </div>
                </div>

                <ConfirmModal
                    title={T.translate('contactUs.taskCreationConfirmation')}
                    open={this.props.showTaskCreationConfirmation}
                    onToggle={this.goToDashboard}
                    loading={this.props.profileLoading}
                >
                    <div>
                        <p>{T.translate('contactUs.taskCreationConfirmationContent')}</p>
                    </div>
                </ConfirmModal>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.common, ...state.profile, ...state.account }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...ProfileStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(ContactUs);
