import T from 'i18n-react';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as ServiceStore from '../../store/Service';
import BasicModal from '../shared/BasicModal';
import LoadingButton from '../shared/LoadingButton';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';
import UspsAddressVerification from '../shared/UspsAddressVerification';

const Scroll = require('react-scroll');

class MoveOut extends Component {

    constructor(props) {
        super(props);

        var fields = [];

        fields['accountServicePointID'] = { isRequired: true, isValid: true, value: -1 };
        fields['serviceMoveOutDate'] = { isRequired: true, isValid: true, value: undefined };
        fields['billingAddress'] = { isRequired: true, isValid: true, value: '' };
        fields['apartment'] = { isRequired: false, isValid: true, value: '' };
        fields['city'] = { isRequired: true, isValid: true, value: '' };
        fields['state'] = { isRequired: true, isValid: true, value: '' };
        fields['zipCode'] = { isRequired: true, isValid: true, value: '' };

        this.state = {
            fields,
            finalAddress: {},
            distributionServiceProviderID: -1,
            locationAddress: '',
            selectedLocation: {},
            validSpStatus: [8, 10, 11, 18],
            validSevicePoints: [],
            stepNumber: 1,
            disableCancel: false
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('moveOut.title');

        if (this.props.account.accountNumber) {
            var fields = this.state.fields;
            var validSevicePoints = this.props.account.servicePoints.filter(x => this.state.validSpStatus.includes(x.servicePointStatusID));
            var address = this.props.account.mailingAddress;
            var distributionServiceProviderID = this.state.distributionServiceProviderID;

            fields['state'].value = address.state;

            if (validSevicePoints.length === 1) {
                distributionServiceProviderID = validSevicePoints[0].distributionServiceProviderID;
            }

            this.setState({ validSevicePoints, fields, distributionServiceProviderID });
            
            this.props.resetCommonState({ commonLoading: false, commonErrorMessage: '', uspsAddress: { status: this.props.constants.uspsAddressStatus.PENDING } });
            this.props.clearServiceState();
            this.checkStatesListNCalendar(distributionServiceProviderID);
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            var fields = this.state.fields;
            var validSevicePoints = nextProps.account.servicePoints.filter(x => this.state.validSpStatus.includes(x.servicePointStatusID));
            var address = nextProps.account.mailingAddress;
            var distributionServiceProviderID = this.state.distributionServiceProviderID;

            fields['state'].value = address.state;

            if (validSevicePoints.length === 1) {
                distributionServiceProviderID = validSevicePoints[0].distributionServiceProviderID;
            }

            this.setState({ validSevicePoints, fields, distributionServiceProviderID });

            this.props.resetCommonState({ commonLoading: false, commonErrorMessage: '', uspsAddress: { status: this.props.constants.uspsAddressStatus.PENDING } });
            this.props.clearServiceState();
            this.checkStatesListNCalendar(distributionServiceProviderID);
        }
    }

    sendMvo = () => {
        if (this.state.stepNumber === 1) {
            if (this.state.validSevicePoints.length === 1) {
                var defaultLocation = this.state.validSevicePoints[0];
                if (defaultLocation) {
                    let state = this.state;
                    state.fields['accountServicePointID'].value = defaultLocation.accountServicePointID;
                    this.setState({ fieds: state.fieds, locationAddress: defaultLocation.fullAddress });
                }
            }

            if (this.isFormValid()) {
                var address = this.state.fields;
                var request = {
                    address1: address.billingAddress.value,
                    address2: address.apartment.value,
                    city: address.city.value,
                    state: address.state.value,
                    postalCode: address.zipCode.value
                };
                this.props.requestUpsAddressCheck(request);
            }
        }
        else if (this.state.stepNumber === 2) {
            this.setState({ disableCancel: true });

            this.props.createMvoServiceOrder(this.state.selectedLocation, this.state.fields['serviceMoveOutDate'].value, this.state.finalAddress);
        }
    }

    closeModal = () => {
        this.props.resetCommonState({ uspsAddress: { status: this.props.constants.uspsAddressStatus.PENDING } });
        this.props.clearServiceState();
        this.setState({ stepNumber: 1 });
    }

    redirectToDashboard = (e) => {
        this.props.history.push(`/myaccount/dashboard`);
    }

    finalAddressSelection = (preferredAddress) => {
        let fields = this.state.fields;
        let finalAddress = this.props.uspsAddress;

        if (preferredAddress !== this.props.constants.addressSource.USPS) {
            finalAddress.address1 = fields['billingAddress'].value;
            finalAddress.address2 = fields['apartment'].value;
            finalAddress.city = fields['city'].value;
            finalAddress.state = fields['state'].value;
            finalAddress.postalCode = fields['zipCode'].value;
        }

        var selectedLocation = this.props.account.servicePoints.find(x => x.accountServicePointID === this.state.fields['accountServicePointID'].value);
        this.setState({ stepNumber: 2, selectedLocation: selectedLocation, finalAddress });
    }

    isFormValid = () => {
        let state = this.state;
        var valid = true;
        var objProperties = Object.keys(state.fields);
        objProperties.map((propertyName) => {
            var field = state.fields[propertyName];
            field.isValid = true;

            if (field.value === undefined) {
                field.isValid = false;
                valid = valid && false;
            }
            else {
                if (Number.isInteger(field.value)) {
                    if (field.isRequired && field.value <= 0) {
                        field.isValid = false;
                        valid = valid && false;
                    }
                }
                else {
                    if (Object.prototype.toString.call(field.value) !== '[object Date]') {
                        if (field.isRequired && field.value.trim() === '') {
                            field.isValid = false;
                            valid = valid && false;
                        }

                        if (field.isValid && field.validationType === 'phone') {
                            field.isValid = !field.value.includes('_');
                        }

                        if (field.isValid && field.validationType === 'email') {
                            field.isValid = validator.isEmail(field.value);
                        }
                    }
                }
            }
        });

        this.setState(state.fields);

        if (valid) {
            this.props.setCommonError('');
        } else {
            this.props.setCommonError(T.translate('common.thereAreMissingFields'));
        }

        return valid;
    }

    handleChangeServiceMoveOutDate = (e) => {
        let state = this.state;
        state.fields['serviceMoveOutDate'].value = e;
        state.fields['serviceMoveOutDate'].isValid = true;

        this.setState({ fields: state.fields });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    onSelectLocation = (e) => {

        //Get new selection values
        var accountServicePointID = parseInt(e.target.value);
        var selectedSPs = this.props.account.servicePoints.filter(x => x.accountServicePointID === accountServicePointID);
        var locationAddress = selectedSPs.length > 0 ? selectedSPs[0].fullAddress : '';
        var distributionServiceProviderID = selectedSPs.length > 0 ? selectedSPs[0].distributionServiceProviderID : -1;

        let state = this.state;
        state.fields['serviceMoveOutDate'].value = undefined;
        state.fields['accountServicePointID'].value = accountServicePointID;

        state.fields['accountServicePointID'].isValid = state.fields['accountServicePointID'].value > 0;

        this.setState({ distributionServiceProviderID, locationAddress, fields: state.fields });

        this.checkStatesListNCalendar(distributionServiceProviderID);
    }

    checkStatesListNCalendar = (distributionServiceProviderID) => {
        distributionServiceProviderID = distributionServiceProviderID ? distributionServiceProviderID : this.state.distributionServiceProviderID;
        this.props.requestNonWorkingDaysNStates(distributionServiceProviderID, 3);//Mvo:3
    }

    changeFinalAddress = (e) => {
        var input = e.target;
        var finalAddress = this.state.fields;
        finalAddress[input.id].value = input.value;

        finalAddress[input.id].isValid = input.value.trim() !== '';

        this.setState({ fields: this.state.fields });
    }

    getNonWorkingDays = () => {

        var wgGroupID = 3;//mvo

        var tduCalendar = this.props.nonWorkingDaysByTdu.find(x => x.distributionServiceProviderID === this.state.distributionServiceProviderID && x.workingDaysGroupID === wgGroupID);

        if (tduCalendar) {
            return tduCalendar.nonWorkingDays;
        } else {
            return [];
        }

    }

    getFinalCorrespondenceAddress = () => {
        var finalAddress = this.state.fields;
        return finalAddress.billingAddress.value + ' ' + finalAddress.apartment.value + ', ' + finalAddress.city.value + ' ' + finalAddress.state.value + ' ' + finalAddress.zipCode.value;
    }

    getLocationAddress = () => {

        if (this.state.validSevicePoints.length === 1) {
            var defaultLocation = this.state.validSevicePoints[0];
            if (defaultLocation) {
                return defaultLocation.fullAddress;
            }
        }
        return this.state.locationAddress;
    }

    getMaxDate = () => {
        var result = new Date();
        var days = this.props.systemProperties.myAcctMvoFutureDaysAllowed;

        result.setDate(result.getDate() + days);
        return result;
    }

    setValidationStyle = (fieldName) => {
        let state = this.state;
        var field = state.fields[fieldName];
        return field.isValid ? '' : 'is-invalid';
    }

    getErrorMessage = () => {

        if (this.props.commonErrorMessage) {
            return this.props.commonErrorMessage;
        }

        if (this.props.serviceErrorMessage) {
            return this.props.serviceErrorMessage;
        }

        if (this.props.account.accountNumber && this.state.validSevicePoints.length === 0) {
            return T.translate('moveOut.invalidLocations', { customerServicePhone: this.props.systemProperties.customerServicePhone, customerServiceHours: this.props.systemProperties['customerServiceHours' + this.props.lang.toUpperCase()] });
        }

        return undefined;
    }

    render() {
        return (
            <div className="p-main">
                <LoadingOverlay loading={this.props.commonLoading || this.props.serviceLoading} />
                <div className="left-sidebar">
                    <div className="p-main">
                        <form autoComplete="off">
                            <div className="page-header">
                                <div className="page-title">{T.translate('moveOut.pageTitle')}</div>
                                <p className="lead text-dark font-weight-normal kanit">{T.translate('moveOut.accountNumber')}: {this.props.account.accountNumber}</p>
                            </div>

                            <MessageError>{this.state.stepNumber === 1 && this.getErrorMessage()}</MessageError>

                            {this.state.validSevicePoints.length > 0 &&
                                <React.Fragment>
                                    {this.state.stepNumber === 1 &&
                                        <div className="card mb-5">
                                            <div className="card-body">
                                                {this.state.validSevicePoints.length > 1 &&
                                                    <div className="row">
                                                        <div className="col-md-4 form-group">
                                                            <select className={"form-control " + this.setValidationStyle('accountServicePointID')} id="location" name="location" style={{ minWidth: "290px" }} value={this.state.fields['accountServicePointID'].value} onChange={this.onSelectLocation}>
                                                                <option key={0} value={-1}>{T.translate('moveOut.selectLocation')}</option>
                                                                {this.state.validSevicePoints.map((sp, i) =>
                                                                    <option key={sp.accountServicePointID} value={sp.accountServicePointID}>{sp.fullAddress}</option>
                                                                )}
                                                            </select>
                                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                        </div>
                                                    </div>}
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <span><h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('moveOut.serviceLocation')}: </h6><label >{this.getLocationAddress()}</label></span>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <h6 className="card-title" style={{ display: "inline-block", paddingRight: "50px", paddingBottom: "10px" }}>{T.translate('moveOut.moveOutDate')}</h6>
                                                        <DatePicker className={"form-control dp1 calender-im " + this.setValidationStyle('serviceMoveOutDate')}
                                                            id="serviceMoveOutDate"
                                                            name="serviceMoveOutDate"
                                                            selected={this.state.fields['serviceMoveOutDate'].value}
                                                            onChange={this.handleChangeServiceMoveOutDate}
                                                            onChangeRaw={this.handleDateChangeRaw}
                                                            minDate={new Date()}
                                                            maxDate={this.getMaxDate()}
                                                            excludeDates={this.getNonWorkingDays()}
                                                            dateFormat="MM/dd/yy"
                                                            disabledKeyboardNavigation
                                                        />
                                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                    </div>
                                                </div>

                                                <hr />
                                                <h6 className="card-title mt-3 mb-4">{T.translate('moveOut.finalCorrespondenceAddress')}</h6>
                                                <div className="row">
                                                    <div className="col-md-4 form-group">
                                                        <label htmlFor="address">{T.translate('moveOut.billingAddress')}</label>
                                                        <input type="text" className={"form-control " + this.setValidationStyle('billingAddress')} id="billingAddress" value={this.state.fields['billingAddress'].value} onChange={this.changeFinalAddress} />
                                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                    </div>
                                                    <div className="col-md-2 form-group">
                                                        <label htmlFor="apt">{T.translate('moveOut.appartmentNumber')}</label>
                                                        <input type="text" className={"form-control " + this.setValidationStyle('apartment')} id="apartment" value={this.state.fields['apartment'].value} onChange={this.changeFinalAddress} />
                                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                    </div>
                                                    <div className="col-md-2 form-group">
                                                        <label htmlFor="city">{T.translate('moveOut.city')}</label>
                                                        <input type="text" className={"form-control " + this.setValidationStyle('city')} id="city" value={this.state.fields['city'].value} onChange={this.changeFinalAddress} />
                                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                    </div>
                                                    <div className="col-md-2 form-group">
                                                        <label htmlFor="state">{T.translate('moveOut.state')}</label>
                                                        <select className={"form-control " + this.setValidationStyle('state')} id="state" name="state" value={this.state.fields['state'].value} onChange={this.changeFinalAddress}>
                                                            <option key={0} value="" />
                                                            {this.props.statesList.map((state, i) =>
                                                                <option key={i} value={state.value}>{state.name}</option>
                                                            )}
                                                        </select>
                                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                    </div>
                                                    <div className="col-md-2 form-group">
                                                        <label htmlFor="zip-code">{T.translate('moveOut.zipCode')}</label>
                                                        <input type="text" className={"form-control " + this.setValidationStyle('zipCode')} id="zipCode" value={this.state.fields['zipCode'].value} onChange={this.changeFinalAddress} />
                                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group" style={{ textAlignLast: 'right' }}>
                                                            {!this.state.disableCancel && <NavLink to="/myaccount/dashboard" className="btn btn-outline-dark mr-3">{T.translate('common.cancel')}</NavLink>}
                                                            <LoadingButton
                                                                type="button"
                                                                className="btn btn-primary"
                                                                loading={this.props.serviceLoading.toString()}
                                                                onClick={this.sendMvo}
                                                            >
                                                                {T.translate('moveOut.submit')}
                                                            </LoadingButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <BasicModal visible={!this.props.mvoCreated && this.state.stepNumber === 2} title={T.translate('moveOut.comfirmMvo')} onClick={this.closeModal} onClickLoadingBtn={this.sendMvo} btnText={T.translate('common.cancel')} loadingBtnText={T.translate('moveOut.submit')} isLoading={this.props.serviceLoading} size="lg">
                                        <MessageError>{this.getErrorMessage()}</MessageError>
                                        <div className="card mb-5">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <span style={{ paddingBottom: "10px" }}><h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('moveOut.serviceLocation')}: </h6><label>{this.state.locationAddress}</label></span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <span style={{ paddingBottom: "10px" }}><h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('moveOut.moveOutDate')}: </h6><label>{CommonStore.formatDate(this.state.fields['serviceMoveOutDate'].value)}</label></span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <span style={{ paddingBottom: "10px" }}><h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('moveOut.finalCorrespondenceAddress')}: </h6><label>{this.getFinalCorrespondenceAddress()}</label></span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        {T.translate('moveOut.acknowledgment', this.props.systemProperties)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BasicModal>

                                    <BasicModal visible={this.props.mvoCreated && this.state.stepNumber === 2} title={T.translate('moveOut.mvoCreatedTitle')} onClickLoadingBtn={this.redirectToDashboard} loadingBtnText={T.translate('moveOut.ok')} size="lg">
                                        <div className="card mb-5">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        {T.translate('moveOut.mvoCreated', { customerServicePhone: this.props.systemProperties.customerServicePhone, customerServiceHours: this.props.systemProperties['customerServiceHours' + this.props.lang.toUpperCase()] })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BasicModal>

                                <UspsAddressVerification uspsAddress
                                    Text={T.translate('common.uspsAddressFound')} onCancel={this.closeModal} onAddressSelection={this.finalAddressSelection} />
                                </React.Fragment>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.service, ...state.account, ...state.common }),
    dispatch => bindActionCreators({ ...ServiceStore.actionCreators, ...AccountStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(MoveOut);
