import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import * as UserStore from '../../store/User';
import * as CommonStore from '../../store/Common';
import MessageError from './MessageError';
import LoadingButton from './LoadingButton';
import T from 'i18n-react';
import LoadingOverlay from './LoadingOverlay';

class UspsAddressVerification extends Component {

    constructor(props) {
        super(props);
    }

    getUspsCorrespondenceAddress = () => {
        var uspsAddress = this.props.uspsAddress;
        return uspsAddress.address1 + ' ' + uspsAddress.address2 + ', ' + uspsAddress.city + ' ' + uspsAddress.state + ' ' + uspsAddress.postalCode;
    }

    onAddressSelection = (e) => {
        if (this.props.onAddressSelection) {
            if (this.props.uspsAddress.status === this.props.constants.uspsAddressStatus.FOUND && e.target.parentElement.id === 'btnUspsAddress') {
                this.props.onAddressSelection(this.props.constants.addressSource.USPS);
            } else {
                this.props.onAddressSelection(this.props.constants.addressSource.CUSTOM);
            }
        }
    }

    render() {
        return (
            <Modal className="quick-pay-modal" isOpen={this.props.uspsAddress.status !== this.props.constants.uspsAddressStatus.PENDING} size="lg">
                <ModalHeader className="bg-primary"><span className="text-white">{T.translate('common.confirmAddressTitle')}</span></ModalHeader>
                <ModalBody>
                    <div className="">
                        {this.props.uspsAddress.status === this.props.constants.uspsAddressStatus.FOUND &&
                            <div className="row">
                                <div className="col-md-12">
                                    <span>
                                        <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{this.props.uspsAddressFoundText}</h6>
                                        <label id="lblAddress">{this.getUspsCorrespondenceAddress()}</label>
                                    </span>
                                </div>
                            </div>}
                        {this.props.uspsAddress.status === this.props.constants.uspsAddressStatus.NOT_FOUND && <div className="row">{T.translate('common.uspsAddressNotFound')}</div>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    {this.props.onCancel && <button type="button" className="btn" onClick={this.props.onCancel}>{T.translate('common.cancel')}</button>}
                    {this.props.uspsAddress.status === this.props.constants.uspsAddressStatus.FOUND && this.props.onAddressSelection && <button type="button" className="btn" onClick={this.onAddressSelection}>{T.translate('common.skip')}</button>}

                    {this.props.onAddressSelection &&
                        <LoadingButton
                            id="btnUspsAddress"
                            type="button"
                            className="btn btn-primary"
                            loading="false"
                            disabled={false}
                            onClick={this.onAddressSelection}
                        >
                            {T.translate('common.continue')}
                        </LoadingButton>}

                </ModalFooter>
            </Modal>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(UspsAddressVerification);
