import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as UserStore from '../../store/User';
import LoadingOverlay from '../shared/LoadingOverlay';
import DataGrid from '../shared/DataGrid';
import LoadingButton from '../shared/LoadingButton';
import MessageError from '../shared/MessageError';

const Scroll = require('react-scroll');
const planIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/plan-icon.png`);
const referFriendImg = require('../../images/mp-refer-friend.png');
const militatyPromotionImg = require('../../images/mp-military-promotion.png');
const membertomemberImg = require('../../images/mp-membertomember.png');
const roundupImg = require('../../images/mp-roundup.png');


class MemberProgram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            utilityProgramEdited: false,
            utilityProgramIDs: [],
            roundupCheck: -1,
            membertomemberCheck: -1,
            militaryPromos: []
        }
        this.updateMemberProgram = this.updateMemberProgram.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('memberInfo.title');

        if (this.props.account.accountNumber) {
            this.props.getMemberPrograms();            
            this.props.getPlans();
        }
    }

    componentWillReceiveProps(nextProps) {
        let roundUp = -1;
        let memberToMember = -1;
        let utilityProgramIDs = this.state.utilityProgramIDs;

        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getMemberPrograms();            
            this.props.getPlans();
        }

        if (nextProps.memberProgram != null) {           
            roundUp = (nextProps.memberProgram.donationProgramTypeID == 1 || nextProps.memberProgram.donationProgramTypeID == 3) ? 1 : -1;
            memberToMember = (nextProps.memberProgram.donationProgramTypeID == 2 || nextProps.memberProgram.donationProgramTypeID == 3) ? 1 : -1;
        }

        if (nextProps.serviceAgreement.serviceAgreementID
            && nextProps.serviceAgreement.serviceAgreementID > 0
            && this.props.serviceAgreement.serviceAgreementID !== nextProps.serviceAgreement.serviceAgreementID) {

            this.props.getDonationAddOnCharges(nextProps.serviceAgreement.productID);
            this.props.getPromotionDetail(nextProps.serviceAgreement.productID);
        }

        if (nextProps.serviceAgreement.utilityProgramIDs && !this.state.utilityProgramEdited) {
            if (nextProps.serviceAgreement.utilityProgramIDs && nextProps.serviceAgreement.utilityProgramIDs !== utilityProgramIDs) {
                utilityProgramIDs = nextProps.serviceAgreement.utilityProgramIDs;
            }
        }

        if (this.props.accountServicePointID > 0 && this.props.accountServicePointID !== nextProps.accountServicePointID) {
            this.props.getPlans();
        }

        const militaryPromos = [];
        var lang = "";
        if (this.props.lang)
            lang = this.props.lang.toUpperCase();

        nextProps.promotions && nextProps.promotions.map((promotion) => {
            if (promotion.promotionCharacteristic.length > 0) {
                let personCategoryByLangValue = "";
                const personCategoryByLangIndex = promotion.promotionCharacteristic.findIndex((item) => item.charName === "PERSON_CATEGORY");
                if (personCategoryByLangIndex !== -1) {
                    personCategoryByLangValue = promotion.promotionCharacteristic[personCategoryByLangIndex].charValue;
                }

                if (personCategoryByLangValue === "MILITARY") {
                    let titleByLangValue = "";
                    const titleByLangIndex = promotion.promotionCharacteristic.findIndex((item) => item.charName === "WEB_ENROLLMENT_MILITARYID_TITLE_" + lang);
                    if (titleByLangIndex !== -1) {
                        titleByLangValue = promotion.promotionCharacteristic[titleByLangIndex].charValue;
                    }

                    let textByLangValue = "";
                    const textByLangIndex = promotion.promotionCharacteristic.findIndex((item) => item.charName === "WEB_ENROLLMENT_MILITARYID_TEXT_" + lang);
                    if (textByLangIndex !== -1) {
                        textByLangValue = promotion.promotionCharacteristic[textByLangIndex].charValue;
                    }

                    let imageUrlByLangValue = "";
                    const imageByLangIndex = promotion.promotionCharacteristic.findIndex((item) => item.charName === "WEB_ENROLLMENT_MILITARYID_IMAGE_" + lang);
                    if (imageByLangIndex !== -1) {
                        imageUrlByLangValue = promotion.promotionCharacteristic[imageByLangIndex].charValue;
                    }

                    if (titleByLangValue && textByLangValue && imageUrlByLangValue) {
                        promotion.titleByLangValue = titleByLangValue;
                        promotion.textByLangValue = textByLangValue;
                        promotion.imageUrlByLangValue = imageUrlByLangValue;

                        militaryPromos.push(promotion);
                    }
                }
            }           
        });
        this.setState({
            utilityProgramIDs,
            roundupCheck: roundUp,
            membertomemberCheck: memberToMember,
            militaryPromos
        });
    }

    onChange = (e, index, customModuleId) => {
        if (customModuleId == 10) {
            if (e.target.checked == true)
                this.setState({ roundupCheck: 1 })
            else
                this.setState({ roundupCheck: 0 })

        }
        if (customModuleId == 11) {
            if (e.target.checked == true)
                this.setState({ membertomemberCheck: 1 })
            else
                this.setState({ membertomemberCheck: 0 })
        }
    }

    onChangeProgramSubscription = (e, index, program) => {
        var utilityProgramIDs = this.state.utilityProgramIDs;

        if (e.target.checked) {
            if (!utilityProgramIDs.includes(program.utilityProgramID)) {
                utilityProgramIDs.push(program.utilityProgramID);
                this.setState({ utilityProgramIDs, utilityProgramEdited: true })
            }
        } else {
            if (utilityProgramIDs.includes(program.utilityProgramID)) {
                utilityProgramIDs = utilityProgramIDs.filter(x => x !== program.utilityProgramID);
                this.setState({ utilityProgramIDs, utilityProgramEdited: true })
            }
        }
    }

    updateMemberProgram = () => {       
        this.props.createMemberProgram(this.state, this.props.serviceAgreement.productID);
    }

    setMilitaryDocument = (event) => {
        this.props.setMilitaryDocument(event.target.files[0]);
    }

    setReferralName = (event) => {        
        this.props.setReferral(event.target.value);
    }

    render() {
        return (
            <div className="p-main">
                <div className="left-sidebar">
                    <div className="p-main">
                        <LoadingOverlay loading={this.props.accountLoading} />

                        <div className="page-header">
                            <div className="page-title">{T.translate('memberProgram.pageTitle')}</div>
                        </div>

                        {this.props.isSuccess &&
                            <div>
                                <React.Fragment>
                                    <div className="card bl-green mb-4 mb-sm-5 bl-br">
                                        <div className="card-body pay-echeck">
                                            <div>
                                                <h6 className="card-title text-success">{T.translate('memberProgram.succesMessageTitle')}</h6>
                                                <p className="lead mb-4">
                                                    <em>
                                                        {T.translate('memberProgram.programSaved')}
                                                    </em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>}

                        <React.Fragment>
                            {this.props.utilityPrograms && this.props.utilityPrograms.map((program, index) =>
                                <div className="form-group row" key={program.utilityProgramID}>
                                    <div className="checkbox col-md-3">
                                        <label className="switch">
                                            <input type="checkbox" name="addOnCheck" checked={(this.state.utilityProgramIDs.includes(program.utilityProgramID))} onChange={(event) => this.onChangeProgramSubscription(event, index, program)} />
                                            <span className="slider round" />
                                        </label>
                                    </div>

                                    <div className="col-md-6">
                                        <p><b>{program.addOnChargeTitle}</b></p>
                                        <label>{program.addOnChargeDescription}</label>
                                    </div>
                                    <div className="col-md-3">
                                        <img src={program.addOnChargeURLImage} style={{ height: 75 }} />
                                    </div>
                                </div>
                            )}
                        </React.Fragment>

                        <React.Fragment>
                            {this.props.addOnCharges && this.props.addOnCharges.map((charge, index) =>

                                <div className="form-group row" key={charge.chargeID}>
                                    {charge.isCustomCharge && charge.customChargeModuleID == 10 &&
                                        <div className="checkbox col-md-3">
                                            <label className="switch">
                                                <input type="checkbox" name="addOnCheck" checked={(this.state.roundupCheck == 1)} onClick={(event) => this.onChange(event, index, charge.customChargeModuleID)} />
                                                <span className="slider round" />
                                            </label>
                                        </div>
                                    }

                                    {charge.isCustomCharge && charge.customChargeModuleID == 11 &&
                                        <div className="checkbox col-md-3">
                                            <label className="switch">
                                                <input type="checkbox" name="addOnCheck" checked={(this.state.membertomemberCheck == 1)} onClick={(event) => this.onChange(event, index, charge.customChargeModuleID)} />
                                                <span className="slider round" />
                                            </label>
                                        </div>
                                    }

                                    <div className="col-md-6">
                                        <p><b>{charge.addOnTitle}</b></p>
                                        <label>{charge.addOnDescription}</label>
                                    </div>
                                    <div className="col-md-3">
                                        <img src={charge.addOnURLImage} style={{ height: 75 }} />
                                    </div>
                                </div>
                            )}
                        </React.Fragment>

                        {this.state.militaryPromos && this.state.militaryPromos.map(promo =>
                            <div className="form-group row" key={promo.promotionID}>
                                <div className="col-md-3">
                                    <label>                                        
                                        <input type="file" onChange={(event) => this.setMilitaryDocument(event)}  name="image" />
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <p><b>{promo.titleByLangValue}</b></p>
                                    <label>{promo.textByLangValue}</label>
                                    {this.props.militaryAccountDocumentFile &&
                                        <p>{T.translate("common.fileName")}: <b>{this.props.militaryAccountDocumentFile.name}</b> - {T.translate("common.fileType")}: <b>{this.props.militaryAccountDocumentFile.type}</b></p>
                                    }
                                </div>
                                <div className="col-md-3">
                                    <img src={promo.imageUrlByLangValue} style={{ height: 75 }} />
                                </div>
                            </div>
                        )}

                        {this.props.systemProperties && this.props.systemProperties.myacctReferralTitle &&
                            <div className="form-group row">
                                <div className="col-md-3">
                                    <label>
                                        <input type="text" className="form-control" onChange={(event) => this.setReferralName(event)} />
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <p><b>{this.props.systemProperties.myacctReferralTitle}</b></p>
                                    <label>{this.props.systemProperties.myacctReferralDescription}</label>
                                </div>
                                <div className="col-md-3"> 
                                    <img src={this.props.systemProperties.myacctReferralLogo} style={{ height: 75 }} />
                                </div>
                            </div>
                        }
                        
                        <MessageError>{this.props.memberProgramError}</MessageError>
                        <div>
                            <LoadingButton
                                type="button"
                                className="btn btn-primary mb-3 mr-3"
                                disabled={this.props.accountLoading}
                                loading={this.props.accountLoading ? 'true' : 'false'}
                                onClick={this.updateMemberProgram}
                            >
                                {T.translate('customerProfile.saveUpdates')}
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(
    state => ({ ...state.account, ...state.common }),
    dispatch => bindActionCreators({ ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)

)(MemberProgram);