import React from 'react';
import { NavLink } from 'react-router-dom';

class AppNavLink extends React.Component {
    render() {

        var isActive = false;
        var className = '';
        try {
            isActive = window.location.pathname === this.props.to;
            className = isActive ? 'active' : '';
        } catch (e) {
            console.log(e);
        }

        return (
            <li className={className}>
                <NavLink title={this.props.sidebar === 'true' ? this.props.text : ''} {...this.props}>
                    {this.props.children}
                </NavLink>
            </li>
        );
    }
}

export default AppNavLink;