import T from 'i18n-react';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as ServiceStore from '../../store/Service';
import ConfirmModal from '../shared/ConfirmModal';
import LoadingButton from '../shared/LoadingButton';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';

const Scroll = require('react-scroll');

class BackflowInspection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            distributionPointID: 0,
            distributionServiceProviderID: 1,
            assetID: 0,
            serviceOrderReasonTypeID: 0,
            serviceDate: '',
            serialNumber: '',
            serviceOrderSubPurposeID: 16,//Backflow device inspection
            fields: [
                { name: 'distributionPointID', isRequired: true, isRequiredError: false },
                { name: 'assetID', isRequired: true, isRequiredError: false },
                { name: 'serviceDate', isRequired: true, isRequiredError: false }
            ],
        };
    }


    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('backflowInspection.title');

        if (this.props.account.accountNumber) {
            this.props.clearServiceState();
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if (nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.clearServiceState();
        }
    }

    onChange = (e) => {

        let fields = this.state.fields.filter(x => x.name === e.target.name);
        if (fields && fields.length === 1) {
            let field = fields[0];

            if (field.isRequired && field.isRequiredError && e.target.value) {
                field.isRequiredError = false;
            }
        }

        this.setState({ [e.target.name]: e.target.value });

        if (e.target.name === 'distributionPointID') {
            this.getAssetValue();
        }
    }

    handleChangeServiceDate = (e) => {
        this.setState({ serviceDate: e });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    onToggle = () => {
        this.props.setDistributionPointModalVisible(false);
    }

    onToggleConfirm = () => {
        this.props.setServiceRequestOrderConfirmModalVisible(false);
    }

    onToggleCancel = () => {
        this.props.setServiceRequestOrderCancelModalVisible(false);
    }

    onCancelClick = () => {
        this.props.setServiceRequestOrderCancelModalVisible(true);
    }

    goToDashboard = () => {
        this.props.setServiceRequestOrderCancelModalVisible(false);
        this.props.setServiceRequestOrderSuccessModalVisible(false);
        this.props.history.push('/myaccount/dashboard');
    }

    checkStatesListNCalendar = (distributionServiceProviderID) => {
        this.props.requestNonWorkingDaysNStates(distributionServiceProviderID, 1);//Enrollments:1
    }

    onSubmitRequest = () => {

        let state = this.state;
        let valid = true;

        state.fields.map((field) => {

            field.value = state[field.name];
            field.isRequiredError = false;

            if (field.isRequired && !field.value) {
                field.isRequiredError = true;
                valid = false;
            }

        });

        this.setState(state);

        if (valid) {
            
            let asset = this.getSelectedAsset();
            let isModelError = true;
            let person = this.props.account.person;

            state.accountServicePointID = this.getSelectedServicePoint().accountServicePointID;
            state.distributionServiceProviderID = this.getSelectedServicePoint().distributionServiceProviderID;
            state.distributionPointID = this.getSelectedServicePoint().distributionPointID;
            state.serialNumber = asset.serialNumber;
            state.serviceOrderText = T.translate("backflowInspection.serviceOrderText", person);

            if (asset.modelCode) {

                let modelCode = asset.modelCode.trim().toLowerCase();

                if (modelCode === 'rpz') {
                    isModelError = false;
                    state.serviceOrderReasonTypeID = this.props.constants.serviceOrderReasonType.INSPECTION_ON_RPZ_DEVICE;
                } else if (modelCode === 'dcv') {
                    isModelError = false;
                    state.serviceOrderReasonTypeID = this.props.constants.serviceOrderReasonType.INSPECTION_ON_DCV_DEVICE;
                }
            }

            if (isModelError) {
                this.props.showServiceError(T.translate("backflowInspection.backflowDeviceWithoutModel", asset));
                return;
            }

            this.props.showServiceError('');
            this.props.setServiceRequestOrderConfirmModalVisible(true);
        } else {
            this.props.showServiceError(T.translate("common.thereAreMissingFields"));
        }

    }

    getMaxDate = () => {
        let result = new Date();
        let days = this.props.systemProperties.myAcctMvoFutureDaysAllowed;

        result.setDate(result.getDate() + days);
        return result;
    }

    onSubmitRequestConfirm = () => {
        this.props.createServiceOrder(this.state);
    }

    getNonWorkingDays = () => {

        let wgGroupID = 1;//mvi-switch

        let tduCalendar = this.props.nonWorkingDaysByTdu.find(x => x.distributionServiceProviderID === this.state.distributionServiceProviderID && x.workingDaysGroupID === wgGroupID);

        if (tduCalendar) {
            return tduCalendar.nonWorkingDays;
        } else {
            return [];
        }

    }

    getErrorMessage = () => {
        let errorMessage = '';

        if (this.props.serviceErrorMessage) {
            errorMessage = this.props.serviceErrorMessage;
        }

        if (errorMessage !== '') {
            Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        }

        return errorMessage;
    }

    getSelectedServicePoint = () => {
        let distributionPointID = this.state.distributionPointID;
        let servicePoint = this.props.account.servicePoints.find(x => x.distributionPointID == distributionPointID);

        if (servicePoint)
            return servicePoint;

        return { fullAddress: '', distributionServiceProviderID: 0 };
    }

    getSelectedAsset = () => {
        let assetID = this.state.assetID;
        let assets = this.getAssets();
        let asset = assets.find(x => x.assetID === assetID);

        if (asset)
            return asset;

        return { serialNumber: '', assetDescription: '', assetID: 0 };
    }

    getDistributionPointValue = () => {
        let distributionPointID = this.state.distributionPointID;
        let spList = this.props.account.servicePoints;

        if (spList && spList.length === 1 && distributionPointID === 0) {
            distributionPointID = spList[0].distributionPointID;
            this.setState({ distributionPointID: distributionPointID });
        }

        return distributionPointID;
    }

    getAssetValue = () => {
        let distributionPointID = this.state.distributionPointID;
        let assetID = this.state.assetID;
        let spList = this.props.account.servicePoints;
        let locationServices = this.props.account.locationServices;

        let sp = spList.find(x => x.distributionPointID === distributionPointID);

        if (sp) {
            let locationSvc = locationServices.find(x => x.accountServicePointID === sp.accountServicePointID);

            if (locationSvc && locationSvc.assets && locationSvc.assets.length === 1) {
                assetID = locationSvc.assets[0].assetID;
                if (assetID !== this.state.assetID) {
                    this.setState({ assetID: assetID });
                }
            }
        }

        return assetID;
    }

    getAssets = () => {
        let distributionPointID = this.state.distributionPointID;
        let spList = this.props.account.servicePoints;
        let locationServices = this.props.account.locationServices;
        let assets = [];

        let sp = spList.find(x => x.distributionPointID === distributionPointID);

        if (sp) {
            let locationSvc = locationServices.find(x => x.accountServicePointID === sp.accountServicePointID);

            if (locationSvc && locationSvc.assets && locationSvc.assets.length === 1) {
                assets = locationSvc.assets;
            }
        }

        return assets;
    }

    render() {
        return (
            <div className="p-main">
                <div className="left-sidebar">
                    <div className="p-main">

                        <LoadingOverlay loading={this.props.serviceLoading} />

                        <div className="page-header">
                            <div className="page-title">{T.translate('backflowInspection.pageTitle')}</div>
                            <p className="lead text-dark font-weight-normal kanit">{T.translate('backflowInspection.accountNumber')}: {this.props.account.accountNumber}</p>
                        </div>

                        <MessageError>{this.getErrorMessage()}</MessageError>

                        {this.props.serviceErrorCode === 0 && <React.Fragment>

                            <div className="card mb-5">
                                <div className="card-body">

                                    <h6 className="card-title mt-3 mb-4">{T.translate('backflowInspection.serviceRequest')}</h6>
                                    <div className="row">
                                        <div className="col-md-4 form-group">
                                            <label htmlFor="distributionPointID">{T.translate('backflowInspection.serviceLocation')}</label>
                                            <select className={"form-control " + (this.state.fields[0].isRequiredError ? 'is-invalid' : '')} id="distributionPointID" name="distributionPointID" onChange={this.onChange} value={this.getDistributionPointValue()}>
                                                <option key={0} value={0} >{T.translate('addLocation.select')}</option>
                                                {this.props.account.servicePoints.map((sp, i) =>
                                                    <option key={sp.distributionPointID} value={sp.distributionPointID}>{sp.fullAddress}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label htmlFor="assetID">{T.translate('backflowInspection.asset')}</label>
                                            <select className={"form-control " + (this.state.fields[1].isRequiredError ? 'is-invalid' : '')} id="assetID" name="assetID" onChange={this.onChange} value={this.getAssetValue()}>
                                                <option key={0} value={0} >{T.translate('addLocation.select')}</option>
                                                {this.getAssets().map((asset, i) =>
                                                    <option key={asset.assetID} value={asset.assetID}>{'(' + asset.serialNumber + ') ' + asset.assetDescription}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-2">

                                            <label htmlFor="serviceDate">{T.translate('backflowInspection.serviceDate')}</label>
                                            <div className="date-time">
                                                <DatePicker className={"form-control " + (this.state.fields[2].isRequiredError ? 'is-invalid' : '')}
                                                    id="serviceDate"
                                                    name="serviceDate"
                                                    selected={this.state.serviceDate}
                                                    onChange={this.handleChangeServiceDate}
                                                    onChangeRaw={this.handleDateChangeRaw}
                                                    minDate={new Date()}
                                                    maxDate={this.getMaxDate()}
                                                    excludeDates={this.getNonWorkingDays()}
                                                    dateFormat="MM/dd/yy"
                                                    disabledKeyboardNavigation
                                                />
                                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group" style={{ textAlignLast: 'right' }}>
                                                <button type="button" className="btn btn-outline-dark mr-3" onClick={this.onCancelClick}>{T.translate('common.cancel')}</button>
                                                <button type="button" className="btn btn-primary" onClick={this.onSubmitRequest}>{T.translate('backflowInspection.submitServiceRequest')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal className="transfer-service-modal" isOpen={this.props.showTransferServiceConfirmModal} size="lg" style={{ maxWidth: '900px', width: '80%' }}>
                                <ModalHeader toggle={this.onToggleConfirm} className="bg-primary"><span className="text-white">{T.translate('backflowInspection.backflowInspectionConfirmation')}</span></ModalHeader>
                                <ModalBody>

                                    <MessageError>{this.props.accountErrorMessage}</MessageError>

                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span>
                                                    <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('backflowInspection.serviceAddress')}: </h6>
                                                    <label id="lblFullAddress2">{this.getSelectedServicePoint().fullAddress}</label>
                                                </span>
                                            </div>
                                            <div className="col-md-12">
                                                <span>
                                                    <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('backflowInspection.locationIdentifier')}: </h6>
                                                    <label id="lblServiceProviderIdentifier2">{this.getSelectedServicePoint().serviceProviderIdentifier}</label>
                                                </span>
                                            </div>
                                            <div className="col-md-12">
                                                <span>
                                                    <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('backflowInspection.asset')}: </h6>
                                                    <label id="lblAsset2">{T.translate('backflowInspection.assetDetail', this.getSelectedAsset())}</label>
                                                </span>
                                            </div>
                                            <div className="col-md-12">
                                                <span>
                                                    <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('backflowInspection.serviceDate')}: </h6>
                                                    <label id="lblServiceDate">{CommonStore.formatDate(this.state.serviceDate)}</label>
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>
                                                    {T.translate('backflowInspection.acknowledgment', this.props.systemProperties)}
                                                </p>
                                            </div>
                                        </div>

                                        {this.props.systemProperties.myAcctHoldEnrollmentTransferServiceBackflowInspection &&
                                            <React.Fragment>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p><strong>{CommonStore.textWrap(this.props.systemProperties['myAcctTranServiceBackflowInspectionTitle' + this.props.lang.toUpperCase()], ';')}</strong></p>
                                                        <p>
                                                            {CommonStore.textWrap(this.props.systemProperties['myAcctTranServiceBackflowInspectionDes' + this.props.lang.toUpperCase()], ';')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>

                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="btn pull-right" onClick={this.onToggleConfirm}>{T.translate('common.close')}</button>
                                    <LoadingButton
                                        type="button"
                                        className="btn pull-right btn-primary"
                                        disabled={this.props.serviceLoading}
                                        loading={this.props.serviceLoading ? 'true' : 'false'}
                                        onClick={this.onSubmitRequestConfirm}>
                                        {T.translate('common.submit')}
                                    </LoadingButton>
                                </ModalFooter>
                            </Modal>

                            <ConfirmModal
                                title={T.translate('backflowInspection.confirmDialog')}
                                open={this.props.showTransferServiceCancelModal}
                                onToggle={this.onToggleCancel}
                                onConfirm={this.goToDashboard}
                                confirmButtonText={T.translate('backflowInspection.yes')}>
                                <div><p>{T.translate('backflowInspection.areYouSureYouWantToNavigateAwayFromThisScreen')}</p></div>
                            </ConfirmModal>

                            <ConfirmModal
                                title={T.translate('backflowInspection.backflowInspectionRequestConfirmTitle')}
                                open={this.props.showTransferServiceSuccessModal}
                                onConfirm={this.goToDashboard}
                                confirmButtonText={T.translate('backflowInspection.ok')}>
                                <div><p>{T.translate('backflowInspection.yourRequestHasBeenSubmitted', this.props.systemProperties)}</p></div>
                            </ConfirmModal>
                        </React.Fragment>}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.service, ...state.account, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...ServiceStore.actionCreators }, dispatch)
)(BackflowInspection);
