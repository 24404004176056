import React from 'react';
const loading = require(`../../images/${process.env.REACT_APP_CLIENT}/loading.gif`);

class LoadingOverlay extends React.Component {
    render() {

        if (this.props.loading) {
            return (
                this.props.loading &&
                <div className="content-screen-overlay">
                    <img src={loading} alt="loading" />
                </div>
            );
        } else {
            return null;
        }
    }
}

export default LoadingOverlay;