import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserStore from '../../store/User';
import * as CommonStore from '../../store/Common';
import * as AccountStore from '../../store/Account';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';
import DataGrid from '../shared/DataGrid';
import T from 'i18n-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as CommonState from '../../store/Common';

const Scroll = require('react-scroll');
const download = require('../../images/download.png');

class Usage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numberOfMonths: this.props.usageMeterReadMaxMonths,
            startDate: CommonState.addMonthsToDate(new Date(), -this.props.usageMeterReadMaxMonths),
            endDate: new Date()
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('usage.title');

        if (this.props.account.accountNumber) {
            const { startDate, endDate } = this.state;
            this.props.getBilledMeterReadsByDateRange(startDate, endDate);
        }
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            const { startDate, endDate } = this.state;
            this.props.getBilledMeterReadsByDateRange(startDate, endDate);
        }
    }

    handleChangeStartDate = (date) => {
        const { numberOfMonths, endDate } = this.state;
        const newEndDate = CommonState.addMonthsToDate(date, numberOfMonths);

        const dateDifference = CommonState.getDateDifference(date, endDate, 'months');
        
        if (dateDifference > numberOfMonths || dateDifference < 0) {
            this.setState({
                startDate: date,
                endDate: newEndDate
            });
        } else {
            this.setState({ startDate: date });
        }
    }

    handleChangeEndDate = (date) => {
        const { numberOfMonths, startDate } = this.state;
        const newStartDate = CommonState.addMonthsToDate(date, -numberOfMonths);

        const dateDifference = CommonState.getDateDifference(startDate, date, 'months');

        if (dateDifference > numberOfMonths || dateDifference < 0) {
            this.setState({
                endDate: date,
                startDate: newStartDate
            });
        } else {
            this.setState({ endDate: date });
        }
    }


    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    onSubmitDateRange = () => {
        const { startDate, endDate } = this.state;
        this.props.getBilledMeterReadsByDateRange(startDate, endDate);
    }

    render() {
        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('usage.pageTitle')}</div>
                </div>
                <div className="diverter" />

                <MessageError>{this.props.accountErrorMessage}</MessageError>

                {this.props.billedMeterReads.length === 0 ?
                    <p class="mb-5">{T.translate('common.noRecordsToDisplay')}</p> :
                    <p class="mb-5"><strong>{T.translate('usage.dateRangeLimit', this.state)}</strong></p>}


                <div className="row mb-5">
                    <div className="col-md-2">

                        <p>{T.translate('usage.startDate')}</p>
                        <div className="date-time">
                            <DatePicker className="form-control"
                                id="meterStartDate"
                                name="meterStartDate"
                                selected={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                onChangeRaw={this.handleDateChangeRaw}
                                dateFormat="MM/dd/yy"
                                disabledKeyboardNavigation
                            />
                        </div>
                    </div>
                    <div className="col-md-2 !flex">

                        <p>{T.translate('usage.endDate')}</p>
                        <div className="date-time">
                            <DatePicker className="form-control"
                                id="meterEndDate"
                                name="meterEndDate"
                                selected={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                onChangeRaw={this.handleDateChangeRaw}
                                dateFormat="MM/dd/yy"
                                disabledKeyboardNavigation
                            />
                        </div>
                    </div>
                    <div className="col-md-2 !flex">
                        <p class="text-white">{T.translate('usage.endDate')}</p>
                        <button type="button" className="btn btn-primary" onClick={this.onSubmitDateRange}>{T.translate('usage.submitDateRange')}</button>
                    </div>
                    
                </div>
                <DataGrid usePaging={true}
                    pageSize="15"
                    dataKey="meterReadID" 
                    downloadData={true}
                    csvFileName={"MeterReads_" + CommonStore.formatDateYYYYMMDD(this.state.startDate) + "-" + CommonStore.formatDateYYYYMMDD(this.state.endDate) + "_" + this.props.account.accountNumber + ".csv"}
                    applyColumnFormattingOnDownload={true}
                >{{
                    rows: this.props.billedMeterReads,
                    columns: [
                        { columnId: 'serialNumber', displayText: T.translate('usage.colMeterSerialNumber'), dataType: 'string' },
                        { columnId: 'billDate', displayText: T.translate('usage.colBillDate'), dataType: 'date', format: 'MM/dd/yyyy' },
                        { columnId: 'fromDate', displayText: T.translate('usage.colFromDate'), dataType: 'datetime', format: 'MM/dd/yyyy hh:mm A' },
                        { columnId: 'toDate', displayText: T.translate('usage.colToDate'), dataType: 'datetime', format: 'MM/dd/yyyy hh:mm A' },
                        { columnId: 'startRegisterValue', displayText: T.translate('usage.colStartRegisterValue'), dataType: 'decimal' },
                        { columnId: 'endRegisterValue', displayText: T.translate('usage.colEndRegisterValue'), dataType: 'decimal' },
                        { columnId: 'totalConsumption', displayText: T.translate('usage.colTotalConsumption'), dataType: 'decimal' }
                    ]
                }}</DataGrid>
            </div>
        );
    }
}
export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(Usage);