import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as UserStore from '../../store/User';
import QuickPayModal from '../QuickPayModal';
import LoadingButton from './LoadingButton';
import LoadingOverlay from './LoadingOverlay';
import MessageError from './MessageError';
import Password from './PasswordInput';
import { Tooltip } from 'reactstrap';

const Scroll = require('react-scroll');
const qs = require('querystringify');
const quickPay = require(`../../images/${process.env.REACT_APP_CLIENT}/quickpay.png`);

class RightSectionLogin extends Component {

    constructor(props) {
        super(props);

        const cookies = new Cookies();

        this.state = {
            username: '',
            password: '',
            twoFactorAuthCode: cookies.get('twf4a9th'),
            rememberMe: false,
            cookies: cookies,
            tooltipUsernameOpen: false,
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('login.title');

        this.props.clearUserState();

        const params = qs.parse(this.props.location.search);

        if (params.quickPay || params.quickpay) {
            this.props.setQuickPayModalVisible(true);
        }
    }

    toggleUsernameTooltip = () => {
        this.setState({
            tooltipUsernameOpen: !this.state.tooltipUsernameOpen
        });
    }

    onChange = (e) => {
        const state = this.state;

        if (e.target.type === 'checkbox') {
            state[e.target.name] = e.target.checked;
        }
        else {
            state[e.target.name] = e.target.value;
        }

        this.setState(state);
    }

    showQuickPay = () => {
        this.props.setQuickPayModalVisible(true);
    }

    render() {
        return (
            <div className="login-right">
                <h3>{T.translate('login.dontHaveAn')}<span>{T.translate('login.onlineAccount')}</span></h3>
                <p className="lead">
                    {T.translate('login.onceYouHaveRegisteredYouCan', this.props.systemProperties)}
                </p>
                <ul className="login-right-ul">
                    <li>{T.translate('login.lookAndPayYourBills')}</li>
                    <li>{T.translate('login.viewYourUsage')}</li>
                    <li>{T.translate('login.manageYourNotifications')}</li>
                    <li>{T.translate('login.updateYourAccountInformation')}</li>
                </ul>
                <div className="login-diverder" />
                <div className="quickpay-box">
                    <div>
                        <h3><T.span text="login.quickPay" /></h3>
                        <p className="lead font-weight-normal"><strong>{T.translate('login.theFastestWayToPayYourBill')}</strong></p>
                        <p className="lead font-weight-normal">
                            {T.translate('login.makePaymentWithoutEven')}
                            <br />
                            {T.translate('login.loggingAccount', this.props.systemProperties)}
                        </p>
                    </div>
                    <img src={quickPay} alt="quick pay" />
                </div>
                <a href="javascript:;" className="btn btn-light btn-lg" onClick={this.showQuickPay}>{T.translate('login.makeQuickPayment')}</a>
            </div>
        );
    }
}

//https://github.com/reduxjs/redux/issues/363
export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(RightSectionLogin);
