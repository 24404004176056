import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import * as AccountStore from '../store/Account';
import * as CommonStore from '../store/Common';
import * as UserStore from '../store/User';
import QuickPayModal from './QuickPayModal';
import LoadingButton from './shared/LoadingButton';
import LoadingOverlay from './shared/LoadingOverlay';
import MessageError from './shared/MessageError';
import Password from './shared/PasswordInput';
import { Tooltip } from 'reactstrap';
import RightSectionLogin from './shared/RightSectionLogin';

const Scroll = require('react-scroll');
const qs = require('querystringify');
const quickPay = require(`../images/${process.env.REACT_APP_CLIENT}/quickpay.png`);
const loading = require(`../images/${process.env.REACT_APP_CLIENT}/loading.gif`);
const logoMyAccountLogin = require(`../images/${process.env.REACT_APP_CLIENT}/logo-external.png`);

class Login extends Component {

    constructor(props) {
        super(props);

        const cookies = new Cookies();

        this.state = {
            username: '',
            password: '',
            twoFactorAuthCode: cookies.get('twf4a9th'),
            rememberMe: false,
            cookies: cookies,
            tooltipUsernameOpen: false,
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('login.title');

        this.props.clearUserState();

        const params = qs.parse(this.props.location.search);

        if (params.quickPay || params.quickpay) {
            this.props.setQuickPayModalVisible(true);
        }
    }

    toggleUsernameTooltip = () => {
        this.setState({
            tooltipUsernameOpen: !this.state.tooltipUsernameOpen
        });
    }

    onChange = (e) => {
        const state = this.state;

        if (e.target.type === 'checkbox') {
            state[e.target.name] = e.target.checked;
        }
        else {
            state[e.target.name] = e.target.value;
        }

        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.login(this.state);
    }

    showQuickPay = () => {
        this.props.setQuickPayModalVisible(true);
    }

    onSendVerificationCode = (e) => {
        this.props.sendVerificationCode(this.state);
    }

    render() {
        return (
            <div className="login-box">
                <div className="login-left">
                    {this.props.showLogoHeaderLogin &&
                        <img src={logoMyAccountLogin} alt="myAccountLogin" className="header-login-logo" />
                    }
                    <div className="login-left-content" style={{ paddingTop: this.props.showLogoHeaderLogin ? 20 : 100 }}>
                        <div className="page-header">
                            <div className="page-title-home">
                                {T.translate('login.pageTitle')}
                            </div>
                        </div>
                        <div className="diverter" />

                        {this.props.commonLoading && <div className="full-screen-overlay">
                            <img src={loading} alt="loading" />
                        </div>}

                        <MessageError>{this.props.commonErrorMessage || this.props.userErrorMessage}</MessageError>

                        <h6 className="card-title mb-5">{T.translate('login.loginWith')}</h6>
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">

                                {(T.translate('register.useEmaiOrMobileNumber')) && <label id="lblUsername" htmlFor="useEmaiOrMobileNumber">{T.translate('register.useEmaiOrMobileNumber')}</label>}
                                {!(T.translate('register.useEmaiOrMobileNumber')) && <label id="lblUsername" htmlFor="username">{T.translate('login.userName')}</label>}

                                <Tooltip placement="right-end" isOpen={this.state.tooltipUsernameOpen} target="lblUsername" toggle={this.toggleUsernameTooltip}>
                                    <div>{T.translate('login.thisIsTourEmailAddressMobileNumber')}</div>
                                </Tooltip>
                                <input name="username" id="username" type="text" className="form-control" value={this.state.username} onChange={this.onChange} autoFocus />
                            </div>
                            <div className="form-group">
                                <Password id="password"
                                    labelText={T.translate('login.password')}
                                    maxLength={30}
                                    onChange={this.onChange}
                                    value={this.state.password}
                                />
                            </div>
                            <div className="custom-control custom-checkbox d-inline-block">
                                <input type="checkbox" className="custom-control-input" id="rememberMe" name="rememberMe" value="0" onChange={this.onChange} />
                                <label className="custom-control-label" htmlFor="rememberMe">{T.translate('login.rememberMe')}</label>
                            </div>
                            <NavLink to="/forgotpassword" className="float-left float-sm-right mt-3 mt-sm-0 a-link">{T.translate('login.forgotPassword')}</NavLink>
                            <div className="clearfix" />

                            {false && <div>
                                <div className="my-or"><span>OR</span></div>
                                <p>Login with Social media</p>
                                <ul className="login-social clearfix">
                                    <li><a href="javascript:;" className="facebook"><FontAwesomeIcon icon={faFacebookF} />Facebook</a></li>
                                    <li><a href="javascript:;" className="twitter"><FontAwesomeIcon icon={faTwitter} />Twitter</a></li>
                                    <li><a href="javascript:;" className="linkedin"><FontAwesomeIcon icon={faLinkedinIn} />LinkedIn</a></li>
                                </ul>
                            </div>}

                            <hr className="mt-3 mb-3 mt-md-5 mb-md-5" />

                            <MessageError>{this.props.accountErrorMessage}</MessageError>

                            {this.props.isAccountVerificationRequired && !this.props.verificationCodeConfirmation &&
                                <MessageError>
                                    <div>
                                        <p>{T.translate('login.accountVerificationRequired')}</p>
                                        <p>{T.translate('login.thisUserNeedsVerification')}</p>
                                        <LoadingButton
                                            type="button"
                                            className="btn btn-light"
                                            onClick={this.onSendVerificationCode}
                                            loading={this.props.userLoading ? 'true' : 'false'}>
                                            {T.translate('login.sendVerificationCode')}
                                        </LoadingButton>
                                    </div>
                                </MessageError>
                            }

                            {this.props.verificationCodeConfirmation &&
                                <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                                    <div className="d-flex">
                                        <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                        <p>
                                            {T.translate('login.verificationCodeSent')}
                                        </p>
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className="col-sm-6">
                                    <LoadingButton
                                        type="submit"
                                        disabled={!this.state.username || !this.state.password || this.props.accountLoading || this.props.commonLoading}
                                        className="btn-login btn btn-primary d-block btn-lg mt-2 mb-2"
                                        onClick={this.onSubmit}
                                        loading={this.props.accountLoading || this.props.commonLoading ? 'true' : 'false'}>
                                        {T.translate('login.login')}
                                    </LoadingButton>
                                </div>
                                <div className="col-sm-6"> <NavLink to="/register" className="btn btn-outline-dark d-block btn-lg mt-2 mb-2">{T.translate('login.register')}</NavLink> </div>
                            </div>

                        </form>
                    </div>
                </div>

                <RightSectionLogin location={this.props.location} />

                <QuickPayModal />
            </div>
        );
    }
}

//https://github.com/reduxjs/redux/issues/363
export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(Login);
