import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as ProfileStore from '../../store/Profile';
import * as CommonStore from '../../store/Common';
import T from 'i18n-react';
import InputMask from 'react-input-mask';
import MessageError from '../shared/MessageError';
import LoadingOverlay from '../shared/LoadingOverlay';
import LoadingButton from '../shared/LoadingButton';
import ConfirmModal from '../shared/ConfirmModal';
import Password from '../shared/PasswordInput';
import validator from 'validator';

const Scroll = require('react-scroll');
const email = require('../../images/email.png');
const mobile = require('../../images/mobile.png');
const postal = require('../../images/postal-mail.png');
const eBill = require('../../images/postal-sm.png');

class CustomerProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            emailAddress: '',
            homePhone: '',
            mobilePhone: '',
            languageID: 1,
            billDeliveryMethodPaper: false,
            billDeliveryMethodElectBill: false,
            notificationChannelEmail: false,
            notificationChannelSms: false,
            twoFactorAuthRequired: false,
            password: '',
            passwordConfirm: '',
            passwordTooltipOpen: false,
            fields: [
                { name: 'address1', isRequired: true, conditionField: 'activeTab', conditionValue: '1' },
                { name: 'city', isRequired: true, conditionField: 'activeTab', conditionValue: '1' },
                { name: 'postalCode', isRequired: true, conditionField: 'activeTab', conditionValue: '1' },
                { name: 'emailAddress', conditionField: 'activeTab', conditionValue: '1', isEmail: true },
                { name: 'mobilePhone', conditionField: 'activeTab', conditionValue: '1' },
                { name: 'password', isRequired: true, conditionField: 'activeTab', conditionValue: '2', isPassword: true, isPasswordRuleError: false },
                { name: 'passwordConfirm', isRequired: true, conditionField: 'activeTab', conditionValue: '2', isCompare: true, compareField: 'password' },
                { name: 'twoFactorAuthRequired', isRequired: false, conditionField: 'activeTab', conditionValue: '2' }
            ]
        };

        let customer = props.account.customer;

        if (customer) {
            let state = this.state;
            this.state = { ...state, ...customer };
        }

    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('customerProfile.title');

        this.props.requestStatesList();
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            let customer = nextProps.account.customer;
            this.setState({ ...customer });

            this.props.requestStatesList();
        }

        if (nextProps.reverse2FASetting && nextProps.reverse2FASetting !== this.props.reverse2FASetting) {
            let state = this.state;
            this.setState({ twoFactorAuthRequired: !state.twoFactorAuthRequired });
        }
    }

    toggleActiveTab = (e) => {
        var tab = e.target.attributes['data-tab'].value;
        this.setState({ activeTab: tab });
    }

    onChange = (e) => {

        this.hidePasswordTooltip();

        if (e.target.name !== 'billDeliveryMethodPaper' && e.target.name !== 'billDeliveryMethodElectBill' && e.target.name !== 'notificationChannelEmail' && e.target.name !== 'notificationChannelSms') {
            let fields = this.state.fields.filter(x => x.name === e.target.name);
            if (fields && fields.length === 1) {
                let field = fields[0];

                if (field.isRequiredError && e.target.value) {
                    field.isRequiredError = false;
                }

                if (!field.isRequiredError && field.isCompareError && this.state[field.compareField] === e.target.value) {
                    field.isCompareError = false;
                }

                if (field.isRequired && field.isPassword && CommonStore.checkPasswordRules(e.target.value)) {
                    field.isPasswordRuleError = false;
                }

                if (!field.isRequiredError && field.isEmailError && validator.isEmail(e.target.value)) {
                    field.isEmailError = false;
                }
            }

            if (e.target.type === "checkbox") {
                this.setState({ [e.target.name]: e.target.checked });
            } else {
                this.setState({ [e.target.name]: e.target.value });
            }

        }

        if (e.target.name === 'billDeliveryMethodPaper') {
            const state = this.state;
            state[e.target.name] = !this.state.billDeliveryMethodPaper;
            this.setState(state);
        }

        if (e.target.name === 'billDeliveryMethodElectBill') {
            const state = this.state;
            state[e.target.name] = !this.state.billDeliveryMethodElectBill;
            this.setState(state);
        }

        if (e.target.name === 'notificationChannelEmail') {
            const state = this.state;
            state[e.target.name] = !this.state.notificationChannelEmail;
            this.setState(state);
        }

        if (e.target.name === 'notificationChannelSms') {
            const state = this.state;
            state[e.target.name] = !this.state.notificationChannelSms;
            this.setState(state);
        }
    }

    isFormValid = () => {

        var state = this.state;
        var valid = true;
        var accountBillingTypeID = this.props.account.accountBillingTypeID;
        state.passwordTooltipOpen = false;

        this.state.fields.map((field) => {

            if (field.conditionField && field.conditionValue === state[field.conditionField]) {
                field.value = this.state[field.name];
                field.isRequiredError = false;

                if (field.isRequired && !field.value) {
                    field.isRequiredError = true;
                    valid = false;
                }

                if (!field.isRequiredError && field.isCompare && this.state[field.compareField] !== field.value) {
                    field.isCompareError = true;
                    valid = false;
                }

                if (field.isRequired && field.isPassword && !CommonStore.checkPasswordRules(field.value)) {
                    field.isPasswordRuleError = true;
                    valid = false;

                    state.passwordTooltipOpen = true;
                }

                if (!field.isRequiredError && field.isEmail && field.value && !validator.isEmail(field.value)) {
                    field.isEmailError = true;
                    valid = false;
                }

                //special cases
                if (field.name === 'emailAddress' && !field.value && accountBillingTypeID === 2 && (state.billDeliveryMethodElectBill || state.notificationChannelEmail)) {
                    field.isRequiredError = true;
                    valid = false;
                }

                if (field.name === 'mobilePhone' && !field.value && state.notificationChannelSms) {
                    field.isRequiredError = true;
                    valid = false;
                }
            }

            return field;
        });

        this.setState(state);

        if (valid) {

            //special cases
            if (this.props.systemProperties.emailContactInfoRequired && !state.notificationChannelEmail) {
                this.props.setProfileError(T.translate('customerProfile.emailNotificationChannelRequired'));
                valid = false;
            }
            else if (this.props.systemProperties.smsContactInfoRequired && !state.notificationChannelSms) {
                this.props.setProfileError(T.translate('customerProfile.smsNotificationChannelRequired'));
                valid = false;
            }
            else if (!state.notificationChannelEmail && !state.notificationChannelSms) {
                this.props.setProfileError(T.translate('customerProfile.selectOneNotificationChannel'));
                valid = false;
            }

            if (valid && !state.billDeliveryMethodElectBill && !state.billDeliveryMethodPaper && accountBillingTypeID === 2) {
                this.props.setProfileError(T.translate('customerProfile.selectOneDeliveryMethod'));
                valid = false;
            }

            if (valid) {
                this.props.setProfileError('');
            }

        } else {
            this.props.setProfileError(T.translate("common.thereAreMissingFields"));
        }

        return valid;
    }

    updateProfile = () => {
        if (this.isFormValid()) {
            this.props.updateProfile(this.state);
        }
    }

    updateSecurityInfo = () => {
        if (this.isFormValid()) {
            this.props.updatePassword(this.state);
        }
    }

    updateTFASetting = (e) => {
        this.props.updateTFASetting({ twoFactorAuthRequired: this.state.twoFactorAuthRequired });
    }

    goToDashboard = (e) => {
        this.props.toggleUpdateProfileSuccessModal();
        this.props.setAccount();
        this.props.history.push('/myaccount/dashboard');
    }

    getErrorMessage = (tabSource) => {

        if (this.props.profileErrorMessage === 'TFA_PHONE_NUMBER_NOT_PRESENT') {
            return tabSource === '2' ? T.translate('customerProfile.tfaPhoneNumberNotPresent') : '';
        }

        return this.props.profileErrorMessage;
    }

    showPasswordTooltip = () => {
        this.setState({ passwordTooltipOpen: true });
    }

    hidePasswordTooltip = () => {
        this.setState({ passwordTooltipOpen: false });
    }

    render() {
        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.profileLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('customerProfile.pageTitle')}</div>
                </div>
                <ul className="nav nav-tabs">
                    <li className="nav-item"><a className={"nav-link " + (this.state.activeTab === '1' ? "active" : "")} onClick={this.toggleActiveTab} data-tab="1" href="javascript:;">{T.translate('customerProfile.profileInformation')}</a></li>
                    <li className="nav-item"><a className={"nav-link " + (this.state.activeTab === '2' ? "active" : "")} onClick={this.toggleActiveTab} data-tab="2" href="javascript:;">{T.translate('customerProfile.passwordSettings')}</a></li>
                </ul>
                <div className="tab-content mb-4">
                    {this.state.activeTab === '1' && <div className={"tab-pane " + (this.state.activeTab === '1' ? "active" : "")}>
                        <h6 className="card-title mt-4 mb-4 mb-5">{T.translate('customerProfile.personalInformation')}</h6>
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label htmlFor="address1">{T.translate('customerProfile.mailingAddress')}</label>
                                    <input type="text" id="address1" name="address1" onChange={this.onChange} value={this.state.address1} className={"form-control " + (this.state.fields[0].isRequiredError ? 'is-invalid' : '')} disabled={!this.props.systemProperties.myAcctAllowMailAddressEdit} />
                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="address2">{T.translate('customerProfile.aptNumber')}</label>
                                    <input type="text" className="form-control" id="address2" name="address2" onChange={this.onChange} value={this.state.address2} disabled={!this.props.systemProperties.myAcctAllowMailAddressEdit} />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="city">{T.translate('customerProfile.city')}</label>
                                    <input type="text" id="city" name="city" onChange={this.onChange} value={this.state.city} className={"form-control " + (this.state.fields[1].isRequiredError ? 'is-invalid' : '')} disabled={!this.props.systemProperties.myAcctAllowMailAddressEdit} />
                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="state">{T.translate('customerProfile.state')}</label>
                                    <select className="form-control" id="state" name="state" onChange={this.onChange} value={this.state.state} disabled={!this.props.systemProperties.myAcctAllowMailAddressEdit}>
                                        {this.props.statesList.map((state, i) =>
                                            <option key={i} value={state.value}>{state.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="postalCode">{T.translate('customerProfile.zipCode')}</label>
                                    <input type="text" id="postalCode" name="postalCode" onChange={this.onChange} value={this.state.postalCode} className={"form-control " + (this.state.fields[2].isRequiredError ? 'is-invalid' : '')} disabled={!this.props.systemProperties.myAcctAllowMailAddressEdit} />
                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                </div>
                            </div>
                        </div>
                        {!this.props.systemProperties.myAcctAllowMailAddressEdit && <div className="clarification">
                            <small>{T.translate('customerProfile.editAddressNotAllowed')}</small>*
                            <hr />
                        </div>}
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="emailAddress">{T.translate('customerProfile.emailAddress')}</label>
                                    <input type="text" id="emailAddress" name="emailAddress" onChange={this.onChange} value={this.state.emailAddress} className={"form-control " + (this.state.fields[3].isRequiredError || this.state.fields[3].isEmailError ? 'is-invalid' : '')} />
                                    {this.state.fields[3].isRequiredError && <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>}
                                    {this.state.fields[3].isEmailError && <div className="invalid-feedback">{T.translate('common.provideValidEmail')}</div>}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="homePhone">{T.translate('customerProfile.homePhone')}</label>
                                    <InputMask mask="(999) 999-9999" className="form-control" id="homePhone" name="homePhone" onChange={this.onChange} value={this.state.homePhone} />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label htmlFor="mobilePhone">{T.translate('customerProfile.mobilePhone')}</label>
                                    <InputMask mask="(999) 999-9999" id="mobilePhone" name="mobilePhone" onChange={this.onChange} value={this.state.mobilePhone} className={"form-control " + (this.state.fields[4].isRequiredError ? 'is-invalid' : '')} />
                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                </div>
                            </div>
                        </div>
                        {this.props.systemProperties.myAcctShowLanguagePreference && <div className="card mt-4 mt-sm-5 mb-4">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-8 align-self-center">
                                        <h6 className="card-title">{T.translate('customerProfile.languagePreference')}</h6>
                                        <p className="mb-0">{T.translate('customerProfile.notificationsProcessed')}</p>
                                    </div>
                                    <div className="col-md-4 align-self-center">
                                        <select className="form-control" id="languageID" name="languageID" onChange={this.onChange} value={this.state.languageID}>
                                            <option value="1">{T.translate('customerProfile.english')}</option>
                                            <option value="2">{T.translate('customerProfile.spanish')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <div className="card mb-4 mb-sm-5">
                            <div className="card-body">
                                <div className="account-noti-box p-0 m-0 bg-white">
                                    <div className="account-noti-left">
                                        <h6 className="card-title">{T.translate('customerProfile.notificationChannels')}</h6>
                                        <p className="mb-0">{T.translate('customerProfile.thereAreTwoDifferentMethodsUnderWhichYouCanReceiveNotifications')}</p>
                                    </div>
                                    <ul className="noti-switch">
                                        <li>
                                            <span className="notifi-icon"><img src={email} alt="email" title={T.translate('customerProfile.email')} /></span>
                                            <label className="switch" htmlFor="notificationChannelEmail">
                                                <input type="checkbox" id="notificationChannelEmail" name="notificationChannelEmail" onChange={this.onChange} checked={this.state.notificationChannelEmail} />
                                                <span className="slider round" />
                                            </label>
                                            <div className="text-center">{T.translate('customerProfile.email')}</div>
                                        </li>
                                        <li>
                                            <span className="notifi-icon"><img src={mobile} alt="mobile" title={T.translate('customerProfile.sms')} /></span>
                                            <label className="switch" htmlFor="notificationChannelSms">
                                                <input type="checkbox" id="notificationChannelSms" name="notificationChannelSms" onChange={this.onChange} checked={this.state.notificationChannelSms} />
                                                <span className="slider round" />
                                            </label>
                                            <div className="text-center">{T.translate('customerProfile.sms')}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {this.props.account.accountBillingTypeID === 2 && <div className="card mb-4 mb-sm-5">
                            <div className="card-body">
                                <div className="account-noti-box p-0 m-0 bg-white">
                                    <div className="account-noti-left">
                                        <h6 className="card-title">{T.translate('customerProfile.billDeliveryMethod')}</h6>
                                        <p className="mb-0">{T.translate('customerProfile.thereAreTwoDifferentMethodsUnderWhichYouCanReceiveYourBills')}</p>
                                    </div>
                                    <ul className="noti-switch">
                                        <li>
                                            <span className="notifi-icon"><img src={postal} alt="email" title={T.translate('customerProfile.postalMail')} /></span>
                                            <label className="switch" htmlFor="billDeliveryMethodPaper">
                                                <input type="checkbox" id="billDeliveryMethodPaper" name="billDeliveryMethodPaper" onChange={this.onChange} checked={this.state.billDeliveryMethodPaper} />
                                                <span className="slider round" />
                                            </label>
                                            <div className="text-center">{T.translate('customerProfile.postalMail')}</div>
                                        </li>
                                        <li>
                                            <span className="notifi-icon"><img src={eBill} alt="mobile" title={T.translate('customerProfile.eBill')} /></span>
                                            <label className="switch" htmlFor="billDeliveryMethodElectBill">
                                                <input type="checkbox" id="billDeliveryMethodElectBill" name="billDeliveryMethodElectBill" onChange={this.onChange} checked={this.state.billDeliveryMethodElectBill} />
                                                <span className="slider round" />
                                            </label>
                                            <div className="text-center">{T.translate('customerProfile.eBill')}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>}

                        <MessageError>{this.getErrorMessage('1')}</MessageError>

                        <LoadingButton
                            type="button"
                            className="btn btn-primary mb-3 mr-3"
                            disabled={this.props.profileLoading}
                            loading={this.props.profileLoading ? 'true' : 'false'}
                            onClick={this.updateProfile}
                        >
                            {T.translate('customerProfile.saveUpdates')}
                        </LoadingButton>

                        <NavLink to="/myaccount/dashboard" className="btn btn-outline-dark mb-3">{T.translate('common.cancel')}</NavLink>

                    </div>}

                    {this.state.activeTab === '2' && <div className={"tab-pane " + (this.state.activeTab === '2' ? "active" : "")}>

                        <MessageError>{this.getErrorMessage('2')}</MessageError>

                        <h6 className="card-title mt-4 mb-4 mb-5">{T.translate('customerProfile.passwordSecurity')}</h6>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <Password
                                        id="password"
                                        name="password"
                                        value=""
                                        className={(this.state.fields[5].isRequiredError || this.state.fields[5].isPasswordRuleError ? 'is-invalid' : '')}
                                        labelText={T.translate('customerProfile.newPassword')}
                                        maxLength={30}
                                        messageError={this.state.fields[5].isRequiredError ? T.translate('common.thisFieldIsRequired') : (this.state.fields[5].isPasswordRuleError ? T.translate('common.passwordRulesError') : '')}
                                        onChange={this.onChange}
                                        checked={this.state.password}
                                        showPasswordRules
                                        showTooltip={this.showPasswordTooltip}
                                        hideTooltip={this.hidePasswordTooltip}
                                        tooltipOpen={this.state.passwordTooltipOpen}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <Password
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        className={(this.state.fields[6].isRequiredError || this.state.fields[6].isCompareError ? 'is-invalid' : '')}
                                        labelText={T.translate('customerProfile.confirmPassword')}
                                        maxLength={30}
                                        messageError={T.translate('common.enterSameValue')}
                                        onChange={this.onChange}
                                        checked={this.state.passwordConfirm}
                                    />
                                </div>
                            </div>
                        </div>

                        <LoadingButton
                            type="button"
                            className="btn btn-primary mb-3 mr-3"
                            style={{marginTop:'20px'}}
                            disabled={this.props.profileLoading}
                            loading={this.props.profileLoading ? 'true' : 'false'}
                            onClick={this.updateSecurityInfo}
                        >
                            {T.translate('customerProfile.saveNewPassword')}
                        </LoadingButton>

                        <h6 className="card-title mt-4 mb-4 mb-5">{T.translate('customerProfile.securitySettings')}</h6>

                        {false && <div>
                            <h6 className="card-title mt-4 mb-4 mb-5">{T.translate('customerProfile.securePin')}</h6>
                            <div className="row">
                                <div className="col-lg-2 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="new-pin">{T.translate('customerProfile.newPin')}</label>
                                        <input type="text" className="form-control" id="new-pin" />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="verify-pin">{T.translate('customerProfile.verifyPin')}</label>
                                        <input type="text" className="form-control" id="verify-pin" />
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className="card mb-4 mb-sm-5 mt-4 mt-sm-5">
                            <div className="card-body">
                                <div className="account-noti-box p-0 m-0 bg-white">
                                    <div className="account-noti-left">
                                        <h6 className="card-title">{T.translate('customerProfile.twoFactorAuthentication')}</h6>
                                        <p className="mb-0">{T.translate('customerProfile.twoFactorAuthenticationRequiresAdditionalStep')}</p>
                                    </div>
                                    <ul className="noti-switch">
                                        <li className="p-0" />
                                        <li>
                                            <label className="switch" htmlFor="twoFactorAuthRequired">
                                                <input type="checkbox" id="twoFactorAuthRequired" name="twoFactorAuthRequired" onChange={this.onChange} checked={this.state.twoFactorAuthRequired} />
                                                <span className="slider round" />
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <LoadingButton
                            type="button"
                            className="btn btn-primary mb-3 mr-3"
                            disabled={this.props.profileLoading}
                            loading={this.props.profileLoading ? 'true' : 'false'}
                            onClick={this.updateTFASetting}
                        >
                            {T.translate('customerProfile.save2FA')}
                        </LoadingButton>

                    </div>}

                    <ConfirmModal
                        title={T.translate('customerProfile.profileUpdated')}
                        open={this.props.showUpdateProfileSuccessModal}
                        onToggle={this.goToDashboard}
                        loading={this.props.profileLoading}
                    >
                        <div>
                            <p>{T.translate('customerProfile.profileUpdated')}</p>
                            <p className="font-weight-bold">{T.translate('customerProfile.customerProfileSuccessfullyUpdated')}</p>
                        </div>
                    </ConfirmModal>

                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.profile, ...state.common }),
    dispatch => bindActionCreators({ ...ProfileStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(CustomerProfile);
