import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as ServiceStore from '../../store/Service';
import T from 'i18n-react';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoadingButton from '../shared/LoadingButton';
import ConfirmModal from '../shared/ConfirmModal';
import BasicModal from '../shared/BasicModal';
import UspsAddressVerification from '../shared/UspsAddressVerification';

const Scroll = require('react-scroll');
const transferIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/transfer-icon.png`);

class TransferService extends Component {

    constructor(props) {
        super(props);

        this.state = {
            address: '',
            apartmentNumber: '',
            city: '',
            state: '',
            zip: '',
            esiID: '',
            open: false,
            moveOutDate: '',
            moveInDate: '',
            billingAddress: {},
            acctAddressAddress1: '',
            acctAddressAddress2: '',
            acctAddressCity: '',
            acctAddressState: '',
            acctAddressPostalCode: '',
            accountServicePointID: 0,
            distributionServiceProviderID: -1,
            mviDistributionServiceProviderID: -1,
            validSpStatus: [8, 10, 11, 18],
            validSevicePoints: [],
            fields: [
                { name: 'acctAddressAddress1', isRequired: true },
                { name: 'acctAddressCity', isRequired: true },
                { name: 'acctAddressState', isRequired: true },
                { name: 'acctAddressPostalCode', isRequired: true },
                { name: 'moveOutDate', isRequired: true },
                { name: 'moveInDate', isRequired: true },
                { name: 'accountServicePointID', isRequired: true },
            ],
            disabledSubmitTransfer: false
        };

        this.addressInput = React.createRef();
    }


    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('transferService.title');

        if (this.props.account.accountNumber) {
            let distributionServiceProviderID = -1;
            let validSevicePoints = this.props.account.servicePoints.filter(x => this.state.validSpStatus.includes(x.servicePointStatusID));

            if (validSevicePoints.length === 1) {
                const sp = validSevicePoints[0];
                this.props.setServicePoint(sp);
                distributionServiceProviderID = sp.distributionServiceProviderID;
                this.setState({ validSevicePoints, accountServicePointID: sp.accountServicePointID, distributionServiceProviderID });
            } else {

                if (validSevicePoints.length === 0) {
                    this.props.showServiceError(T.translate('transferService.errorNoActivePremiseOnAccount'));
                }

                this.props.setServicePoint({});
                this.setState({ validSevicePoints, accountServicePointID: 0, distributionServiceProviderID });
            }

            this.props.resetCommonState({ commonLoading: false, commonErrorMessage: '', uspsAddress: { status: this.props.constants.uspsAddressStatus.PENDING } });
            this.checkStatesListNCalendar(distributionServiceProviderID, 3);//Mvo:3
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if (nextProps.account.accountNumber !== this.props.account.accountNumber) {
            let distributionServiceProviderID = -1;
            var validSevicePoints = nextProps.account.servicePoints.filter(x => this.state.validSpStatus.includes(x.servicePointStatusID));

            if (validSevicePoints.length === 1) {
                const sp = validSevicePoints[0];
                nextProps.setServicePoint(sp);
                distributionServiceProviderID = sp.distributionServiceProviderID;
                this.setState({ validSevicePoints, accountServicePointID: sp.accountServicePointID, distributionServiceProviderID });
            } else {

                if (validSevicePoints.length === 0) {
                    this.props.showServiceError(T.translate('transferService.errorNoActivePremiseOnAccount'));
                }

                this.props.setServicePoint({});
                this.setState({ validSevicePoints, accountServicePointID: 0, distributionServiceProviderID });
            }

            this.props.resetCommonState({ commonLoading: false, commonErrorMessage: '', uspsAddress: { status: this.props.constants.uspsAddressStatus.PENDING } });
            this.checkStatesListNCalendar(distributionServiceProviderID, 3);//Mvo:3
        }

        if (nextProps.distributionPoint.address1 && this.props.distributionPoint.address1 !== nextProps.distributionPoint.address1) {
            this.setState({
                acctAddressAddress1: nextProps.distributionPoint.address1,
                acctAddressAddress2: nextProps.distributionPoint.address2,
                acctAddressCity: nextProps.distributionPoint.city,
                acctAddressState: nextProps.distributionPoint.stateCode,
                acctAddressPostalCode: nextProps.distributionPoint.zipCode,
                mviDistributionServiceProviderID: nextProps.distributionPoint.distributionServiceProviderID
            });
            this.checkStatesListNCalendar(nextProps.distributionPoint.distributionServiceProviderID, 1);//Enrollment:1
        }
    }

    onChange = (e) => {

        let fields = this.state.fields.filter(x => x.name === e.target.name);
        if (fields && fields.length === 1) {
            let field = fields[0];

            if (field.isRequired && field.isRequiredError && e.target.value) {
                field.isRequiredError = false;
            }
        }

        this.setState({ [e.target.name]: e.target.value });
    }

    onSearchClick = async () => {

        let getDistributionPoints = false;

        if (this.state.esiID) {
            getDistributionPoints = true;
        } else {
            if (this.state.address && this.state.city && this.state.state && this.state.zip) {
                getDistributionPoints = true;
            }
        }

        if (getDistributionPoints) {
            await this.props.getDistributionPoints(this.state);
            this.props.getWebProductInformation(this.state);
        } else {
            this.props.showServiceError(T.translate('transferService.errorFieldRequiredToMakeSearch'));
        }
    }

    handleChangeServiceMoveInDate = (e) => {
        this.setState({ moveInDate: e });
    }

    handleChangeServiceMoveOutDate = (e) => {
        this.setState({ moveOutDate: e });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    onChangeLocationClick = () => {

        this.setState({
            address: '',
            apartmentNumber: '',
            city: '',
            state: '',
            zip: '',
            esiID: '',
            billingAddress: {},
            acctAddressAddress1: '',
            acctAddressAddress2: '',
            acctAddressCity: '',
            acctAddressState: '',
            acctAddressPostalCode: '',
            mviDistributionServiceProviderID: -1
        });

        this.props.setDistributionPoint({});
        this.addressInput.current.focus();
    }

    setDistributionPoint = (dp) => {
        this.props.setDistributionPoint(dp);
        this.props.setDistributionPointModalVisible(false);
    }

    onToggle = () => {
        this.props.setDistributionPointModalVisible(false);
    }

    onToggleConfirm = () => {
        this.props.setTransferServiceConfirmModalVisible(false);
    }

    onToggleCancel = () => {
        this.props.resetCommonState({ uspsAddress: { status: this.props.constants.uspsAddressStatus.PENDING } });
        this.props.setTransferServiceCancelModalVisible(false);
    }

    onToggleSuccess = () => {
        this.props.setTransferServiceSuccessModalVisible(false);
    }

    onCancelClick = () => {
        this.props.setTransferServiceCancelModalVisible(true);
    }

    goToDashboard = () => {
        this.props.setTransferServiceCancelModalVisible(false);
        this.props.setTransferServiceSuccessModalVisible(false);
        this.props.history.push('/myaccount/dashboard');
    }

    onSelectLocation = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);

        let sps = this.state.validSevicePoints.filter(x => x.accountServicePointID === parseInt(e.target.value));
        let distributionServiceProviderID = -1;

        if (sps && sps.length) {
            this.props.setServicePoint(sps[0]);
            distributionServiceProviderID = sps[0].distributionServiceProviderID;
        } else {
            this.props.setServicePoint({});
        }

        this.setState({ distributionServiceProviderID });

        this.checkStatesListNCalendar(distributionServiceProviderID, 3);//Mvo:3

    }

    finalAddressSelection = (preferredAddress) => {
        let state = this.state;
        let billingAddress = this.props.uspsAddress;

        if (preferredAddress !== this.props.constants.addressSource.USPS) {
            billingAddress.address1 = state.acctAddressAddress1;
            billingAddress.address2 = state.acctAddressAddress2;
            billingAddress.city = state.acctAddressCity;
            billingAddress.state = state.acctAddressState;
            billingAddress.postalCode = state.acctAddressPostalCode;
        }

        this.props.resetCommonState({ uspsAddress: { status: this.props.constants.uspsAddressStatus.PENDING } });
        this.setState({ billingAddress });
        this.props.setTransferServiceConfirmModalVisible(true);
    }

    checkStatesListNCalendar = (distributionServiceProviderID, workingDaysGroupID) => {
        this.props.requestNonWorkingDaysNStates(distributionServiceProviderID, workingDaysGroupID);
    }

    onSubmitTransfer = () => {

        var state = this.state;
        var valid = true;

        this.state.fields.map((field) => {

            field.value = this.state[field.name];
            field.isRequiredError = false;

            if (field.isRequired && !field.value) {
                field.isRequiredError = true;
                valid = false;
            }

        });
        this.setState(state);
        var boolDisabled = this.props.selectedWebProduct === '' || this.props.selectedWebProduct === 'undefined' || this.props.selectedWebProduct === null;

        this.setState({
            disabledSubmitTransfer: boolDisabled
        });

        if (valid) {
            this.props.showServiceError('');

            //now see if distribution point is set
            if (this.props.distributionPoint.serviceProviderIdentifier) {
                let state = this.state;
                let distributionPoint = this.props.distributionPoint;

                if (state.acctAddressAddress1 !== distributionPoint.address1 || state.acctAddressAddress2 !== distributionPoint.address2 || state.acctAddressCity !== distributionPoint.city
                    || state.acctAddressState !== distributionPoint.stateCode || state.acctAddressPostalCode !== distributionPoint.zipCode) {
                    var request = {
                        address1: state.acctAddressAddress1,
                        address2: state.acctAddressAddress2,
                        city: state.acctAddressCity,
                        state: state.acctAddressState,
                        postalCode: state.acctAddressPostalCode
                    };
                    this.props.requestUpsAddressCheck(request);
                } else {
                    let billingAddress = this.props.uspsAddress;
                    billingAddress.address1 = distributionPoint.address1;
                    billingAddress.address2 = distributionPoint.address2;
                    billingAddress.city = distributionPoint.city;
                    billingAddress.state = distributionPoint.stateCode;
                    billingAddress.postalCode = distributionPoint.zipCode;

                    this.setState({ billingAddress });
                    this.props.setTransferServiceConfirmModalVisible(true);
                }
            } else {
                this.props.showServiceError('Please, search for the New Service Location.');
            }

        } else {
            this.props.showServiceError(T.translate("common.thereAreMissingFields"));
        }

    }

    onSubmitTransferConfirm = () => {
        this.props.createTransferOfService(this.state);
    }

    getNonWorkingDays = (workingGroup) => {
        var wgGroupID = 1;//mvi-switch
        var distributionServiceProviderID = this.state.mviDistributionServiceProviderID;

        if (workingGroup === 'mvo') {
            wgGroupID = 3;//mvo
            distributionServiceProviderID = this.state.distributionServiceProviderID;
        }

        var tduCalendar = this.props.nonWorkingDaysByTdu.find(x => x.distributionServiceProviderID === distributionServiceProviderID && x.workingDaysGroupID === wgGroupID);

        if (tduCalendar) {
            return tduCalendar.nonWorkingDays;
        } else {
            return [];
        }

    }

    render() {
        return (
            <div className="p-main">
                <div className="left-sidebar">
                    <div className="p-main">

                        <LoadingOverlay loading={this.props.serviceLoading || this.props.serviceLoading} />

                        <div className="page-header">
                            <div className="page-title">{T.translate('transferService.pageTitle')}</div>
                            <p className="lead text-dark font-weight-normal kanit">{T.translate('transferService.accountNumber')}: {this.props.account.accountNumber}</p>
                        </div>

                        <div className="card mb-5">
                            <div className="card-body">

                                {this.state.validSevicePoints.length > 1 && <React.Fragment>
                                    <div className="row">
                                        <div className="col-md-4 form-group">
                                            <select id="accountServicePointID" name="accountServicePointID" style={{ minWidth: "290px" }} value={this.state.accountServicePointID} onChange={this.onSelectLocation} className={"form-control " + (this.state.fields[6].isRequiredError ? 'is-invalid' : '')}>
                                                <option key={0} value={0}>{T.translate('transferService.select')}</option>
                                                {this.state.validSevicePoints.map((sp, i) =>
                                                    <option key={sp.accountServicePointID} value={sp.accountServicePointID}>{sp.fullAddress}</option>
                                                )}
                                            </select>
                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                        </div>
                                    </div>
                                </React.Fragment>}

                                <div className="row">
                                    <div className="col-md-6 d-flex align-self-center">
                                        <img src={transferIcon} width="80" height="79" alt="transfer" />
                                        <div className="ml-3">
                                            <h6 className="card-title">{T.translate('transferService.existingServiceLocation')}</h6>
                                            <p className="lead mb-0">{this.props.servicePoint.fullAddress}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2 mt-4 mt-md-0">
                                        <label htmlFor="requested-service">{T.translate('transferService.moveOutDate')}</label>
                                        <div className="date-time">
                                            <DatePicker className={"form-control " + (this.state.fields[4].isRequiredError ? 'is-invalid' : '')}
                                                id="serviceMoveOutDate"
                                                name="serviceMoveOutDate"
                                                selected={this.state.moveOutDate}
                                                onChange={this.handleChangeServiceMoveOutDate}
                                                onChangeRaw={this.handleDateChangeRaw}
                                                minDate={new Date()}
                                                excludeDates={this.getNonWorkingDays('mvo')}
                                                dateFormat="MM/dd/yy"
                                                disabledKeyboardNavigation
                                            />
                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <h6 className="card-title mt-3 mb-4">{T.translate('transferService.newServiceLocation')}</h6>
                                <div className="row">
                                    <div className="col-md-4 form-group">
                                        <label htmlFor="address">{T.translate('transferService.address')}</label>
                                        <input type="text" ref={this.addressInput} className="form-control" id="address" name="address" value={this.state.address} onChange={this.onChange} />
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label htmlFor="apartmentNumber">{T.translate('transferService.aptNumber')}</label>
                                        <input type="text" className="form-control" id="apartmentNumber" name="apartmentNumber" value={this.state.apartmentNumber} onChange={this.onChange} />
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label htmlFor="city">{T.translate('transferService.city')}</label>
                                        <input type="text" className="form-control" id="city" name="city" value={this.state.city} onChange={this.onChange} />
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label htmlFor="state">{T.translate('transferService.state')}</label>
                                        <select className="form-control" id="state" name="state" onChange={this.onChange} value={this.state.state}>
                                            <option key={0} value="">{T.translate('transferService.select')}</option>
                                            {this.props.statesList.map((state, i) =>
                                                <option key={i} value={state.value}>{state.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label htmlFor="zip">{T.translate('transferService.zipCode')}</label>
                                        <input type="text" className="form-control" id="zip" name="zip" value={this.state.zip} onChange={this.onChange} />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="esi-id">{T.translate('transferService.serviceIdentifier')}</label>
                                        <input type="text" className="form-control" id="esiID" name="esiID" value={this.state.esiID} onChange={this.onChange} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <LoadingButton
                                            type="button"
                                            className="btn btn-outline-dark mt-4 pull-right"
                                            disabled={this.props.serviceLoading}
                                            loading={this.props.serviceLoading ? 'true' : 'false'}
                                            onClick={this.onSearchClick}>
                                            {T.translate('transferService.search')}
                                        </LoadingButton>
                                    </div>
                                </div>

                                <MessageError>{this.props.serviceErrorMessage}</MessageError>

                                <hr />

                                <div className="row">
                                    <div className="col-md-6">
                                        <span style={{ paddingBottom: "10px" }}>
                                            <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.address')}: </h6>
                                            <label id="lblAddress">{this.props.distributionPoint.address1 && (this.props.distributionPoint.address1 + ' ' + this.props.distributionPoint.address2 + ' ' + this.props.distributionPoint.city + ', ' + this.props.distributionPoint.stateCode + ' ' + this.props.distributionPoint.zipCode)}</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        {this.props.distributionPoint.address1 && <button type="button" className="btn btn-outline-dark" onClick={this.onChangeLocationClick}>{T.translate('transferService.changeLocation')}</button>}
                                    </div>
                                    <div className="col-md-12">
                                        <span style={{ paddingBottom: "10px" }}>
                                            <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.serviceIdentifier')}: </h6>
                                            <label id="lblAddress">{this.props.distributionPoint.serviceProviderIdentifier}</label>
                                        </span>
                                    </div>
                                    <div className="col-md-2">
                                        <h6 className="card-title">{T.translate('transferService.moveInDate')}</h6>
                                        <div className="date-time">
                                            <DatePicker className={"form-control " + (this.state.fields[5].isRequiredError ? 'is-invalid' : '')}
                                                id="serviceMoveInDate"
                                                name="serviceMoveInDate"
                                                selected={this.state.moveInDate}
                                                onChange={this.handleChangeServiceMoveInDate}
                                                onChangeRaw={this.handleDateChangeRaw}
                                                minDate={new Date()}
                                                excludeDates={this.getNonWorkingDays('mvi')}
                                                dateFormat="MM/dd/yy"
                                                disabledKeyboardNavigation
                                            />
                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <h6 className="card-title mt-3 mb-4">{T.translate('transferService.billingAddress')}</h6>
                                <div className="row">
                                    <div className="col-md-4 form-group">
                                        <label htmlFor="acctAddressAddress1">{T.translate('transferService.billingAddress')}</label>
                                        <input type="text" id="acctAddressAddress1" name="acctAddressAddress1" value={this.state.acctAddressAddress1} onChange={this.onChange} className={"form-control " + (this.state.fields[0].isRequiredError ? 'is-invalid' : '')} />
                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label htmlFor="acctAddressAddress2">{T.translate('transferService.aptNumber')}</label>
                                        <input type="text" className="form-control" id="acctAddressAddress2" name="acctAddressAddress2" value={this.state.acctAddressAddress2} onChange={this.onChange} />
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label htmlFor="acctAddressCity">{T.translate('transferService.city')}</label>
                                        <input type="text" id="acctAddressCity" name="acctAddressCity" value={this.state.acctAddressCity} onChange={this.onChange} className={"form-control " + (this.state.fields[1].isRequiredError ? 'is-invalid' : '')} />
                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label htmlFor="acctAddressState">{T.translate('transferService.state')}</label>
                                        <select id="acctAddressState" name="acctAddressState" value={this.state.acctAddressState} onChange={this.onChange} className={"form-control " + (this.state.fields[2].isRequiredError ? 'is-invalid' : '')}>
                                            <option key={0} value="">{T.translate('transferService.select')}</option>
                                            {this.props.statesList.map((state, i) =>
                                                <option key={i} value={state.value}>{state.name}</option>
                                            )}
                                        </select>
                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label htmlFor="acctAddressPostalCode">{T.translate('transferService.zipCode')}</label>
                                        <input type="text" id="acctAddressPostalCode" name="acctAddressPostalCode" value={this.state.acctAddressPostalCode} onChange={this.onChange} className={"form-control " + (this.state.fields[3].isRequiredError ? 'is-invalid' : '')} />
                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <MessageError>{this.props.serviceErrorMessage}</MessageError>

                        <button type="button" className="btn btn-outline-dark mr-3" onClick={this.onCancelClick}>{T.translate('common.cancel')}</button>
                        <button type="button" className="btn btn-primary" onClick={this.onSubmitTransfer}>{T.translate('transferService.submitTransfer')}</button>

                        <Modal className="distribution-points-modal" isOpen={this.props.showDistributionPointsModal} size="lg" style={{ maxWidth: '1000px', width: '80%' }}>
                            <ModalHeader toggle={this.onToggle} className="bg-primary"><span className="text-white">{T.translate('transferService.selectNewServiceLocation')}</span></ModalHeader>
                            <ModalBody>

                                <div className="js-responsive-table mb-5">
                                    <table className="">
                                        <tbody>
                                            <tr>
                                                <th />
                                                <th>{T.translate('transferService.serviceAddress')}</th>
                                                <th>{T.translate('transferService.aptNumber')}</th>
                                                <th>{T.translate('transferService.city')}</th>
                                                <th>{T.translate('transferService.state')}</th>
                                                <th>{T.translate('transferService.zipCode')}</th>
                                                <th>{T.translate('transferService.locationIdentifier')}</th>
                                            </tr>
                                            {this.props.distributionPoints && this.props.distributionPoints.map(address =>
                                                (<tr key={address.serviceProviderIdentifier}>
                                                    <td>
                                                        <input type="radio" name="optradio1" onClick={() => { this.setDistributionPoint(address); }} />
                                                    </td>
                                                    <td>{address.address1}</td>
                                                    <td>{address.address2}</td>
                                                    <td>{address.city}</td>
                                                    <td>{address.stateCode}</td>
                                                    <td>{address.zipCode}</td>
                                                    <td>{address.serviceProviderIdentifier}</td>
                                                </tr>)
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn pull-right" onClick={this.onToggle}>{T.translate('common.close')}</button>
                            </ModalFooter>
                        </Modal>

                        <Modal className="transfer-service-modal" isOpen={this.props.showTransferServiceConfirmModal} size="lg" style={{ maxWidth: '900px', width: '80%' }}>
                            <ModalHeader toggle={this.onToggleConfirm} className="bg-primary"><span className="text-white">{T.translate('transferService.transferOfServiceConfirmation')}</span></ModalHeader>
                            <ModalBody>

                                <MessageError>{this.props.accountErrorMessage}</MessageError>

                                <div className="">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.moveOutServiceLocationAddress')}: </h6>
                                                <label id="lblAddress">{this.props.servicePoint.fullAddress}</label>
                                            </span>
                                        </div>
                                        <div className="col-md-12">
                                            <span>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.moveOutServiceLocationIdentifier')}: </h6>
                                                <label id="lblAddress">{this.props.servicePoint.serviceProviderIdentifier}</label>
                                            </span>
                                        </div>
                                        <div className="col-md-12">
                                            <span>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.moveOutDate')}: </h6>
                                                <label id="lblAddress">{CommonStore.formatDate(this.state.moveOutDate)}</label>
                                            </span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.moveInServiceLocationAddress')}: </h6>
                                                <label id="lblAddress">{this.props.distributionPoint.address1 + ' ' + this.props.distributionPoint.address2 + ' ' + this.props.distributionPoint.city + ', ' + this.props.distributionPoint.stateCode + ' ' + this.props.distributionPoint.zipCode}</label>
                                            </span>
                                        </div>
                                        <div className="col-md-12">
                                            <span>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.moveInServiceLocationIdentifier')}: </h6>
                                                <label id="lblAddress">{this.props.distributionPoint.serviceProviderIdentifier}</label>
                                            </span>
                                        </div>
                                        <div className="col-md-12">
                                            <span>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.moveInDate')}: </h6>
                                                <label id="lblAddress">{CommonStore.formatDate(this.state.moveInDate)}</label>
                                            </span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.billingAddress')}: </h6>
                                                <label id="lblAddress">{this.state.billingAddress.address1 + ' ' + this.state.billingAddress.address2 + ' ' + this.state.billingAddress.city + ', ' + this.state.billingAddress.state + ' ' + this.state.billingAddress.postalCode}</label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <span>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('transferService.productPlan')}: </h6>
                                                <label id="lblAddress">{this.props.selectedWebProduct.productDisplayName}</label>
                                            </span>
                                        </div>
                                    </div>

                                    {this.props.selectedWebProduct &&
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="table-responsive table-free-night">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>{T.translate('transferService.effectiveRate')}</th>
                                                                <th>{T.translate('transferService.term')}</th>
                                                                <th>{T.translate('transferService.terminationFee')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{CommonStore.formatDecimal(this.props.selectedWebProduct.kwhAvgPrice, 1)}  ¢ <span>{T.translate("transferService.perkWh")}</span></td>
                                                                <td>
                                                                    <strong>
                                                                        {this.props.selectedWebProduct.contractLengthName ? (this.props.selectedWebProduct.contractLengthName + ' ' +
                                                                            this.props.selectedWebProduct.contractPeriodName)
                                                                            : T.translate("common.none")}
                                                                    </strong>
                                                                </td>
                                                                <td><small>$</small><strong>{this.props.selectedWebProduct.earlyTerminationFee}</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.props.selectedWebProduct && this.props.selectedWebProduct.productDocuments &&
                                        <React.Fragment>
                                            <p><strong>{T.translate("addLocation.consumerInfo")}</strong></p>
                                            <ul className="free-night-ul">
                                                {
                                                    this.props.selectedWebProduct.productDocuments.map((document) =>
                                                    (
                                                        <li><a href={document.documentURL} target="_blank" className="a-underline">{document.documentName}</a></li>
                                                    ))
                                                }
                                            </ul>
                                        </React.Fragment>
                                    }

                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>
                                                {T.translate('transferService.acknowledgment', this.props.systemProperties)}
                                            </p>
                                        </div>
                                    </div>

                                    {this.props.systemProperties.myAcctHoldEnrollmentTransferServiceAddLocation &&
                                        <React.Fragment>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p><strong>{CommonStore.textWrap(this.props.systemProperties['myAcctTranServiceAddLocationTitle' + this.props.lang.toUpperCase()], ';')}</strong></p>
                                                    <p>
                                                        {CommonStore.textWrap(this.props.systemProperties['myAcctTranServiceAddLocationDes' + this.props.lang.toUpperCase()], ';')}
                                                    </p>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn pull-right" onClick={this.onToggleConfirm}>{T.translate('common.close')}</button>
                                <LoadingButton
                                    type="button"
                                    className="btn pull-right btn-primary"
                                    disabled={this.props.serviceLoading || this.state.disabledSubmitTransfer}
                                    loading={this.props.serviceLoading ? 'true' : 'false'}
                                    onClick={this.onSubmitTransferConfirm}>
                                    {T.translate('common.submit')}
                                </LoadingButton>
                            </ModalFooter>
                        </Modal>

                        <ConfirmModal
                            title={T.translate('transferService.confirmDialog')}
                            open={this.props.showTransferServiceCancelModal}
                            onToggle={this.onToggleCancel}
                            onConfirm={this.goToDashboard}
                            confirmButtonText={T.translate('transferService.yes')}>
                            <div><p>{T.translate('transferService.areYouSureYouWantToNavigateAwayFromThisScreen')}</p></div>
                        </ConfirmModal>

                        <ConfirmModal
                            title={T.translate('transferService.transferOfServiceRequestConfirmTitle')}
                            open={this.props.showTransferServiceSuccessModal}
                            onConfirm={this.goToDashboard}
                            confirmButtonText={T.translate('transferService.ok')}>
                            <div><p>{T.translate('transferService.yourRequestTransferServiceHasBeenSubmitted', this.props.systemProperties)}</p></div>
                        </ConfirmModal>

                        <UspsAddressVerification uspsAddressFoundText={T.translate('common.uspsAddressFound')} onCancel={this.onToggleCancel} onAddressSelection={this.finalAddressSelection} />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.service, ...state.account, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...ServiceStore.actionCreators }, dispatch)
)(TransferService);
