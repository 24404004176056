import React from 'react';
import T from 'i18n-react';
const visa = require('../../images/visa.png');
const mc = require('../../images/mastercard.png');
const dis = require('../../images/discover.png');
const amex = require('../../images/american-express.png');
const bank = require('../../images/bank.png');

export const PaymentAccountSingleValue = props => {

    let img = visa;

    if (props.data.paymentAccountTypeID === 4) {
        img = bank; //eCheck
    } else {
        if (props.data.cardTypeID === 2) {
            img = mc;
        } else if (props.data.cardTypeID === 3) {
            img = dis;
        } else if (props.data.cardTypeID === 4) {
            img = amex;
        } 
    }

    return (
        <div {...props.innerProps}>
            <div className="panel panel-default">
                <div className="panel-heading accordian-card-selected">
                    <div className="panel-title" style={{ minHeight: '36px' }}>
                        {props.data.accountName && <div>
                            <img src={img} className="mr-3" alt="card" />
                            {props.data.accountName} {T.translate('autopay.endingIn')} {props.data.accountNumber ? props.data.accountNumber.substr(props.data.accountNumber.length - 4) : ''}
                        </div>}
                        {!props.data.accountName && <div style={{color: 'gray', paddingTop: '3px'}}>
                            {T.translate('accountPay.selectPaymentMethod')}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};