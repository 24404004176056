import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserStore from '../../store/User';
import * as CommonStore from '../../store/Common';
import * as AccountStore from '../../store/Account';
import * as PaymentStore from '../../store/Payment';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';
import DataGrid from '../shared/DataGrid';
import T from 'i18n-react';

const Scroll = require('react-scroll');

class PaymentHistory extends Component {

    constructor(props) {
        super(props);

        this.state = { paymentHistoryDaysRange: this.props.paymentHistoryMonthsRange * 30 };
        
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('paymentHistory.title');

        if (this.props.initiated) {
            this.props.getPaymentHistory();
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getPaymentHistory();
        }
    }

    setHighlightStyle = (columnId, row) => {
        var styleClass = '';

        if (columnId === 'tenderStatusName') {
            styleClass = 'text-white font-weight-normal px-3 rounded text-capitalize';
            styleClass = ((row.paymentTenderStatusID === 1) ? "bg-success " : "bg-danger ") + styleClass;
        }

        return styleClass;
    }

    render() {
        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.paymentLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('paymentHistory.pageTitle')}</div>
                </div>
                <div className="diverter" />

                <MessageError>{this.props.accountErrorMessage}</MessageError>

                {this.props.paymentHistoryList.length === 0 ?
                    <p>{T.translate('common.noRecordsToDisplay')}</p> :
                    <p><strong>{T.translate('paymentHistory.displayingLastMonths', this.state)}</strong>{' ' + T.translate('paymentHistory.outOfRangeHistorical', this.state)}</p>}

                {this.props.phYears.map(year =>
                    <div key={year}>
                        <h6 className="card-title">{year}</h6>
                        <DataGrid dataKey="accountTransactionID" downloadData={true} csvFileName={"Payments_" + (this.props.account.accountNumber === undefined ? "History" : this.props.account.accountNumber) + "_" + year + ".csv"} onHighlightStyle={this.setHighlightStyle} usePaging={true} pageSize="15">{{
                            rows: this.props.paymentHistoryList.filter((row) => row.year === year),
                            columns: [
                                { columnId: 'transactionDate', displayText: T.translate('paymentHistory.colPaymentDate'), dataType: 'date', format: 'MM/dd/yyyy', bold: true, italic: true },
                                { columnId: 'tenderStatusName', displayText: T.translate('paymentHistory.colStatus'), dataType: 'string', hightlightCell: true },
                                { columnId: 'tenderTypeName', displayText: T.translate('paymentHistory.colPaymentMethod'), dataType: 'string' },
                                { columnId: 'transactionTypeName', displayText: T.translate('paymentHistory.colTransactionTypeName'), dataType: 'string' },
                                { columnId: 'authorizationNumber', displayText: T.translate('paymentHistory.colConfirmationNumber'), dataType: 'string' },
                                { columnId: 'amount', displayText: T.translate('paymentHistory.colAmount'), dataType: 'currency' }
                            ]
                        }}</DataGrid>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.payment, ...state.user, ...state.common, ...state.account }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(PaymentHistory);
