import HighchartsPatternFill from 'highcharts-pattern-fill';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts/modules/drilldown';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonStore from '../../../store/Common';
const plug = require(`../../../images/${process.env.REACT_APP_CLIENT}/plug-icon.png`);

drilldown(Highcharts);
HighchartsPatternFill(Highcharts);

class EnergyTabUsage extends Component {

    constructor(props) {
        super(props);

        this.onIntervalEnergyClick = this.onIntervalEnergyClick.bind(this);

        this.state = {
            chartEnergyTypeID: '1',
            intervalElectricity: false,
            intervalDateElectricity: '',
            calculationLegendText: '',
            highchartsOptionDailyWithEnergySpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        },
                        {
                            id: 'unbilled-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: '#ecb67c',
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.energySpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)',
                    useHTML: true,
                    labelFormatter: function () {

                        if (this.name === T.translate('dashboard.kWhUsed')) {
                            return '<div>' + T.translate('dashboard.billedUsage') + ' <span style="width:12px; height:12px; background-color:#ecb67c; display:inline-block; border-radius:500px; margin-left:20px; margin-right:4px;"></span>' + T.translate('dashboard.unbilledUsage') + '</div>';
                        } else {
                            return T.translate('dashboard.energySpend');
                        }

                    }
                },
                plotOptions: {
                    column: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: this.onIntervalEnergyClick
                            }
                        }
                    }
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    },
                    {
                        name: T.translate('dashboard.energySpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ],
                drilldown: {
                    series: []
                }
            },
            highchartsOptionDailyWithoutEnergySpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        },
                        {
                            id: 'unbilled-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: '#ecb67c',
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: {
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)',
                    useHTML: true,
                    labelFormatter: function () {

                        if (this.name === T.translate('dashboard.kWhUsed')) {
                            return '<div>' + T.translate('dashboard.billedUsage') + ' <span style="width:12px; height:12px; background-color:#ecb67c; display:inline-block; border-radius:500px; margin-left:20px; margin-right:4px;"></span>' + T.translate('dashboard.unbilledUsage') + '</div>';
                        } else {
                            return T.translate('dashboard.energySpend');
                        }

                    }
                },
                plotOptions: {
                    column: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: (e) => {
                                    if (e.point && e.point.category) {
                                        this.toggleIntervalElectricity(e.point.category);
                                    }
                                }
                            }
                        }
                    }
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    }
                ],
                drilldown: {
                    series: []
                }
            },
            highchartsOptionIntervalWithEnergySpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.energySpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    },
                    {
                        name: T.translate('dashboard.energySpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ]
            },
            highchartsOptionIntervalWithoutEnergySpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: { // Primary yAxis
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    }
                ]
            },
            highchartsOptionMonthlyWithEnergySpend: {
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.energySpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    },
                    {
                        name: T.translate('dashboard.energySpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ]
            },
            highchartsOptionMonthlyWithoutEnergySpend: {
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: {
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    }
                ]
            }
        };
    }

    componentWillMount() {
        if (this.props.active) {
            this.setChartData(this.props, true);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setChartData(nextProps, false);
    }

    setChartData = (props, requestDataSource) => {
        if (this.props.active) {
            if (requestDataSource && props.asp.accountServicePointID) {
                props.onDataSourceRequest({ chartTypeID: this.state.chartEnergyTypeID, accountServicePointID: props.asp.accountServicePointID });
                return;
            }

            if (props.chartData) {

                var chartData = props.chartData;

                if (chartData.activityMonthlyElectricityCategories && chartData.activityMonthlyElectricitySeries && chartData.activityMonthlyElectricitySeries2) {

                    let highchartsOptionMonthlyWithEnergySpend = { ...this.state.highchartsOptionMonthlyWithEnergySpend };

                    highchartsOptionMonthlyWithEnergySpend.xAxis[0].categories = chartData.activityMonthlyElectricityCategories;
                    highchartsOptionMonthlyWithEnergySpend.series[0].data = chartData.activityMonthlyElectricitySeries;
                    highchartsOptionMonthlyWithEnergySpend.series[1].data = chartData.activityMonthlyElectricitySeries2;
                    highchartsOptionMonthlyWithEnergySpend.scrollbar = {};
                    highchartsOptionMonthlyWithEnergySpend.scrollbar.enabled = chartData.activityMonthlyElectricityCategories.length > 15;
                    highchartsOptionMonthlyWithEnergySpend.xAxis[0].max = chartData.activityMonthlyElectricityCategories.length > 15 ? 13 : null;

                    this.setState({ highchartsOptionMonthlyWithEnergySpend });

                    if (this.refs.highChartsMonthlyWithEnergySpend) {
                        //reset extremes after updating values - then redraw chart
                        this.refs.highChartsMonthlyWithEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                        this.refs.highChartsMonthlyWithEnergySpend.chart.redraw();
                    }

                    let highchartsOptionMonthlyWithoutEnergySpend = { ...this.state.highchartsOptionMonthlyWithoutEnergySpend };

                    highchartsOptionMonthlyWithoutEnergySpend.xAxis[0].categories = chartData.activityMonthlyElectricityCategories;
                    highchartsOptionMonthlyWithoutEnergySpend.series[0].data = chartData.activityMonthlyElectricitySeries;
                    highchartsOptionMonthlyWithoutEnergySpend.scrollbar = {};
                    highchartsOptionMonthlyWithoutEnergySpend.scrollbar.enabled = chartData.activityMonthlyElectricityCategories.length > 15;
                    highchartsOptionMonthlyWithoutEnergySpend.xAxis[0].max = chartData.activityMonthlyElectricityCategories.length > 15 ? 13 : null;

                    this.setState({ highchartsOptionMonthlyWithoutEnergySpend });

                    if (this.refs.highChartsMonthlyWithoutEnergySpend) {
                        //reset extremes after updating values - then redraw chart
                        this.refs.highChartsMonthlyWithoutEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                        this.refs.highChartsMonthlyWithoutEnergySpend.chart.redraw();
                    }

                }

                if (chartData.activityDailyElectricityCategories && chartData.activityDailyElectricitySeries && chartData.activityDailyElectricitySeries2) {

                    let highchartsOptionDailyWithoutEnergySpend = { ...this.state.highchartsOptionDailyWithoutEnergySpend };

                    highchartsOptionDailyWithoutEnergySpend.xAxis[0].categories = chartData.activityDailyElectricityCategories;
                    highchartsOptionDailyWithoutEnergySpend.series[0].data = chartData.activityDailyElectricitySeries;
                    highchartsOptionDailyWithoutEnergySpend.scrollbar = {};
                    highchartsOptionDailyWithoutEnergySpend.scrollbar.enabled = chartData.activityDailyElectricityCategories.length > 15;
                    highchartsOptionDailyWithoutEnergySpend.xAxis[0].max = chartData.activityDailyElectricityCategories.length > 15 ? 13 : null;

                    this.setState({ highchartsOptionDailyWithoutEnergySpend });

                    if (this.refs.highChartsDailyWithoutEnergySpend) {
                        this.refs.highChartsDailyWithoutEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                        this.refs.highChartsDailyWithoutEnergySpend.chart.redraw();
                    }

                    let highchartsOptionDailyWithEnergySpend = { ...this.state.highchartsOptionDailyWithEnergySpend };

                    highchartsOptionDailyWithEnergySpend.xAxis[0].categories = chartData.activityDailyElectricityCategories;
                    highchartsOptionDailyWithEnergySpend.series[0].data = chartData.activityDailyElectricitySeries;
                    highchartsOptionDailyWithEnergySpend.series[1].data = chartData.activityDailyElectricitySeries2;
                    highchartsOptionDailyWithEnergySpend.scrollbar = {};
                    highchartsOptionDailyWithEnergySpend.scrollbar.enabled = chartData.activityDailyElectricityCategories.length > 15;
                    highchartsOptionDailyWithEnergySpend.xAxis[0].max = chartData.activityDailyElectricityCategories.length > 15 ? 13 : null;

                    this.setState({ highchartsOptionDailyWithEnergySpend });

                    if (this.refs.highChartsDailyWithEnergySpend) {
                        this.refs.highChartsDailyWithEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                        this.refs.highChartsDailyWithEnergySpend.chart.redraw();
                    }

                }

                if (chartData.activityIntervalElectricityCategories && chartData.activityIntervalElectricitySeries && chartData.activityIntervalElectricitySeries2) {

                    let highchartsOptionIntervalWithoutEnergySpend = { ...this.state.highchartsOptionIntervalWithoutEnergySpend };

                    highchartsOptionIntervalWithoutEnergySpend.xAxis[0].categories = chartData.activityIntervalElectricityCategories;
                    highchartsOptionIntervalWithoutEnergySpend.series[0].data = chartData.activityIntervalElectricitySeries;
                    highchartsOptionIntervalWithoutEnergySpend.scrollbar = {};
                    highchartsOptionIntervalWithoutEnergySpend.scrollbar.enabled = chartData.activityIntervalElectricityCategories.length > 15;
                    highchartsOptionIntervalWithoutEnergySpend.xAxis[0].max = chartData.activityIntervalElectricityCategories.length > 15 ? 13 : null;

                    this.setState({ highchartsOptionIntervalWithoutEnergySpend });

                    if (this.refs.highChartsIntervalWithoutEnergySpend) {
                        this.refs.highChartsIntervalWithoutEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                        this.refs.highChartsIntervalWithoutEnergySpend.chart.redraw();
                    }

                    let highchartsOptionIntervalWithEnergySpend = { ...this.state.highchartsOptionIntervalWithEnergySpend };

                    highchartsOptionIntervalWithEnergySpend.xAxis[0].categories = chartData.activityIntervalElectricityCategories;
                    highchartsOptionIntervalWithEnergySpend.series[0].data = chartData.activityIntervalElectricitySeries;
                    highchartsOptionIntervalWithEnergySpend.series[1].data = chartData.activityIntervalElectricitySeries2;
                    highchartsOptionIntervalWithEnergySpend.scrollbar = {};
                    highchartsOptionIntervalWithEnergySpend.scrollbar.enabled = chartData.activityIntervalElectricityCategories.length > 15;
                    highchartsOptionIntervalWithEnergySpend.xAxis[0].max = chartData.activityIntervalElectricityCategories.length > 15 ? 13 : null;

                    this.setState({ highchartsOptionIntervalWithEnergySpend });

                    if (this.refs.highChartsIntervalWithEnergySpend) {
                        this.refs.highChartsIntervalWithEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                        this.refs.highChartsIntervalWithEnergySpend.chart.redraw();
                    }

                }

                this.setState({ chartData });

                this.getCalculationLegend(chartData);
            }
        }
    }

    onChartEnergyChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);

        this.props.onDataSourceRequest({ chartTypeID: this.state.chartEnergyTypeID, accountServicePointID: this.props.asp.accountServicePointID });

        if (this.state.chartEnergyTypeID === '1') {
            this.backToDailyElectricity();
        }

        this.getCalculationLegend(this.state.chartData);
    }

    onIntervalEnergyClick = (e) => {
        if (e.point && e.point.category) {
            this.toggleIntervalElectricity(e.point.category, this.props);
        }
    }

    backToDailyElectricity = (e) => {
        this.setState({ intervalElectricity: false, intervalDateElectricity: '' });
    }

    toggleIntervalElectricity = (category, props) => {
        this.setState({ intervalElectricity: true, intervalDateElectricity: category });
        props.onDataSourceRequest({ chartTypeID: '3', accountServicePointID: props.asp.accountServicePointID, date: category });
    }

    getCalculationLegend = (chartData) => {

        let calculationLegendText = '';
        let periodID = 0;
        let resourceKey = '';
        let activityStatistics = {};

        periodID = this.state.chartEnergyTypeID;

        if (periodID === '1') {
            activityStatistics = chartData.activityElectricityMonthlyStatistics;
        } else if (periodID === '2') {
            activityStatistics = chartData.activityElectricityDailyStatistics;
        }

        if (activityStatistics) {
            activityStatistics.unit = T.translate('dashboard.unit');
            if (activityStatistics.isUsagePresent) {

                if (periodID === '1') {

                    activityStatistics.perMonth.formatedDate = CommonStore.formatDate(activityStatistics.perMonth.date, 'MMMM YYYY');
                    resourceKey = '';

                    if (activityStatistics.perMonth.isPreviousMonthPesent || activityStatistics.perMonth.isPreviousYearMonthPesent) {

                        activityStatistics.perMonth.formatedPreviousMonthDate = CommonStore.formatDate(activityStatistics.perMonth.previousMonthDate, 'MMMM YYYY');

                        var resourceKeyArr = [];

                        if (activityStatistics.perMonth.previousMonthAmountDiff > 0) {
                            resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV2MoreThan');
                        } else if (activityStatistics.perMonth.previousMonthAmountDiff < 0) {
                            resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV2LessThan');
                        } else {
                            resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV2Equal');
                        }

                        if (activityStatistics.perMonth.isPreviousYearMonthPesent) {
                            if (activityStatistics.perMonth.previousYearMonthAmountDiff > 0) {
                                resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV3MoreThan');
                            } else if (activityStatistics.perMonth.previousYearMonthAmountDiff < 0) {
                                resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV3LessThan');
                            } else {
                                resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV3Equal');
                            }
                        }

                        let translations = resourceKeyArr.map(x => T.translate(x, activityStatistics));

                        calculationLegendText = translations.join(' ');

                    } else {
                        resourceKey = 'dashboard.yourAvgDailyCostMonthV1';
                        calculationLegendText = T.translate(resourceKey, activityStatistics);
                    }
                } else if (periodID === '2') {

                    activityStatistics.perDay.formatedFromDate = CommonStore.formatDate(activityStatistics.perDay.startDate, 'MM/DD/YYYY');
                    activityStatistics.perDay.formatedToDate = CommonStore.formatDate(activityStatistics.perDay.endDate, 'MM/DD/YYYY');

                    if (activityStatistics.perDay.isPreviousWeekPesent) {

                        if (activityStatistics.perDay.previousWeekAmountDiff > 0) {
                            resourceKey = 'dashboard.yourAvgDailyCostWeekV2MoreThan';
                        } else if (activityStatistics.perDay.previousWeekAmountDiff < 0) {
                            resourceKey = 'dashboard.yourAvgDailyCostWeekV2LessThan';
                        } else {
                            resourceKey = 'dashboard.yourAvgDailyCostWeekV2Equal';
                        }

                    } else {
                        resourceKey = 'dashboard.yourAvgDailyCostWeekV1';
                    }

                    calculationLegendText = T.translate(resourceKey, activityStatistics);
                }
            }

        }

        this.setState({ calculationLegendText });
    }

    render() {
        return (
            <div id={this.props.id} className={"tab-pane " + (this.props.active ? "active" : "")}>
                <div className="row">
                    <div className="col-md-9">

                        {this.state.chartEnergyTypeID === '1' && this.props.showEnergySpend &&
                            <HighchartsReact
                                ref="highChartsMonthlyWithEnergySpend"
                                highcharts={Highcharts}
                                options={this.state.highchartsOptionMonthlyWithEnergySpend}
                            />
                        }

                        {this.state.chartEnergyTypeID === '1' && !this.props.showEnergySpend &&
                            <HighchartsReact
                                ref="highChartsMonthlyWithoutEnergySpend"
                                highcharts={Highcharts}
                                options={this.state.highchartsOptionMonthlyWithoutEnergySpend}
                            />
                        }

                        {this.state.chartEnergyTypeID === '2' && !this.state.intervalElectricity && this.props.showEnergySpend &&
                            <HighchartsReact
                                ref="highChartsDailyWithEnergySpend"
                                highcharts={Highcharts}
                                options={this.state.highchartsOptionDailyWithEnergySpend}
                            />
                        }

                        {this.state.chartEnergyTypeID === '2' && !this.state.intervalElectricity && !this.props.showEnergySpend &&
                            <HighchartsReact
                                ref="highChartsDailyWithoutEnergySpend"
                                highcharts={Highcharts}
                                options={this.state.highchartsOptionDailyWithoutEnergySpend}
                            />
                        }

                        {this.state.chartEnergyTypeID === '2' && this.state.intervalElectricity && (this.props.account.accountBillingTypeID === 2 || !this.props.showEnergySpend) &&
                            <HighchartsReact
                                ref="highChartsIntervalWithoutEnergySpend"
                                highcharts={Highcharts}
                                options={this.state.highchartsOptionIntervalWithoutEnergySpend}
                            />
                        }

                        {this.state.chartEnergyTypeID === '2' && this.state.intervalElectricity && this.props.account.accountBillingTypeID === 1 && this.props.showEnergySpend &&
                            <HighchartsReact
                                ref="highChartsIntervalWithEnergySpend"
                                highcharts={Highcharts}
                                options={this.state.highchartsOptionIntervalWithEnergySpend}
                            />
                        }

                    </div>
                    <div className="col-md-3 align-self-center">
                        <img src={plug} alt="plug icon" />
                        <h6 className="mt-3 mb-3 card-title">{T.translate('dashboard.myUsageInsights')}</h6>

                        {!this.state.intervalElectricity &&
                            <p className="mb-4">{this.state.calculationLegendText}</p>
                        }

                        {this.state.intervalElectricity &&
                            <div>
                                <p className="mb-4">{T.translate('dashboard.showingIntervalConsumptionFor')} {this.state.intervalDateElectricity}</p>
                                <p className="mb-4"><button className="btn btn-primary" type="button" onClick={this.backToDailyElectricity}>{T.translate('dashboard.backToDailyView')}</button></p>
                            </div>
                        }

                        {this.props.showDailyAndDailyCharts && !this.state.intervalElectricity && <select className="form-control" id="chartEnergyTypeID" name="chartEnergyTypeID" value={this.state.chartEnergyTypeID} onChange={this.onChartEnergyChange}>
                            <option value="1">{T.translate('dashboard.monthly')}</option>
                            <option value="2">{T.translate('dashboard.daily')}</option>
                        </select>}
                    </div>
                </div>
                <div className="text-center">{T.translate('dashboard.readsReceivedWithEstimatedStatus')}</div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.common }),
    dispatch => bindActionCreators({ ...CommonStore.actionCreators }, dispatch)
)(EnergyTabUsage);