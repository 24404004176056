import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CommonStore from '../../store/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'reactstrap';
import T from 'i18n-react';

class PaymentSidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse1: false,
            collapse2: false,
            collapse3: false
        };

    }

    toggle = (e) => {

        let collapse = 0;
        if (e.target.attributes['data-tab']) {
            collapse = e.target.attributes['data-tab'].value;
        }

        if (collapse === '1') {
            this.setState({ collapse1: !this.state.collapse1 });
        } else if (collapse === '2') {
            this.setState({ collapse2: !this.state.collapse2 });
        } else if (collapse === '3') {
            this.setState({ collapse3: !this.state.collapse3 });
        }

    }

    render() {
        return (
            <div className="right-sidebar">
                {this.props.systemProperties.myAcctDisplayPaymentProvider.index > 0 && <div className="gray-box text-center">
                    <p className="kanit lead">
                        {T.translate('paymentSidebar.creditCardPaymentsSafe')}
                    </p>
                    <img src={require('../../images/' + this.props.systemProperties.myAcctDisplayPaymentProvider.imageName)} alt="online" />
                </div>}
                {(this.props.systemProperties.myAcctOtherPaymentTenderTypes.payByPhone || this.props.systemProperties.myAcctOtherPaymentTenderTypes.payWithCash || this.props.systemProperties.myAcctOtherPaymentTenderTypes.payByMail) &&
                    (<div className="gray-box">
                        <h6 className="card-title mb-3">{T.translate('paymentSidebar.otherWaysPay')}</h6>
                        <div className="panel-group" id="accordion">
                            <div className="panel panel-default">

                                {this.props.systemProperties.myAcctOtherPaymentTenderTypes.payByPhone &&
                                    <React.Fragment>
                                        <div className="panel-heading">
                                            <div className="panel-title">
                                                <a className="accordion-toggle accordian-btn" href="javascript:;" data-tab="1" onClick={this.toggle}>
                                                    {T.translate('paymentSidebar.payByPhone')}
                                                    <FontAwesomeIcon icon={this.state.collapse1 ? faAngleUp : faAngleDown} data-tab="1" onClick={this.toggle} />
                                                </a>
                                            </div>
                                        </div>

                                        <Collapse isOpen={this.state.collapse1}>
                                            <div className="panel-body">
                                                <p>{CommonStore.textWrap(this.props.systemProperties['myAcctPayByPhoneText' + this.props.lang.toUpperCase()], ';')}</p>
                                                <p className="lead text-dark kanit">{this.props.systemProperties.customerServicePhone}</p>
                                            </div>
                                        </Collapse>
                                    </React.Fragment>}

                                {this.props.systemProperties.myAcctOtherPaymentTenderTypes.payWithCash &&
                                    <React.Fragment>
                                        <div className="panel-heading">
                                            <div className="panel-title">
                                                <a className="accordion-toggle accordian-btn" href="javascript:;" data-tab="2" onClick={this.toggle}>
                                                    {T.translate('paymentSidebar.payWithCash')}
                                                    <FontAwesomeIcon icon={this.state.collapse2 ? faAngleUp : faAngleDown} data-tab="2" onClick={this.toggle} />
                                                </a>
                                            </div>
                                        </div>

                                        <Collapse isOpen={this.state.collapse2}>
                                            <div className="panel-body">
                                                <p>{CommonStore.textWrap(this.props.systemProperties['myAcctPayWithCashText' + this.props.lang.toUpperCase()], ';')}</p>
                                            </div>
                                        </Collapse>
                                    </React.Fragment>}

                                {this.props.systemProperties.myAcctOtherPaymentTenderTypes.payByMail &&
                                    <React.Fragment>
                                        <div className="panel-heading">
                                            <div className="panel-title">
                                                <a className="accordion-toggle accordian-btn" href="javascript:;" data-tab="3" onClick={this.toggle}>
                                                    {T.translate('paymentSidebar.payByMail')}
                                                    <FontAwesomeIcon icon={this.state.collapse3 ? faAngleUp : faAngleDown} data-tab="3" onClick={this.toggle} />
                                                </a>
                                            </div>
                                        </div>

                                        <Collapse isOpen={this.state.collapse3}>
                                            <div className="panel-body">
                                                <p>{CommonStore.textWrap(this.props.systemProperties['myAcctPayByMailText' + this.props.lang.toUpperCase()], ';')}</p>
                                                {this.props.systemProperties.messagingUtilityName}<br />
                                                <p>{CommonStore.textWrap(this.props.systemProperties.marketerBillingAddress)}</p>
                                            </div>
                                        </Collapse>
                                    </React.Fragment>}
                            </div>
                        </div>
                    </div>)
                }
            </div >
        );
    }
}

export default connect(
    state => state.common,
    dispatch => bindActionCreators({ ...CommonStore.actionCreators }, dispatch)
)(PaymentSidebar);
