import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as ServiceStore from '../../store/Service';
import T from 'i18n-react';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoadingButton from '../shared/LoadingButton';
import ConfirmModal from '../shared/ConfirmModal';
import BasicModal from '../shared/BasicModal';

const Scroll = require('react-scroll');
const transferIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/transfer-icon.png`);

class AddLocation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            address: '',
            apartmentNumber: '',
            city: '',
            state: '',
            zip: '',
            esiID: '',
            open: false,
            moveInDate: '',
            acctAddressAddress1: '',
            acctAddressAddress2: '',
            acctAddressCity: '',
            acctAddressState: '',
            acctAddressPostalCode: '',
            accountServicePointID: 0,
            distributionServiceProviderID: -1,
            validSpStatus: [14, 16, 19, 21],//teminated status
            validSevicePoints: [],
            fields: [
                { name: 'acctAddressAddress1', isRequired: true, isRequiredError: false },
                { name: 'acctAddressCity', isRequired: true, isRequiredError: false },
                { name: 'acctAddressState', isRequired: true, isRequiredError: false },
                { name: 'acctAddressPostalCode', isRequired: true, isRequiredError: false },
                { name: 'moveInDate', isRequired: true, isRequiredError: false }
            ],
            disableSubmitMvi: false
        };

        this.addressInput = React.createRef();
    }


    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('addLocation.title');

        if (this.props.account.accountNumber) {
            this.props.clearServiceState();
            let distributionServiceProviderID = this.getServiceProviderID(this.props);
            this.checkStatesListNCalendar(distributionServiceProviderID);
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if (nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.clearServiceState();
            let distributionServiceProviderID = this.getServiceProviderID(nextProps);
            this.checkStatesListNCalendar(distributionServiceProviderID);
        }

        if (nextProps.distributionPoint.address1 && this.props.distributionPoint.address1 !== nextProps.distributionPoint.address1) {

            this.setState({
                acctAddressAddress1: nextProps.distributionPoint.address1,
                acctAddressAddress2: nextProps.distributionPoint.address2,
                acctAddressCity: nextProps.distributionPoint.city,
                acctAddressState: nextProps.distributionPoint.stateCode,
                acctAddressPostalCode: nextProps.distributionPoint.zipCode
            });
        }
    }

    getServiceProviderID = (props) => {
        let distributionServiceProviderID = -1;
        let validSevicePoints = props.account.servicePoints.filter(x => !this.state.validSpStatus.includes(x.servicePointStatusID));
        var prods = validSevicePoints.map(x => x.productID).filter(this.getValidProducts);

        if (validSevicePoints.length > 0 && prods.length === 1) {
            const sp = validSevicePoints[0];
            props.setServicePoint(sp);
            distributionServiceProviderID = sp.distributionServiceProviderID;
            this.setState({ validSevicePoints, accountServicePointID: sp.accountServicePointID, distributionServiceProviderID });
        } else {

            this.props.setServicePoint({});
            this.setState({ validSevicePoints, accountServicePointID: 0, distributionServiceProviderID });

            if (validSevicePoints.length === 0) {
                this.props.showServiceError(T.translate('addLocation.errorNoActivePremiseOnAccount'));
            } else if (prods.length > 1) {
                this.props.showServiceError(T.translate('addLocation.mviRequestUnable'), 300);
            }
        }

        return distributionServiceProviderID;
    }

    onChange = (e) => {

        let fields = this.state.fields.filter(x => x.name === e.target.name);
        if (fields && fields.length === 1) {
            let field = fields[0];

            if (field.isRequired && field.isRequiredError && e.target.value) {
                field.isRequiredError = false;
            }
        }

        this.setState({ [e.target.name]: e.target.value });
    }

    onSearchClick = async () => {
        let getDistributionPoints = false;

        if (this.state.esiID) {
            getDistributionPoints = true;
        } else {
            if (this.state.address && this.state.city && this.state.state && this.state.zip) {
                getDistributionPoints = true;
            }
        }

        if (getDistributionPoints) {
            await this.props.getDistributionPoints(this.state);
            this.props.getWebProductInformation(this.state);
        } else {
            this.props.showServiceError(T.translate('addLocation.errorFieldRequiredToMakeSearch'));
        }
    }

    handleChangeServiceMoveInDate = (e) => {
        this.setState({ moveInDate: e });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    onChangeLocationClick = () => {

        this.setState({
            address: '',
            apartmentNumber: '',
            city: '',
            state: '',
            zip: '',
            esiID: '',
            acctAddressAddress1: '',
            acctAddressAddress2: '',
            acctAddressCity: '',
            acctAddressState: '',
            acctAddressPostalCode: ''
        });

        this.props.setDistributionPoint({});
        this.addressInput.current.focus();
    }

    setDistributionPoint = (dp) => {
        this.props.setDistributionPoint(dp);
        this.props.setDistributionPointModalVisible(false);
    }

    onToggle = () => {
        this.props.setDistributionPointModalVisible(false);
    }

    onToggleConfirm = () => {
        this.props.setNewServiceLocationConfirmModalVisible(false);
    }

    onToggleCancel = () => {
        this.props.setNewServiceLocationCancelModalVisible(false);
    }

    onToggleSuccess = () => {
        this.props.setNewServiceLocationSuccessModalVisible(false);
    }

    onCancelClick = () => {
        this.props.setNewServiceLocationCancelModalVisible(true);
    }

    goToDashboard = () => {
        this.props.setNewServiceLocationCancelModalVisible(false);
        this.props.setNewServiceLocationSuccessModalVisible(false);
        this.props.history.push('/myaccount/dashboard');
    }

    checkStatesListNCalendar = (distributionServiceProviderID) => {
        this.props.requestNonWorkingDaysNStates(distributionServiceProviderID, 1);//Enrollments:1
    }

    onSubmitMvi = () => {

        var state = this.state;
        var valid = true;

        this.state.fields.map((field) => {

            field.value = this.state[field.name];
            field.isRequiredError = false;

            if (field.isRequired && !field.value) {
                field.isRequiredError = true;
                valid = false;
            }

        });
        this.setState(state);
        var boolDisabled = this.props.selectedWebProduct === '' || this.props.selectedWebProduct === 'undefined' || this.props.selectedWebProduct === null;

        this.setState({
            disableSubmitMvi: boolDisabled 
        }); 

        if (valid) {
            this.props.showServiceError('');

            //now see if distribution point is set
            if (this.props.distributionPoint.serviceProviderIdentifier) {
                this.props.setNewServiceLocationConfirmModalVisible(true);
            } else {
                this.props.showServiceError('Please, search for the New Service Location.');
            }

        } else {
            this.props.showServiceError(T.translate("common.thereAreMissingFields"));
        }

    }

    getMaxDate = () => {
        var result = new Date();
        var days = this.props.systemProperties.myAcctMvoFutureDaysAllowed;

        result.setDate(result.getDate() + days);
        return result;
    }

    onSubmitMviConfirm = () => {
        this.props.createMviServiceOrder(this.state);
    }

    getNonWorkingDays = () => {

        var wgGroupID = 1;//mvi-switch

        var tduCalendar = this.props.nonWorkingDaysByTdu.find(x => x.distributionServiceProviderID === this.state.distributionServiceProviderID && x.workingDaysGroupID === wgGroupID);

        if (tduCalendar) {
            return tduCalendar.nonWorkingDays;
        } else {
            return [];
        }

    }

    getErrorMessage = () => {
        var errorMessage = '';

        var validSevicePoints = this.props.account.servicePoints.filter(x => !this.state.validSpStatus.includes(x.servicePointStatusID));
        var prods = validSevicePoints.map(x => x.productID).filter(this.getValidProducts);

        if (this.props.serviceErrorCode === 300 || prods.length > 1) {
            errorMessage = T.translate('addLocation.mviRequestUnable', this.props.systemProperties);
        } else {
            errorMessage = this.props.serviceErrorMessage;
        }

        if (errorMessage !== '') {
            Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        }

        return errorMessage;
    }

    getValidProducts = (productID, index, self) => {
        return productID > 0 && self.indexOf(productID) === index;
    }

    render() {
        return (
            <div className="p-main">
                <div className="left-sidebar">
                    <div className="p-main">

                        

                        <LoadingOverlay loading={this.props.serviceLoading || this.props.serviceLoading} />

                        <div className="page-header">
                            <div className="page-title">{T.translate('addLocation.pageTitle')}</div>
                            <p className="lead text-dark font-weight-normal kanit">{T.translate('addLocation.accountNumber')}: {this.props.account.accountNumber}</p>
                        </div>

                        <MessageError>{this.getErrorMessage()}</MessageError>

                        {this.props.serviceErrorCode === 0 && <React.Fragment>

                            <div className="card mb-5">
                                <div className="card-body">

                                    <h6 className="card-title mt-3 mb-4">{T.translate('addLocation.newServiceLocation')}</h6>
                                    <div className="row">
                                        <div className="col-md-4 form-group">
                                            <label htmlFor="address">{T.translate('addLocation.address')}</label>
                                            <input type="text" ref={this.addressInput} className="form-control" id="address" name="address" value={this.state.address} onChange={this.onChange} />
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <label htmlFor="apartmentNumber">{T.translate('addLocation.aptNumber')}</label>
                                            <input type="text" className="form-control" id="apartmentNumber" name="apartmentNumber" value={this.state.apartmentNumber} onChange={this.onChange} />
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <label htmlFor="city">{T.translate('addLocation.city')}</label>
                                            <input type="text" className="form-control" id="city" name="city" value={this.state.city} onChange={this.onChange} />
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <label htmlFor="state">{T.translate('addLocation.state')}</label>
                                            <select className="form-control" id="state" name="state" onChange={this.onChange} value={this.state.state}>
                                                <option key={0} value="">{T.translate('addLocation.select')}</option>
                                                {this.props.statesList.map((state, i) =>
                                                    <option key={i} value={state.value}>{state.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <label htmlFor="zip">{T.translate('addLocation.zipCode')}</label>
                                            <input type="text" className="form-control" id="zip" name="zip" value={this.state.zip} onChange={this.onChange} />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label htmlFor="esi-id">{T.translate('addLocation.serviceIdentifier')}</label>
                                            <input type="text" className="form-control" id="esiID" name="esiID" value={this.state.esiID} onChange={this.onChange} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <LoadingButton
                                                type="button"
                                                className="btn btn-outline-dark mt-4 pull-right"
                                                disabled={this.props.serviceLoading}
                                                loading={this.props.serviceLoading ? 'true' : 'false'}
                                                onClick={this.onSearchClick}>
                                                {T.translate('addLocation.search')}
                                            </LoadingButton>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <span style={{ paddingBottom: "10px" }}>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('addLocation.address')}: </h6>
                                                <label id="lblAddress">{this.props.distributionPoint.address1 && (this.props.distributionPoint.address1 + ' ' + this.props.distributionPoint.address2 + ' ' + this.props.distributionPoint.city + ', ' + this.props.distributionPoint.stateCode + ' ' + this.props.distributionPoint.zipCode)}</label>
                                            </span>
                                        </div>
                                        <div className="col-md-6">
                                            {this.props.distributionPoint.address1 && <button type="button" className="btn btn-outline-dark" onClick={this.onChangeLocationClick}>{T.translate('addLocation.changeLocation')}</button>}
                                        </div>
                                        <div className="col-md-12">
                                            <span style={{ paddingBottom: "10px" }}>
                                                <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('addLocation.serviceIdentifier')}: </h6>
                                                <label id="lblAddress">{this.props.distributionPoint.serviceProviderIdentifier}</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">

                                            <h6 className="card-title">{T.translate('addLocation.moveInDate')}</h6>
                                            <div className="date-time">
                                                <DatePicker className={"form-control " + (this.state.fields[4].isRequiredError ? 'is-invalid' : '')}
                                                    id="serviceMoveInDate"
                                                    name="serviceMoveInDate"
                                                    selected={this.state.moveInDate}
                                                    onChange={this.handleChangeServiceMoveInDate}
                                                    onChangeRaw={this.handleDateChangeRaw}
                                                    minDate={new Date()}
                                                    maxDate={this.getMaxDate()}
                                                    excludeDates={this.getNonWorkingDays()}
                                                    dateFormat="MM/dd/yy"
                                                    disabledKeyboardNavigation
                                                />
                                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group" style={{ textAlignLast: 'right' }}>
                                                <button type="button" className="btn btn-outline-dark mr-3" onClick={this.onCancelClick}>{T.translate('common.cancel')}</button>
                                                <button type="button" className="btn btn-primary" onClick={this.onSubmitMvi}>{T.translate('addLocation.submitMvi')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal className="distribution-points-modal" isOpen={this.props.showDistributionPointsModal} size="lg" style={{ maxWidth: '1000px', width: '80%' }}>
                                <ModalHeader toggle={this.onToggle} className="bg-primary"><span className="text-white">{T.translate('addLocation.selectNewServiceLocation')}</span></ModalHeader>
                                <ModalBody>

                                    <div className="js-responsive-table mb-5">
                                        <table className="">
                                            <tbody>
                                                <tr>
                                                    <th />
                                                    <th>{T.translate('addLocation.serviceAddress')}</th>
                                                    <th>{T.translate('addLocation.aptNumber')}</th>
                                                    <th>{T.translate('addLocation.city')}</th>
                                                    <th>{T.translate('addLocation.state')}</th>
                                                    <th>{T.translate('addLocation.zipCode')}</th>
                                                    <th>{T.translate('addLocation.locationIdentifier')}</th>
                                                </tr>
                                                {this.props.distributionPoints && this.props.distributionPoints.map(address =>
                                                    (<tr key={address.serviceProviderIdentifier}>
                                                        <td>
                                                            <input type="radio" name="optradio1" onClick={() => { this.setDistributionPoint(address); }} />
                                                        </td>
                                                        <td>{address.address1}</td>
                                                        <td>{address.address2}</td>
                                                        <td>{address.city}</td>
                                                        <td>{address.stateCode}</td>
                                                        <td>{address.zipCode}</td>
                                                        <td>{address.serviceProviderIdentifier}</td>
                                                    </tr>)
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="btn pull-right" onClick={this.onToggle}>{T.translate('common.close')}</button>
                                </ModalFooter>
                            </Modal>

                            <Modal className="transfer-service-modal" isOpen={this.props.showTransferServiceConfirmModal} size="lg" style={{ maxWidth: '900px', width: '80%' }}>
                                <ModalHeader toggle={this.onToggleConfirm} className="bg-primary"><span className="text-white">{T.translate('addLocation.addLocationConfirmation')}</span></ModalHeader>
                                <ModalBody>

                                    <MessageError>{this.props.accountErrorMessage}</MessageError>

                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span>
                                                    <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('addLocation.moveInServiceLocationAddress')}: </h6>
                                                    <label id="lblAddress">{this.props.distributionPoint.address1 && (this.props.distributionPoint.address1 + ' ' + this.props.distributionPoint.address2 + ' ' + this.props.distributionPoint.city + ', ' + this.props.distributionPoint.stateCode + ' ' + this.props.distributionPoint.zipCode)}</label>
                                                </span>
                                            </div>
                                            <div className="col-md-12">
                                                <span>
                                                    <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('addLocation.moveInServiceLocationIdentifier')}: </h6>
                                                    <label id="lblAddress">{this.props.distributionPoint.serviceProviderIdentifier}</label>
                                                </span>
                                            </div>
                                            <div className="col-md-12">
                                                <span>
                                                    <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('addLocation.moveInDate')}: </h6>
                                                    <label id="lblAddress">{CommonStore.formatDate(this.state.moveInDate)}</label>
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span>
                                                    <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('addLocation.billingAddress')}: </h6>
                                                    <label id="lblAddress">{this.props.account.mailingAddress && this.props.account.mailingAddress.address1 && (this.props.account.mailingAddress.address1 + ' ' + this.props.account.mailingAddress.address2 + ' ' + this.props.account.mailingAddress.city + ', ' + this.props.account.mailingAddress.state + ' ' + this.props.account.mailingAddress.postalCode)}</label>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <span>
                                                    <h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('addLocation.productPlan')}: </h6>
                                                    <label id="lblAddress">{this.props.selectedWebProduct.productDisplayName}</label>
                                                </span>
                                            </div>
                                        </div>

                                        {this.props.selectedWebProduct &&
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="table-responsive table-free-night">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>{T.translate('addLocation.effectiveRate')}</th>
                                                                    <th>{T.translate('addLocation.term')}</th>
                                                                    <th>{T.translate('addLocation.terminationFee')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{CommonStore.formatDecimal(this.props.selectedWebProduct.kwhAvgPrice, 1)}  ¢ <span>{T.translate("addLocation.perkWh")}</span></td>
                                                                    <td>
                                                                        <strong>
                                                                            {this.props.selectedWebProduct.contractLengthName ? (this.props.selectedWebProduct.contractLengthName + ' ' +
                                                                                                                                 this.props.selectedWebProduct.contractPeriodName) 
                                                                                                                                :  T.translate("common.none")}
                                                                        </strong>
                                                                    </td>
                                                                    <td><small>$</small><strong>{this.props.selectedWebProduct.earlyTerminationFee}</strong></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {this.props.selectedWebProduct && this.props.selectedWebProduct.productDocuments &&
                                            <React.Fragment>
                                                <p><strong>{T.translate("addLocation.consumerInfo")}</strong></p>
                                                <ul className="free-night-ul">
                                                    {
                                                      this.props.selectedWebProduct.productDocuments.map((document) =>
                                                      (
                                                        <li><a href={document.documentURL} target="_blank" className="a-underline">{document.documentName}</a></li>
                                                      ))
                                                    }
                                                </ul>
                                            </React.Fragment>
                                        }

                                        <hr />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>
                                                    {T.translate('addLocation.acknowledgment', this.props.systemProperties)}
                                                </p>
                                            </div>
                                        </div>

                                        {this.props.systemProperties.myAcctHoldEnrollmentTransferServiceAddLocation &&
                                          <React.Fragment>
                                            <hr />
                                            <div className="row">
                                              <div className="col-md-12">                                                        
                                                <p><strong>{CommonStore.textWrap(this.props.systemProperties['myAcctTranServiceAddLocationTitle' + this.props.lang.toUpperCase()], ';')}</strong></p>
                                                <p>
                                                  {CommonStore.textWrap(this.props.systemProperties['myAcctTranServiceAddLocationDes' + this.props.lang.toUpperCase()], ';')}
                                                </p>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        }
                                    </div>

                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="btn pull-right" onClick={this.onToggleConfirm}>{T.translate('common.close')}</button>
                                    <LoadingButton
                                        type="button"
                                        className="btn pull-right btn-primary"
                                        disabled={this.props.serviceLoading || this.state.disableSubmitMvi}
                                        loading={this.props.serviceLoading ? 'true' : 'false'}
                                        onClick={this.onSubmitMviConfirm}>
                                        {T.translate('common.submit')}
                                    </LoadingButton>
                                </ModalFooter>
                            </Modal>

                            <ConfirmModal
                                title={T.translate('addLocation.confirmDialog')}
                                open={this.props.showTransferServiceCancelModal}
                                onToggle={this.onToggleCancel}
                                onConfirm={this.goToDashboard}
                                confirmButtonText={T.translate('addLocation.yes')}>
                                <div><p>{T.translate('addLocation.areYouSureYouWantToNavigateAwayFromThisScreen')}</p></div>
                            </ConfirmModal>

                            <ConfirmModal
                                title={T.translate('addLocation.addLocationRequestConfirmTitle')}
                                open={this.props.showTransferServiceSuccessModal}
                                onConfirm={this.goToDashboard}
                                confirmButtonText={T.translate('addLocation.ok')}>
                                <div><p>{T.translate('addLocation.yourMviRequestHasBeenSubmitted', this.props.systemProperties)}</p></div>
                            </ConfirmModal>
                        </React.Fragment>}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.service, ...state.account, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...ServiceStore.actionCreators }, dispatch)
)(AddLocation);
