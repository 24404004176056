import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as UserStore from '../store/User';
import * as CommonStore from '../store/Common';
import LoadingButton from './shared/LoadingButton';
import MessageError from './shared/MessageError';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import validator from 'validator';
import T from 'i18n-react';
import { Tooltip } from 'reactstrap';

const Scroll = require('react-scroll');

class Landing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountNumber: '',
            phone: '',
            email: '',
            fields: [
                { name: 'accountNumber' },
                { name: 'phone' },
                { name: 'email', isRequired: false, isEmail: true } //3
            ],
            tooltipPhoneOpen: false,
            tooltipEmailOpen: false,
            accountRegistered: false
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('landing.title');

        this.props.clearUserState();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.accountRegistered) {
            this.setState({ accountRegistered: true });
        }
    }

    toggleEmailTooltip = () => {
        this.setState({
            tooltipEmailOpen: !this.state.tooltipEmailOpen
        });
    }

    togglePhoneTooltip = () => {
        this.setState({
            tooltipPhoneOpen: !this.state.tooltipPhoneOpen
        });
    }

    onChange = (e) => {

        let state = this.state;
        let fields = state.fields.filter(x => x.name === e.target.name);

        if (fields && fields.length === 1) {

            let field = fields[0];

            if (field.isEmailError && validator.isEmail(e.target.value)) {
                field.isEmailError = false;
            }
        }

        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    validateAccount = () => {
        if (this.isFormValid()) {
            this.props.validateConvertedAccount(this.state);
        }
    }

    isFormValid = () => {

        var state = this.state;
        var valid = true;

        this.state.fields.map((field) => {

            field.value = this.state[field.name];
            field.isEmailError = false;

            if (field.isEmail && field.value && !validator.isEmail(field.value)) {
                field.isEmailError = true;
                valid = false;
            }

            return field;
        });

        this.setState(state);

        if (valid) {

            if (!this.state.email && !this.state.phone && !this.state.accountNumber) {
                valid = false;
                this.props.setError(T.translate('landing.validationErrorRequired'));
            } else {
                this.props.setError('');
            }
        } else {
            this.props.setError(T.translate('common.thereAreMissingFields'));
        }

        return valid;
    }

    render() {
        return (
            <div className="my-account-md-container">
                <div className="my-account-md-content">
                    <div className="page-header">
                        <div className="page-title-home">{T.translate('landing.title')}</div>
                    </div>
                    <div className="diverter" />
                    <h6 className="card-title mb-3">{T.translate('landing.subTitle1')}</h6>
                    <p>{T.translate('landing.paragraph1')}</p>
                    <p>{T.translate('landing.paragraph2')}</p>
                    <h6 className="card-title mb-3">{T.translate('landing.subTitle2')}</h6>
                    <p>{T.translate('landing.paragraph3')}</p>
                    <div>

                        <div className="landing-input-flex">
                            <div className="form-group mt-4">
                                <label htmlFor="accountNumber">{T.translate('landing.accountNumber')}</label>
                                <input type="text" id="accountNumber" name="accountNumber" value={this.state.accountNumber} onChange={this.onChange} className="form-control" autoComplete="off" />
                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                            </div>
                            <div>
                                <span>{T.translate('landing.orOperatorLabel')}</span>
                            </div>
                        </div>

                        <div className="landing-input-flex">
                            <div className="form-group">
                                <label id="lblPhoneNumber" htmlFor="phone">{T.translate('landing.phoneNumber')}</label>
                                <Tooltip placement="right-end" isOpen={this.state.tooltipPhoneOpen} target="lblPhoneNumber" toggle={this.togglePhoneTooltip}>
                                    <div>{T.translate('landing.phoneOnAccount')}</div>
                                </Tooltip>
                                <InputMask mask="(999) 999-9999" id="phone" name="phone" value={this.state.phone} onChange={this.onChange} className="form-control" autoComplete="new-password" />
                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                            </div>
                            <div>
                                <span>{T.translate('landing.orOperatorLabel')}</span>
                            </div>
                        </div>

                        <div className="landing-input-flex">
                             <div className="form-group">
                             <label id="lblEmail" htmlFor="email">{T.translate('landing.emailAddress')}</label>
                             <Tooltip placement="right-end" isOpen={this.state.tooltipEmailOpen} target="lblEmail" toggle={this.toggleEmailTooltip}>
                                <div>{T.translate('landing.emailOnAccount')}</div>
                             </Tooltip>
                             <input type="text" id="email" name="email" value={this.state.email} onChange={this.onChange} className={"form-control " + (this.state.fields[2].isEmailError ? 'is-invalid' : '')} autoComplete="new-password" />
                                <div className="invalid-feedback">{this.state.fields[2].isEmailError && T.translate('common.provideValidEmail')}</div>
                             </div>
                             <div>
                                <span>{T.translate('landing.orOperatorLabel')}</span>
                             </div>
                        </div>

                        <MessageError>{this.props.userErrorMessage}</MessageError>

                        {this.state.accountRegistered && <div>
                            <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                                <div className="d-flex">
                                    <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                    <div>
                                        <p>{T.translate('landing.accountAlreadyRegistered')}</p>
                                        <NavLink to="/" className="btn btn-primary btn-lg">{T.translate('landing.goToLogin')}</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <LoadingButton
                            className="btn btn-primary btn-lg"
                            disabled={this.props.userLoading || this.props.commonLoading}
                            loading={this.props.userLoading || this.props.commonLoading ? 'true' : 'false'}
                            onClick={this.validateAccount}>
                            {T.translate('landing.continue')}
                        </LoadingButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(Landing);
