import Cookies from 'universal-cookie';
import * as CommonState from './Common';

const initialState = {
    profileLoading: false,
    profileErrorMessage: '',
    showUpdateProfileSuccessModal: false,
    notificationCategories: [],
    accountCommunications: [],
    reverse2FASetting: false
};

export const actionCreators = {

    clearProfileState: () => (dispatch) => {
        dispatch({ type: 'CLEAR_PROFILE_STATE' });
    },

    setProfileError: (errorMessage) => (dispatch) => {
        dispatch({ type: 'SET_PROFILE_ERROR', profileErrorMessage: errorMessage });
    },

    updateProfile: (view) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let accountAddress = state.common.account.mailingAddress;
        let person = state.common.account.person;
        let phoneContacts = [];
        let billDeliveryMethod = [];
        let notificationChannels = [];

        accountAddress = accountAddress ? accountAddress : {};

        accountAddress.address1 = view.address1;
        accountAddress.address2 = view.address2;
        accountAddress.city = view.city;
        accountAddress.state = view.state;
        accountAddress.postalCode = view.postalCode;

        if (person && (view.homePhone || view.homePhone === '')) {
            //Allowing to send empty values, so the service can resolve person contact action (if new ignores insertion, if exists deletes the record)
            //If required the screen does the form validation before send data
            let phone = person.personContacts.filter(x => x.contactTypeID === 2)[0];
            let homePhone = view.homePhone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
            if (phone && phone !== undefined) {
                phoneContacts.push({ contactValue: homePhone, contactTypeID: 2, personContactID: phone.personContactID });
            } else {
                phoneContacts.push({ contactValue: homePhone, contactTypeID: 2 });
            }
        }

        if (person && (view.mobilePhone || view.mobilePhone === '')) {
            //Allowing to send empty values, so the service can resolve person contact action (if new ignores insertion, if exists deletes the record)
            //If required the screen does the form validation before send data
            let phone = person.personContacts.filter(x => x.contactTypeID === 1)[0];
            let mobilePhone = view.mobilePhone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
            if (phone && phone !== undefined) {
                phoneContacts.push({ contactValue: mobilePhone, contactTypeID: 1, personContactID: phone.personContactID });
            } else {
                phoneContacts.push({ contactValue: mobilePhone, contactTypeID: 1 });
            }
        }

        if (view.billDeliveryMethodPaper) {
            billDeliveryMethod.push({ billDeliveryMethodID: 1, name: 'Paper' });
        }

        if (view.billDeliveryMethodElectBill) {
            billDeliveryMethod.push({ billDeliveryMethodID: 2, name: 'E-Bill' });
        }

        if (view.notificationChannelEmail) {
            notificationChannels.push({ notificationChannelID: 1 });
        }

        if (view.notificationChannelSms) {
            notificationChannels.push({ notificationChannelID: 2 });
        }

        let request = {};
        request.accountAddress = accountAddress;
        request.accountID = state.common.accountID;
        request.languageID = view.languageID;
        request.email = view.emailAddress;
        request.profileUpdateTypeID = 1;
        request.phoneContacts = phoneContacts;
        request.billDeliveryMethod = billDeliveryMethod;
        request.notificationChannels = notificationChannels;
        request.personID = person ? person.personID : 0;
        request.lastUpdateUserName = person ? state.common.account.person.fullName + ' via My Account' : 'Via My Account';

        fetch(`${state.common.serviceUrl}/AccountProfile`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                toggleUpdateProfileSuccessModal(dispatch, getState);

            })
            .catch(function (error) {
                dispatch({ type: 'SET_PROFILE_ERROR', profileErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PROFILE_START' });

    },

    updatePassword: (view) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let person = state.common.account.person;

        let request = {};
        request.accountID = state.common.accountID;
        request.personID = person ? person.personID : 0;
        request.password = view.password;

        fetch(`${state.common.serviceUrl}/Password`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                toggleUpdateProfileSuccessModal(dispatch, getState);

            })
            .catch(function (error) {
                dispatch({ type: 'SET_PROFILE_ERROR', profileErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PROFILE_START' });

    },

    updateTFASetting: (view) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let person = state.common.account.person;

        let request = {};
        request.accountID = state.common.accountID;
        request.personID = person ? person.personID : 0;

        request.twoFactorAuthRequired = view.twoFactorAuthRequired;

        fetch(`${state.common.serviceUrl}/TFASetting`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                if (!request.twoFactorAuthRequired) {
                    const cookies = new Cookies();
                    cookies.remove('twf4a9th');
                }

                toggleUpdateProfileSuccessModal(dispatch, getState);
            })
            .catch(function (error) {
                dispatch({ type: 'SET_2FACTOR_AUTH_ERROR', profileErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PROFILE_START' });

    },

    createTask: (fields) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let request = {
            accountID: state.common.accountID,
            customerName: fields['customer-name'].value,
            accountNumber: fields['account-number'].value,
            emailAddress: fields['email-address'].value,
            phoneNumber: fields['phone-number'].value,
            textContent: fields['how-can-we'].value
        };

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/Task`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                dispatch({ type: 'TOGGLE_CREATE_TASK', showTaskCreationConfirmation: true });
            })
            .catch(function (error) {
                console.error(error);
                dispatch({ type: 'SET_PROFILE_ERROR', profileErrorMessage: 'contactUs.taskCreationError' });//Sending resource language key
            });

        dispatch({ type: 'REQUEST_PROFILE_START' });

    },

    toggleTaskCreationSuccessModal: () => (dispatch, getState) => {
        toggleTaskCreationSuccessModal(dispatch, getState);
    },

    toggleUpdateProfileSuccessModal: () => (dispatch, getState) => {
        toggleUpdateProfileSuccessModal(dispatch, getState);
    },

    notificationInit: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let accountActivityTypeID = 2;
        let from = new Date();
        let to = new Date();

        from.setHours(0, 0, 0, 0);
        from = CommonState.addMonthsToDate(from, -6);

        var fromString = CommonState.formatDate(from, 'MM/DD/YYYY');
        var toString = CommonState.formatDate(to, 'MM/DD/YYYY');

        var request = { accountID: state.common.accountID, fromDate: fromString, toDate: toString, accountActivityTypeID: accountActivityTypeID };

        var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

        let fetchAccountCommunication = fetch(`${state.common.serviceUrl}/AccountActivity?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        let fetchNotificationCategories = fetch(`${state.common.serviceUrl}/NotificationCategories?LanguageID=${state.common.langID}&accountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        let fetchNotificationOptOutCategories = fetch(`${state.common.serviceUrl}/NotificationOptOutCategories?AccountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        //call both methods and when got all results process them
        //TODO: review webmethods
        Promise.all([fetchAccountCommunication, fetchNotificationCategories, fetchNotificationOptOutCategories])
            .then(([communications, notificationCategories, notificationOptOutCategories]) => {
                if (notificationOptOutCategories && notificationOptOutCategories.length && notificationCategories && notificationCategories.length) {

                    notificationOptOutCategories.map(function (optOut) {
                        let category = notificationCategories.filter(x => x.value == optOut)[0];
                        if (category) {
                            category.optOut = true;
                        }

                        return optOut;
                    });
                }

                dispatch({ type: 'SET_PROFILE_ACCOUNT_COMMUNICATIONS', accountCommunications: communications.accountCommunications, notificationCategories: notificationCategories });


            })
            .catch(function (error) {
                dispatch({ type: 'SET_PROFILE_ERROR', profileErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PROFILE_START' });

    },

    saveNotificationOptOutCategories: (categories) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let notificationCategoriesIDs = categories.filter(x => x.optOut).map(function (obj) {
            return obj.value;
        });

        let request = {};
        request.NotificationCategoriesId = notificationCategoriesIDs;
        request.accountID = state.common.accountID;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/NotificationOptOutCategories`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                toggleUpdateNotificationSuccessModal(dispatch, getState);

            })
            .catch(function (error) {
                dispatch({ type: 'SET_PROFILE_ERROR', profileErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PROFILE_START' });

    },

    toggleUpdateNotificationSuccessModal: () => (dispatch, getState) => {
        toggleUpdateNotificationSuccessModal(dispatch, getState);
    }

};

var toggleUpdateNotificationSuccessModal = function (dispatch, getState) {
    const state = getState().profile;
    dispatch({ type: 'TOGGLE_UPDATE_NOTIFICATION_SUCCESS_MODAL', showUpdateNotificationSuccessModal: !state.showUpdateNotificationSuccessModal });
};

var toggleTaskCreationSuccessModal = function (dispatch, getState) {
    const state = getState().profile;
    dispatch({ type: 'TOGGLE_TASK_CREATION_SUCCESS_MODAL', showTaskCreationConfirmation: !state.showTaskCreationConfirmation });
};

var toggleUpdateProfileSuccessModal = function (dispatch, getState) {
    const state = getState().profile;
    dispatch({ type: 'TOGGLE_UPDATE_PROFILE_SUCCESS_MODAL', showUpdateProfileSuccessModal: !state.showUpdateProfileSuccessModal });
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case 'REQUEST_PROFILE_START':
            return { ...state, profileLoading: true, profileErrorMessage: '', reverse2FASetting: false };
        case 'SET_PROFILE_ERROR':
            return { ...state, profileErrorMessage: action.profileErrorMessage, profileLoading: false };
        case 'SET_2FACTOR_AUTH_ERROR':
            return { ...state, profileErrorMessage: action.profileErrorMessage, profileLoading: false, reverse2FASetting: true };
        case 'TOGGLE_CREATE_TASK':
            return { ...state, profileLoading: false, showTaskCreationConfirmation: action.showTaskCreationConfirmation };
        case 'TOGGLE_TASK_CREATION_SUCCESS_MODAL':
            return { ...state, profileLoading: false, showTaskCreationConfirmation: action.showTaskCreationConfirmation };
        case 'TOGGLE_UPDATE_PROFILE_SUCCESS_MODAL':
            return { ...state, showUpdateProfileSuccessModal: action.showUpdateProfileSuccessModal, profileLoading: false, reverse2FASetting: false };
        case 'TOGGLE_UPDATE_NOTIFICATION_SUCCESS_MODAL':
            return { ...state, showUpdateNotificationSuccessModal: action.showUpdateNotificationSuccessModal, profileLoading: false };
        case 'SET_PROFILE_ACCOUNT_COMMUNICATIONS':
            return { ...state, accountCommunications: action.accountCommunications, notificationCategories: action.notificationCategories, profileLoading: false };
        case 'CLEAR_PROFILE_STATE':
            return initialState;
        default:
            return state;
    }

};
