import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ProfileStore from '../../store/Profile';
import * as CommonStore from '../../store/Common';
import T from 'i18n-react';
import MessageError from '../shared/MessageError';
import LoadingOverlay from '../shared/LoadingOverlay';
import DataGrid from '../shared/DataGrid';
import LoadingButton from '../shared/LoadingButton';
import ConfirmModal from '../shared/ConfirmModal';

const Scroll = require('react-scroll');

class Notifications extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
            notificationCategories: props.notificationCategories
        };

    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('notification.title');

        if (this.props.account.accountNumber) {
            this.props.notificationInit();
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.notificationInit();
        }

        if (nextProps.notificationCategories.length && !this.props.notificationCategories.length) {
            this.setState({ notificationCategories: nextProps.notificationCategories });    
        }
    }

    toggleActiveTab = (e) => {
        var tab = e.target.attributes['data-tab'].value;
        this.setState({ activeTab: tab });
    }

    onSubmit = (e) => {
        this.props.saveNotificationOptOutCategories(this.state.notificationCategories);
    }

    onChange = (e) => {

        let notificationCategories = this.state.notificationCategories;
        let category = notificationCategories.filter(x => x.value === e.value)[0];

        if (category) {
            category.optOut = !category.optOut;
        }

        this.setState({ notificationCategories });

    }

    setHighlightStyle = (columnId) => {
        var styleClass = '';

        if (columnId === 'notificationChannelName') {
            styleClass = 'plan-current kanit font-weight-normal';
        }

        return styleClass;
    }

    render() {
        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.profileLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('notification.pageTitle')}</div>
                    <p className="lead text-dark font-weight-normal">
                        {T.translate('notification.manageNotificationsWithConvenience')}<br />
                        <strong>{T.translate('notification.manageNotificationsWithConvenienceNote')}</strong>
                    </p>
                </div>
                <div className="diverter" />
                <ul className="nav nav-tabs">
                    <li className="nav-item"><a className={"nav-link " + (this.state.activeTab === '1' ? "active" : "")} onClick={this.toggleActiveTab} data-tab="1" href="javascript:;">{T.translate('notification.accountNotifications')}</a></li>
                    <li className="nav-item"><a className={"nav-link " + (this.state.activeTab === '2' ? "active" : "")} onClick={this.toggleActiveTab} data-tab="2" href="javascript:;">{T.translate('notification.notificationHistory')}</a></li>
                </ul>
                <div className="tab-content mb-4">

                    {this.state.activeTab === '1' && <div className={"tab-pane " + (this.state.activeTab === '1' ? "active" : "")}>

                        {this.state.notificationCategories.map((category, i) => (

                            <div className="account-noti-box" key={i}>
                                <div className="account-noti-left">
                                    <h6>{category.name}</h6>
                                    <p className="mb-0">{category.description}</p>
                                </div>
                                <ul className="noti-switch">
                                    <li>
                                        <label className="switch" htmlFor={"switch-" + i}>
                                            <input type="checkbox" id={"switch-" + i} checked={!category.optOut} onChange={() => { this.onChange(category); }} />
                                            <span className="slider round" />
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            
                        ))}

                        <LoadingButton
                            className="btn btn-primary"
                            onClick={this.onSubmit}
                            loading={this.props.profileLoading ? 'true' : 'false'}>
                            {T.translate('common.submit')}
                        </LoadingButton>

                    </div>}

                    {this.state.activeTab === '2' && <div className={"tab-pane " + (this.state.activeTab === '2' ? "active" : "")}>

                        <DataGrid dataKey="accountTransactionID" usePaging downloadData csvFileName={'Notifications ' + Date() + '.csv'} expandibleContent={false} pageSize="15" onHighlightStyle={this.setHighlightStyle}>
                            {{
                                rows: this.props.accountCommunications,
                                columns: [
                                    { columnId: 'communicationDate', displayText: T.translate('notification.date'), dataType: 'date', format: 'MM/dd/yyyy', bold: true, italic: true },
                                    { columnId: 'communicationTypeText', displayText: T.translate('notification.category') },
                                    { columnId: 'recipient', displayText: T.translate('notification.recipient') },
                                    { columnId: 'notificationChannelName', displayText: T.translate('notification.channel'), hightlightCell: true },
                                    { columnId: 'communicationText', displayText: T.translate('notification.content'), style: { width: '50%' } }
                                ]
                            }}
                        </DataGrid>

                    </div>}

                </div>

                <MessageError>{this.props.profileErrorMessage}</MessageError>

                <ConfirmModal
                    title={T.translate('notification.notificationsUpdated')}
                    open={this.props.showUpdateNotificationSuccessModal}
                    onToggle={this.props.toggleUpdateNotificationSuccessModal}
                    loading={this.props.profileLoading}>
                    <div>
                        <p>{T.translate('notification.notificationsUpdated')}</p>
                        <p className="font-weight-bold">{T.translate('notification.accountNotificationSuccessfullyUpdated')}</p>
                    </div>
                </ConfirmModal> 

            </div>
        );
    }
}

export default connect(
    state => ({ ...state.common, ...state.profile }),
    dispatch => bindActionCreators({ ...CommonStore.actionCreators, ...ProfileStore.actionCreators }, dispatch)
)(Notifications);
