import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Animate from 'react-smooth';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as PaymentStore from '../../store/Payment';
import ExternalLink from '../shared/ExternalLink';
import LoadingOverlay from '../shared/LoadingOverlay';
import EnergyUsageTab from '../shared/tabComponents/EnergyUsageTab';
import WaterUsageTab from '../shared/tabComponents/WaterUsageTab';
import BasicModal from '../shared/BasicModal';

const Scroll = require('react-scroll');
const refer = require('../../images/refer-a-friend.jpg');
const autopay = require('../../images/autopay.jpg');

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            activeTab: 0,
            firstTab: 0,
            initializedContentTabs: [],
            energyChartDataTabs: [],
            waterChartDataTabs: [],
            chartEnergyTypeID: '1',
            chartWaterTypeID: '1',
            intervalElectricity: false,
            intervalWater: false,
            intervalDateElectricity: '',
            intervalDateWater: '',
            calculationLegendText: '',
            bannersHeader: [],
            bannerMarketing: undefined
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('dashboard.title');

        if (this.props.account.accountNumber) {
            this.props.getPaymentAccounts();
        }
    }

    componentWillReceiveProps(nextProps) {

        //Header Banners Logic
        let bannersHeader = [];
        let banersHeaderCounter = 0;
        if (nextProps.accountHeaderBanners && nextProps.accountHeaderBanners.length) {
            nextProps.accountHeaderBanners.map(function (banner, i) {
                if (banner.bannerTemplateDetails && banner.bannerTemplateDetails.length) {
                    banner.bannerTemplateDetails.map(function (bannerDetails, i) {
                        if (bannerDetails.languageID === nextProps.langID && banersHeaderCounter < 3) {
                            bannersHeader.push(bannerDetails);
                        }
                    });
                    banersHeaderCounter++;
                }
            });
        }
        this.setState({ bannersHeader: bannersHeader });

        let activeTab = this.state.activeTab;
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            activeTab = 0;
            this.setState({ activeTab });
        }

        if (activeTab > 0) {
            if ((nextProps.account.locationServices && !this.props.account.locationServices) || nextProps.account.locationServices !== this.props.account.locationServices) {
                activeTab = 0;
                this.setState({ activeTab });
            }
        }

        //Marketing Banners Logic
        let bannerMarketing = undefined;
        if (nextProps.accountHeaderBanners && nextProps.accountMarketingBanners.length) {
            var banner = nextProps.accountMarketingBanners[0];
            if (banner.bannerTemplateDetails && banner.bannerTemplateDetails.length) {
                banner.bannerTemplateDetails.map(function (bannerDetails, i) {
                    if (bannerDetails.languageID === nextProps.langID) {
                        bannerMarketing = bannerDetails;
                    }
                });
            }
        }

        this.setState({ bannerMarketing: bannerMarketing });

        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getPaymentAccounts();
        }

        if (activeTab === 0 && nextProps.account && nextProps.account.locationServices) {
            if (nextProps.account.locationServices.some(x => x.serviceTypeID !== 0)) {
                //in some cases we can have a single chart for a couple for comodities, so we need to set the default location based on a lead location that would be ELECTRICITY or WATER
                var leadCommodities = [this.props.constants.commodity.ELECTRICITY, this.props.constants.commodity.WATER ];
                var tabLocationServices = nextProps.account.locationServices.filter(x => leadCommodities.includes(x.commodityID));//pulling
                tabLocationServices = tabLocationServices.concat(nextProps.account.locationServices.filter(x => !leadCommodities.includes(x.commodityID)));

                var defaultAspID = tabLocationServices[0].accountServicePointID;

                var initializedContentTabs = this.state.initializedContentTabs;
                initializedContentTabs.push(defaultAspID);

                var energyChartDataTabs = this.state.energyChartDataTabs;
                var waterChartDataTabs = this.state.waterChartDataTabs;

                nextProps.account.locationServices.map(x => function (x) {
                    energyChartDataTabs['tab' + x.accountServicePointID] = undefined;
                    waterChartDataTabs['tab' + x.accountServicePointID] = undefined;
                });

                this.setState({
                    activeTab: defaultAspID,
                    firstTab: defaultAspID,
                    initializedContentTabs,
                    energyChartDataTabs,
                    waterChartDataTabs
                });
            } else {
                if ((nextProps.account.electricity || nextProps.account.water)) {
                    var defaultAspID = this.props.accountServicePointID;

                    var energyChartDataTabs = this.state.energyChartDataTabs;
                    energyChartDataTabs['tab' + defaultAspID] = undefined;

                    var waterChartDataTabs = this.state.waterChartDataTabs;
                    waterChartDataTabs['tab' + defaultAspID] = undefined;

                    var initializedContentTabs = this.state.initializedContentTabs;
                    initializedContentTabs.push(defaultAspID);

                    this.setState({
                        activeTab: defaultAspID,
                        firstTab: defaultAspID,
                        initializedContentTabs,
                        energyChartDataTabs,
                        waterChartDataTabs
                    });
                }
            }
        }

        if (nextProps.energyChartData && nextProps.energyChartData.accountServicePointID > 0) {
            var energyChartDataTabs = this.state.energyChartDataTabs;
            energyChartDataTabs['tab' + nextProps.energyChartData.accountServicePointID] = nextProps.energyChartData;
            this.setState({ energyChartDataTabs });
        }

        if (nextProps.waterChartData && nextProps.energyChartData.accountServicePointID > 0) {
            var waterChartDataTabs = this.state.waterChartDataTabs;
            waterChartDataTabs['tab' + nextProps.waterChartData.accountServicePointID] = nextProps.waterChartData;
            this.setState({ waterChartDataTabs });
        }
    }

    toggle = (e) => {
        var tab = parseInt(e.target.attributes['data-tab'].value);
        var initializedContentTabs = this.state.initializedContentTabs;

        if (!initializedContentTabs.includes(tab)) {
            initializedContentTabs.push(tab);
            this.onDataSourceRequest({ chartTypeID: this.state.chartWaterTypeID, accountServicePointID: tab });
        }

        this.setState({ activeTab: tab, initializedContentTabs });

    }


    hrefValue = () => {
        var token = this.props.getPrivateToken();
        var billID = this.props.account.lastBillID;
        var accountID = this.props.account.accountID;
        var url = 'api/data/Bill?accountID=' + accountID + '&billID=' + billID + '&t=' + encodeURIComponent(token.replace('bearer ', ''));
        return url;
    }

    getExternalRenewalUrl = () => {
        var renewalUrl = this.props.systemProperties.myAcctRenewalUrlRedirection.replace("{accountNumber}", this.props.account.accountNumber);
        return renewalUrl;
    }

    hasExternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection !== '') {
            return true;
        }
        return false;
    }

    hasInternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection === '') {
            return true;
        }
        return false;
    }

    onDataSourceRequest = (usageRequest) => {
        this.props.getAccountActivity(usageRequest);

        var energyChartDataTabs = this.state.energyChartDataTabs;
        energyChartDataTabs['tab' + usageRequest.accountServicePointID] = undefined;

        var waterChartDataTabs = this.state.waterChartDataTabs;
        waterChartDataTabs['tab' + usageRequest.accountServicePointID] = undefined;
        this.setState({ waterChartDataTabs, energyChartDataTabs });
    }

    isActiveTab = (asp, className) => {
        return this.isSelectedAsp(asp) ? className : "";
    }

    isSelectedAsp = (asp) => {
        if (this.state.activeTab === 0) {
            return this.state.activeTab === this.state.firstTab;
        } else {
            return this.state.activeTab === asp.accountServicePointID;
        }
    }

    openPaymentTypeSelectModal = () => {
        this.props.togglePaymentTypeSelectModal();
    }

    redirecteCheck = () => {
        this.props.history.push('/myaccount/payecheck');
        this.props.togglePaymentTypeSelectModal();
    }

    redirectCreditCard = () => {
        this.props.history.push('/myaccount/paycreditcard');
        this.props.togglePaymentTypeSelectModal();
    }

    render() {

        let accountBalanceValue = CommonStore.formatDecimal(this.props.invertBalance ? Math.abs(this.props.account.accountBalance) : this.props.account.accountBalance);
        // Available balance is green the label should be green (credit), if value is red then label should be red (debit), when zero balance it should black
        let availableBalanceTextColor = (this.props.account.availableBalance === 0) || (this.props.account.availableBalance === '0') ? "font-weight-bold" :
            (this.props.account.availableBalance > 0) ? "text-success font-weight-bold" : "text-danger font-weight-bold"

        return (

            <div className="p-main">

                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('dashboard.pageTitle')}</div>
                    <p className="lead text-dark font-weight-normal kanit">{T.translate('dashboard.accountNumber')}: {this.props.account.accountNumber}</p>

                    {this.props.account.hasRenewalOffers && <div className="alert bg-success text-white mb-4">
                        <div className="row d-flex align-self-center banner">
                            <div className="col-lg-7 d-flex align-self-center">
                                <FontAwesomeIcon size="2x" className="mr-3" icon={faCheckCircle} />
                                <div>
                                    {T.translate('dashboard.renewalSuggestion', this.props.systemProperties)}
                                </div>
                            </div>
                            {this.hasInternalRenewalContent(this.props.account.hasRenewalOffers) && <div className="col-lg-5">
                                <NavLink to="/myaccount/renewal" className="btn btn-primary">{T.translate('dashboard.renewService')}</NavLink>
                            </div>}
                            {this.hasExternalRenewalContent(this.props.account.hasRenewalOffers) && <div className="col-lg-5">
                                {<a href={this.getExternalRenewalUrl()} target="_blank" className="btn btn-primary">{T.translate('dashboard.renewService')}</a>}
                            </div>}
                        </div>
                    </div>}



                    {this.state.bannersHeader.map(banner => (
                        <div key={banner.bannerTemplateDetailID} className="alert bg-success text-white mb-4">
                            <div className="row d-flex align-self-center banner">
                                <div className="col-lg-7 d-flex align-self-center">
                                    <FontAwesomeIcon size="2x" className="mr-3" icon={faCheckCircle} />
                                    <div>
                                        {banner.bannerBody}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {this.props.account.isCashOnly && <div className="alert bg-info text-white mb-4">
                        <div className="d-flex align-self-center">
                            <FontAwesomeIcon size="2x" className="mr-3" icon={faExclamationCircle} />
                            <div className="d-flex align-self-center">{T.translate('dashboard.isCashOnlyAccountMessage', this.props.systemProperties)}</div>
                        </div>
                    </div>}

                    {this.props.showAccountWarning &&
                        <div className="alert alert-danger alert-primary" role="alert">
                            <p className="mg-bm-5">{T.translate('dashboard.accountWarning1')}</p>
                            <p className="mg-bm-5">{T.translate('dashboard.accountWarning2')}</p>
                            <p className="mg-bm-5">{T.translate('dashboard.accountWarning3')}</p>
                            <p className="mg-bm-5">{T.translate('dashboard.accountWarning4')}</p>
                        </div>
                    }

                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="card mb-4" id="divAccountDashboard" style={{ height: '98%' }}>

                            {this.props.account.accountBillingTypeID === 1 && <div className="card-body">
                                <h6 className="card-title">{T.translate('dashboard.prepaySummary')}</h6>
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="row">
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.availableBalance')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <strong className={availableBalanceTextColor}>${CommonStore.formatDecimal(this.props.account.availableBalance)}</strong> {this.props.account.availableBalance > 0 ? <span className={availableBalanceTextColor}>{T.translate('dashboard.credit')}</span> : <span className={availableBalanceTextColor}>{T.translate('dashboard.debit')}</span>}
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.disconnectThreshold')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="mb-0">${CommonStore.formatDecimal(this.props.account.disconnectThreshold)}</span>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.accountBalance')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="mb-0"> ${accountBalanceValue}</span> {this.props.account.accountBalance > 0 && <span className="mb-0">{T.translate('dashboard.credit')}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 text-center align-self-center">
                                        <div className="progress">
                                            <div className="baroverflow">
                                                <Animate easing="ease" from={{ y: 0 }} to={{ y: this.props.account.daysRemainingCount }}>
                                                    {({ y }) => <div className="bar" style={{ transform: `rotate(${45 + y * 6}deg)` }} />}
                                                </Animate>
                                            </div>
                                            <div className="progress-count">
                                                <Animate from={{ y: 0 }} to={{ y: this.props.account.daysRemaining }}>
                                                    {({ y }) => <span>{parseInt(y)}</span>}
                                                </Animate>
                                            </div>
                                        </div>
                                        <h5 className="est-remaning">{T.translate('dashboard.estDaysRemaining')}</h5>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="row">
                                            {this.props.showEnergySpend && <React.Fragment>
                                                <div className="col-md-6 pt-2 pb-2">
                                                    <p className="mb-0">{T.translate('dashboard.avgDailyUsage')}:</p>
                                                </div>
                                                <div className="col-md-6 pt-2 pb-2">
                                                    <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.averageDailyUsage)}</span>
                                                </div>
                                            </React.Fragment>}
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.avgDailyKwhUsage')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">{CommonStore.formatDecimal(this.props.account.averageDailyKwh)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 text-left text-md-center align-self-center">
                                        {T.translate('dashboard.meterStatus')}<span className={(this.props.account.meterStatusID === 2 ? "bg-success" : "bg-danger") + " ml-3 text-white rounded font-weight-normal py-1 px-3 sp-tag"}>{this.props.account.meterStatus}</span>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="row">
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.paymentPlanBalance')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.debtBalance)}</span>
                                            </div>
                                            {this.props.account.lastPaymentAmount > 0 && <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.lastPayment')}:</p>
                                            </div>}
                                            {this.props.account.lastPaymentAmount > 0 && <div className="col-md-6 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.lastPaymentAmount)} on {CommonStore.formatDate(this.props.account.lastPaymentDate)}</span>
                                            </div>}
                                        </div>
                                    </div>

                                    {!this.props.account.isCashOnly && this.props.showCreditCardPaymentType
                                        && <div className="col-md-5 text-left text-md-center align-self-center">
                                            <NavLink to="/myaccount/paycreditcard" className="btn btn-pay">{T.translate('dashboard.payNow')}</NavLink>
                                        </div>}
                                    {!this.props.account.isCashOnly && this.props.showEcheckPaymentType
                                        && <div className="col-md-5 text-left text-md-center align-self-center">
                                            <NavLink to="/myaccount/payecheck" className="btn btn-pay">{T.translate('dashboard.payNow')}</NavLink>
                                        </div>}
                                    {!this.props.account.isCashOnly && this.props.showCreditCardAndEcheckPaymentType
                                        && <div className="col-md-5 text-left text-md-center align-self-center">
                                            <button className="btn btn-pay" type="button" onClick={this.openPaymentTypeSelectModal}>{T.translate('dashboard.payNow')}</button>
                                        </div>}
                                </div>
                            </div>}

                            {this.props.account.accountBillingTypeID !== 1 && <div className="card-body">
                                <h6 className="card-title">{T.translate('dashboard.billingSummary')}</h6>
                                <div className="row">
                                    <div className="col-md-6 align-self-center">
                                        <div className="row">
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.accountBalance')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <strong className={this.props.account.accountBalance < 0 ? "text-danger font-weight-bold" : "text-success font-weight-bold"}>${CommonStore.formatDecimal(this.props.invertBalance ? Math.abs(this.props.account.accountBalance) : this.props.account.accountBalance)}</strong> {this.props.account.accountBalance > 0 && <span className="text-success font-weight-bold">{T.translate('dashboard.credit')}</span>}
                                            </div>
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.nextDueDate')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">{CommonStore.formatDate(this.props.account.paymentDueDate)}</span>
                                            </div>
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.totalAmountDue')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.totalAmountDue)}</span>
                                            </div>
                                        </div>
                                    </div>

                                    {!this.props.account.isCashOnly && this.props.showCreditCardPaymentType
                                        && <div className="col-md-6 text-left text-md-center align-self-center">
                                            <NavLink to="/myaccount/paycreditcard" className="btn btn-pay">{T.translate('dashboard.payMyBill')}</NavLink>
                                        </div>}
                                    {!this.props.account.isCashOnly && this.props.showEcheckPaymentType
                                        && <div className="col-md-6 text-left text-md-center align-self-center">
                                            <NavLink to="/myaccount/payecheck" className="btn btn-pay">{T.translate('dashboard.payMyBill')}</NavLink>
                                        </div>}
                                    {!this.props.account.isCashOnly && this.props.showCreditCardAndEcheckPaymentType
                                        && <div className="col-md-6 text-left text-md-center align-self-center">
                                            <button className="btn btn-pay" type="button" onClick={this.openPaymentTypeSelectModal}>{T.translate('dashboard.payMyBill')}</button>
                                        </div>}
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-6 align-self-center">
                                        <div className="row">
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.currentAmountDue')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.currentAmountDue)}</span>
                                            </div>
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.pastDue')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.pastDueAmount)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-left text-md-center align-self-center">

                                        {this.props.account.lastBillID > 0 && <ExternalLink href={this.hrefValue()} className="btn btn-outline-dark m-2">{T.translate('dashboard.viewMyBill')}</ExternalLink>}

                                        {false && <NavLink to="/myaccount/monthlystatements" className="btn btn-outline-dark m-2">{T.translate('dashboard.viewMyBill')}</NavLink>}
                                        <NavLink to="/myaccount/monthlystatements" className="btn btn-outline-dark m-2">{T.translate('dashboard.viewPastStatement')}</NavLink>
                                    </div>
                                </div>
                                <hr />
                                {this.props.account.lastPaymentAmount > 0 && <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="row">
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.lastPayment')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.lastPaymentAmount)} on {CommonStore.formatDate(this.props.account.lastPaymentDate)}</span>
                                                {false && <p className="text-success mb-0 kanit">{T.translate('dashboard.autoPayScheduledFor')}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>}

                        </div>
                    </div>
                    {this.state.bannerMarketing && <div className="col-lg-4">
                        <div className="card mb-4" style={{ height: '98%' }}>
                            <div className="card-body img-fluid-container">
                                <div dangerouslySetInnerHTML={{ __html: this.state.bannerMarketing.bannerBodyHtml }} ></div>
                            </div>
                        </div>
                    </div>}

                    {!this.state.bannerMarketing && <div className="col-lg-4">
                        <div className="card mb-4" style={{ height: '98%' }}>
                            <div className="card-body">
                                <img src={refer} className="img-fluid" alt={T.translate('dashboard.tmpDinamicBannerTitle')} />
                                <h6 className="card-title mt-3">{T.translate('dashboard.tmpDinamicBannerTitle')}</h6>
                                <span>{T.translate('dashboard.tmpDinamicBannerParagraph')}</span>
                                {false && <React.Fragment><br />
                                    <p className="mb-4">Use Code <strong>XYRAZ</strong> when referring.</p>
                                </React.Fragment>}
                            </div>
                        </div>
                        {false && <div className="card">
                            <div className="card-body">
                                <img src={autopay} className="img-fluid" alt="autopay" />
                                <h6 className="card-title mt-3">You're not enrolled in Auto Pay</h6>
                                <p>
                                    Save a boat load of time plus 20% off our standard
                                    rates by enrolling in Auto Pay today!
                                </p>
                                <NavLink to="/myaccount/autopay" className="btn btn-primary">Set up auto pay</NavLink>
                            </div>
                        </div>}
                    </div>
                    }
                </div>


                <div className="row">
                    <div className="col-lg-12">

                        {this.props.account.locationServices &&

                            <React.Fragment>
                                <ul className="nav nav-tabs">
                                {this.props.account.locationServices.filter(x => x.isMetered && x.linkedServiceTypeID).sort((x, y) => (x.serviceTypeID - y.serviceTypeID)).map(asp => (
                                        <React.Fragment key={asp.accountServicePointID}>
                                        { asp.linkedServiceTypeID === -1 && <li key={asp.accountServicePointID} className="nav-item"><a href="javascript:;" data-tab={asp.accountServicePointID} className={"nav-link " + this.isActiveTab(asp, "active")} onClick={this.toggle}>{T.translate('dashboard.serviceType' + asp.serviceTypeID)}</a></li> }
                                        { asp.linkedServiceTypeID > 0 && <li key={asp.accountServicePointID} className="nav-item"><a href="javascript:;" data-tab={asp.accountServicePointID} className={"nav-link " + this.isActiveTab(asp, "active")} onClick={this.toggle}>{T.translate('dashboard.serviceType' + asp.serviceTypeID) + ' & ' + T.translate('dashboard.serviceType' + asp.linkedServiceTypeID)}</a></li>}
                                        </React.Fragment>
                                    ))}
                                </ul>

                                <div className="tab-content mb-4">
                                {this.props.account.locationServices.filter(x => x.isMetered && x.linkedServiceTypeID).sort((x, y) => (x.serviceTypeID - y.serviceTypeID)).map(asp => (
                                        <React.Fragment key={asp.accountServicePointID}>
                                        {asp.commodityID === this.props.constants.commodity.ELECTRICITY &&
                                            <EnergyUsageTab id={'tab' + asp.accountServicePointID} asp={asp} active={this.isSelectedAsp(asp)} onDataSourceRequest={this.onDataSourceRequest} chartData={this.state.energyChartDataTabs['tab' + asp.accountServicePointID]} />
                                        }
                                        {(asp.commodityID === this.props.constants.commodity.WATER || asp.commodityID === this.props.constants.commodity.SEWER || asp.commodityID === this.props.constants.commodity.IRRIGATION) &&
                                            <WaterUsageTab id={'tab' + asp.accountServicePointID} asp={asp} active={this.isSelectedAsp(asp)} onDataSourceRequest={this.onDataSourceRequest} chartData={this.state.waterChartDataTabs['tab' + asp.accountServicePointID]} />
                                        }
                                        </React.Fragment>
                                    ))}
                                </div>

                            </React.Fragment>
                        }

                        {!this.props.account.locationServices &&
                            <React.Fragment>
                                <ul className="nav nav-tabs">
                                    {this.props.account.electricity && <li className="nav-item"><a href="javascript:;" data-tab={this.props.accountServicePointID} className={"nav-link active"} onClick={this.toggle}>{T.translate('dashboard.power')}</a></li>}
                                    {this.props.account.water && <li className="nav-item"><a href="javascript:;" data-tab={this.props.accountServicePointID} className={"nav-link active"} onClick={this.toggle}>{T.translate('dashboard.water')}</a></li>}
                                </ul>
                                <div className="tab-content mb-4">

                                    {this.props.account.electricity &&
                                        <EnergyUsageTab id={'tab' + this.props.accountServicePointID} asp={{ accountServicePointID: this.props.accountServicePointID }} active={true} onDataSourceRequest={this.onDataSourceRequest} chartData={this.state.energyChartDataTabs['tab' + this.props.accountServicePointID]} />
                                    }

                                    {this.props.account.water &&
                                        <WaterUsageTab id={'tab' + this.props.accountServicePointID} asp={{ accountServicePointID: this.props.accountServicePointID }} active={true} onDataSourceRequest={this.onDataSourceRequest} chartData={this.state.waterChartDataTabs['tab' + this.props.accountServicePointID]} />
                                    }

                                </div>
                            </React.Fragment>
                        }

                    </div>
                </div>
                <BasicModal visible={this.props.showPaymentTypeSelectedModal} title={T.translate('accountPay.selectPaymentMethod')}
                    onClick={this.redirectCreditCard} btnText={T.translate('accountPay.creditCard')}
                    btnText2={T.translate('accountPay.eCheck')} onClick2nd={this.redirecteCheck}
                    size="lg">
                    <div className="card mb-5">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <span><h6 className="card-title" style={{ display: "inline-block", paddingRight: "10px" }}>{T.translate('accountPay.selectPaymentMethod')}?: </h6></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </BasicModal>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common, ...state.payment }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators }, dispatch)
)(Dashboard);
