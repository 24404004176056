import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingButton from '../shared/LoadingButton';


class BasicModal extends Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
    }

    getSize = () => {

        if (this.props.size) {
            return this.props.size;
        }
        return 'md';
    }

    isLoading = () => {
        if (this.props.isLoading) {
            return this.props.isLoading;
        }
        return false;
    }

    render() {
        return (
            <Modal className="quick-pay-modal" isOpen={this.props.visible} size={this.getSize()}>
                <ModalHeader className="bg-primary"><span className="text-white">{this.props.title}</span></ModalHeader>
                <ModalBody>
                    {this.props.children}
                </ModalBody>
                <ModalFooter>
                    {this.props.btnText && this.props.onClick && <button type="button" className="btn" onClick={this.props.onClick} disabled={this.isLoading()}>{this.props.btnText}</button>}
                    {this.props.btnText2 && this.props.onClick2nd && <button type="button" className="btn" onClick={this.props.onClick2nd} disabled={this.isLoading()}>{this.props.btnText2} </button>}
                    {this.props.btnText3 && this.props.onClick3rd && <button type="button" className="btn" onClick={this.props.onClick3rd} disabled={this.isLoading()}>{this.props.btnText3} </button>}
                    {this.props.loadingBtnText && this.props.onClickLoadingBtn &&
                        <LoadingButton
                            type="button"
                            className="btn btn-primary"
                            loading={this.isLoading().toString()}
                            disabled={this.isLoading()}
                            onClick={this.props.onClickLoadingBtn}
                        >
                            {this.props.loadingBtnText}
                        </LoadingButton>
                    }
                </ModalFooter>
            </Modal>
        );
    }
}

export default BasicModal;
