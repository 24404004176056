import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserStore from '../../store/User';
import * as CommonStore from '../../store/Common';
import * as PaymentStore from '../../store/Payment';
import MessageError from '../shared/MessageError';
import ConfirmModal from '../shared/ConfirmModal';
import LoadingOverlay from '../shared/LoadingOverlay';
import T from 'i18n-react';

const Scroll = require('react-scroll');
const paymentExtension = require(`../../images/${process.env.REACT_APP_CLIENT}/payment-extension.png`);
const payRed = require('../../images/pay-red.png');

class PaymentExtension extends Component {

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('paymentExtension.title');

        if (this.props.initiated) {
            this.props.getPaymentExtensionData();
        }
    }

    componentWillReceiveProps(nextProps) {

        //when user refresh the browser - once we get the token - get account data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getPaymentExtensionData();
        }
    }

    redirectToDashboard = (e) => {
        this.props.history.push(`/myaccount/dashboard`);
    }

    postPaymentExtension = (e) => {
        this.props.postPaymentExtension();
    }

    render() {
        return (
            <div className="p-main">
                <div className="page-header">
                    <div className="page-title">{T.translate('paymentExtension.pageTitle')}</div>
                </div>

                <LoadingOverlay loading={this.props.paymentLoading} />

                <MessageError>{this.props.commonErrorMessage + this.props.paymentErrorMessage}</MessageError>

                {this.props.payExtensionRenderData.resourceLangKey !== '' && <div className="card bl-br mb-4 mb-sm-5">
                    <div className="card-body pay-echeck">
                        <img src={payRed} alt={T.translate('paymentExtension.pageTitle')} />
                        <div>
                            <h6 className="card-title text-danger">{T.translate('paymentExtension.youAreNotElegible')}</h6>
                            <p className="lead mb-0">{T.translate(this.props.payExtensionRenderData.resourceLangKey, this.props.payExtensionRenderData)}</p>
                        </div>
                    </div>
                </div>}

                <ConfirmModal
                    title={T.translate('paymentExtension.confirmPaymentExtension')}
                    confirmButtonText={T.translate('paymentExtension.confirmPaymentExtensionBtn')}
                    open={this.props.showConfirmModal}
                    onToggle={this.redirectToDashboard}>
                    {T.translate('paymentExtension.confirmPaymentExtensionMsg', this.props.payExtensionRenderData.extendedFormattedDates)}
                </ConfirmModal>

                {CommonStore.objectHasProperties(this.props.paymentArrangement) && <React.Fragment>
                    <div className="card bl-blue mb-4 mb-sm-5">
                        <div className="card-body pay-echeck">
                            <img src={paymentExtension} alt={T.translate('paymentExtension.pageTitle')} />
                            <div>
                                <h6 className="card-title mt-3">{T.translate('paymentExtension.youAreElegible')}</h6>
                                <p className="lead mb-0">{T.translate('paymentExtension.paymentExtensionConditions', this.props.payExtensionRenderData)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4 mb-sm-5">
                        <div className="col-md-8 col-lg-5">
                            <div className="renew-product-right">
                                <ul>
                                    <li>
                                        <label>{T.translate('paymentExtension.amountDue')}</label>
                                        <span>{"$ " + this.props.paymentArrangement.accountBalance}</span>
                                    </li>
                                    <li>
                                        <label>{T.translate('paymentExtension.billDueDate')}</label>
                                        <span>{CommonStore.formatDate(this.props.payExtensionRenderData.paymentDueDate, 'MM-DD-YYYY')}</span>
                                    </li>
                                    <li>
                                        <label>{T.translate('paymentExtension.disconnectDate')}</label>
                                        <span>{CommonStore.formatDate(this.props.payExtensionRenderData.disconnectDate, 'MM-DD-YYYY')}</span>
                                    </li>
                                    <li>
                                        <label>{T.translate('paymentExtension.extendedThrough')}</label>
                                        <span className="text-danger">{this.props.payExtensionRenderData.extendedFormattedDates.extendedDateFormmat1}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="alert alert-warning mb-4 mb-sm-5">
                        <em> <strong>{T.translate('paymentExtension.important')}</strong>{T.translate('paymentExtension.note')}</em>
                    </div>
                    <button className="btn btn-primary" onClick={this.postPaymentExtension}>{T.translate('paymentExtension.requestPaymentExtension')}</button>
                </React.Fragment>}
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.payment, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators }, dispatch)
)(PaymentExtension);
