import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import ReactPlaid from "react-plaid";
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import * as CommonStore from '../store/Common';
import * as PaymentStore from '../store/Payment';
import * as UserStore from '../store/User';
import ConfirmModal from './shared/ConfirmModal';
import LoadingButton from './shared/LoadingButton';
import MessageError from './shared/MessageError';

const time = require(`../images/${process.env.REACT_APP_CLIENT}/time.png`);
const bag = require(`../images/${process.env.REACT_APP_CLIENT}/bag.png`);

const initialState = {
    step: 1,
    accountNumber: '',
    lastName: '',
    zipCode: '',
    tenderTypeID: "1",
    amount: '',
    cardNumberValue: '',
    expiration: '',
    ccv: '',
    nameOnAccount: '',
    cardZipCode: '',
    bankAccountNumber: '',
    routingNumber: '',
    bankAccountType: '0',
    reactPlaidOpen: false,
    account_id: '',
    fields: [
        { name: 'accountNumber', isRequired: true, step: 1 },
        { name: 'lastName', isRequired: true, step: 1 },
        { name: 'zipCode', isRequired: true, step: 1 }, //2
        { name: 'amount', isRequired: false, isNumeric: true, isNumericError: false, step: 2, minPaymentAmount: 0, conditionField: '', isMinimumAmountError: false },
        { name: 'cardNumberValue', isRequired: true, step: 2, conditionField: 'tenderTypeID', conditionValue: '1', paymentStripeRequired: 'always' },
        { name: 'expiration', isRequired: true, step: 2, conditionField: 'tenderTypeID', conditionValue: '1', paymentStripeRequired: 'always' },
        { name: 'ccv', isRequired: true, step: 2, conditionField: 'tenderTypeID', conditionValue: '1', paymentStripeRequired: 'always' },
        { name: 'nameOnAccount', isRequired: true, step: 2, conditionField: 'tenderTypeID', conditionValue: '1', paymentStripeRequired: 'always' },
        { name: 'cardZipCode', isRequired: true, step: 2, conditionField: 'tenderTypeID', conditionValue: '1', paymentStripeRequired: 'always' }, //8
        { name: 'bankAccountNumber', isRequired: true, step: 2, conditionField: 'tenderTypeID', conditionValue: '2', paymentStripeRequired: false },
        { name: 'routingNumber', isRequired: true, step: 2, conditionField: 'tenderTypeID', conditionValue: '2', paymentStripeRequired: false }, //10
        { name: 'account_id', isRequired: true, step: 2, conditionField: 'tenderTypeID', conditionValue: '2', paymentStripeRequired: true }
    ],
    //CV ZD14560 2020/03/26 adding this properties to handle client stripe token generation
    infoCreditCardFeeModalVisible: false,
    cardZipCodeStripe: '',
    paymentStripePK: '',
    stripe: {},
    elements: {},
    cardNumberElement: {},
    cardExpiryElement: {},
    cardCvcElement: {},
    slimCD: {}
};

class QuickPayModal extends Component {

    constructor(props) {
        super(props);
               
        this.state = initialState;

        var paymentStripePK = this.props.getPaymentStripePK();

        this.state.paymentStripePK = this.props.getPaymentStripePK();

        //CV ZD14560 2020/03/26 adding this script to load the stripe main scripts
        if (paymentStripePK) {
            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v3/";
            script.async = true;
            document.body.appendChild(script);
        }

        if (this.props.systemProperties.isSlimCDEnabled) {
            const scriptSlimCD = document.createElement("script");
            scriptSlimCD.src = "https://stats.slimcd.com/soft/json/slimcd.js";
            scriptSlimCD.async = true;
            scriptSlimCD.onload = () => {
                this.setState({ slimCD: window["SlimCD"] });
            };
            document.body.appendChild(scriptSlimCD);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.quickPayAccountFound && this.state.step !== 2) {

            //set min payment amount from properties only for prepay
            let state = this.state;
            var amountField = state.fields[3];
            var isPrepay = nextProps.quickPayAccount.accountBillingTypeID === 1;
            amountField.minPaymentAmount = isPrepay ? this.props.systemProperties.myAcctAutopayMinimumPayment : 0;
            this.setState({ fields: state.fields, step: 2 });

            //CV ZD14560 2020/03/26 adding this validation if stripe PK is enable then load the stripe script, and build the respective objects
            if (this.state.paymentStripePK && this.state.tenderTypeID === '1') {
                const script = document.createElement("script");
                script.src = "https://js.stripe.com/v3/";
                script.async = true;
                script.onload = () => {
                    //Creating the main stripe objects
                    this.setState({ stripe: window.Stripe(this.state.paymentStripePK) });
                    this.setState({ elements: this.state.stripe.elements() });
                    //Create cardnumber element (this is the main element)
                    this.setState({ cardNumberElement: this.state.elements.create('cardNumber', { placeholder: ' ' }) });
                    this.state.cardNumberElement.mount('#txtCreditCardStripe');
                    //Create cardexpiry element
                    this.setState({ cardExpiryElement: this.state.elements.create('cardExpiry', { placeholder: ' ' }) });
                    this.state.cardExpiryElement.mount('#txtExpireStripe');
                    //Create cardcvc element
                    this.setState({ cardCvcElement: this.state.elements.create('cardCvc', { placeholder: ' ' }) });
                    this.state.cardCvcElement.mount('#txtCcvStripe');
                };
                document.body.appendChild(script);
            } else {
                //account was found, see if i4go payment is enabled
                this.props.getI4GoAuthorizeClient();
            }

            if (this.state.slimCD && this.state.tenderTypeID === '1' && this.props.systemProperties.isSlimCDEnabled) {
                const scriptSlimCD = document.createElement("script");
                scriptSlimCD.src = "https://stats.slimcd.com/soft/json/slimcd.js";
                scriptSlimCD.async = true;
                scriptSlimCD.onload = () => {
                    this.setState({ slimCD: window["SlimCD"] });
                };
                document.body.appendChild(scriptSlimCD);
            }
        } else if (nextProps.quickPaymentSuccess && this.state.step !== 3) {
            this.setState({ step: 3 });
        }
    }

    onChange = (e) => {

        let fields = this.state.fields.filter(x => x.name === e.target.name);

        if (fields && fields.length === 1) {

            let field = fields[0];

            if (field.isRequired && field.isRequiredError && e.target.value) {
                field.isRequiredError = false;
            }

            if (field.isNumeric && e.target.value && validator.isDecimal(e.target.value)) {
                field.isNumericError = false;
            }
            //clean amount field if min payment amount is met
            if (field.isNumeric && e.target.value && field.minPaymentAmount &&
                parseFloat(e.target.value) >= field.minPaymentAmount) {
                field.isMinimumAmountError = false;
            }

            if (field.name == 'amount') {
                if (parseInt(e.target.value) == 0) {
                    e.target.style.borderColor = 'white';
                }
                else {
                    e.target.style.border = '4px solid red';
                }
            }
        }

        this.setState({ [e.target.name]: e.target.value });
    }

    isFormValid = () => {

        var state = this.state;
        var valid = true;
        var paymentStripeRequired = this.props.paymentStripe ? true : false;

        this.state.fields.map((field) => {

            if (field.step === state.step) {
                field.value = this.state[field.name];
                field.isRequiredError = false;

                if (!field.conditionField) {

                    if (field.isRequired && !field.value) {
                        field.isRequiredError = true;
                        valid = false;
                    } else if (field.isNumeric && field.value && !validator.isDecimal(field.value)) {
                        //validate numeric value for amount field
                        field.isNumericError = true;
                        valid = false;
                    } else if (field.minPaymentAmount && parseFloat(field.value) < field.minPaymentAmount) {
                        //validate min payment amount for amount field - ONLY PREPAY
                        field.isMinimumAmountError = true;
                        valid = false;
                    }

                } else if (field.conditionField && field.conditionValue === state[field.conditionField] && (field.paymentStripeRequired === paymentStripeRequired || field.paymentStripeRequired === 'always')) {

                    if (field.isRequired && !field.value) {
                        field.isRequiredError = true;
                        valid = false;
                    }

                    if (field.isNumeric && field.value && !validator.isDecimal(field.value)) {
                        field.isNumericError = true;
                        valid = false;
                    }
                }

            }

        });

        this.setState(state);
        
        if (valid) {
            this.props.setQuickPayError('');
        } else {
            this.props.setQuickPayError(T.translate("common.thereAreMissingFields"));
        }

        return valid;
    }

    toggle = () => {
        this.setState({ step: 1 });
        this.props.clearUserState();
        this.setState({ ...initialState });
        this.props.setQuickPayModalVisible(!this.props.quickPayModalVisible);
    }

    next = () => {
        if (this.isFormValid()) {
            this.props.getAccount(this.state);
        }
    }

    back = () => {
        this.setState({ step: 1 });
    }

    validateAmountTreshold = () => {

        var state = this.state;
        var valid = true;
        var paymentStripeRequired = this.props.paymentStripe ? true : false;

        this.state.fields.map((field) => {

            if (field.step === state.step && field.name === "amount") {
                field.value = this.state[field.name];
                field.isRequiredError = false;

                if (!field.conditionField) {
                    if (field.isRequired && !field.value) {
                        field.isRequiredError = true;
                        valid = false;
                    } else if (field.isNumeric && field.value && !validator.isDecimal(field.value)) {
                        //validate numeric value for amount field
                        field.isNumericError = true;
                        valid = false;
                    } else if (field.minPaymentAmount && parseFloat(field.value) < field.minPaymentAmount) {
                        //validate min payment amount for amount field - ONLY PREPAY
                        field.isMinimumAmountError = true;
                        valid = false;
                    }
                } 

            }

        });

        this.setState(state);

        if (valid) {
            this.props.setQuickPayError('');
        } else {
            this.props.setQuickPayError(T.translate("common.thereAreMissingFields"));
        }

        return valid;
    }

    pay = () => {
        //CV ZD14560 2020/03/26 adding this validation if stripe PK is enable,  then the token will be and the payment account will be submited
        if (this.state.paymentStripePK && this.validateAmountTreshold() && this.state.tenderTypeID === '1') {
            var options = {
                cardZipCode: this.state.cardZipCode
            };
            //Create stripe token for this card, after that it is submited to the backend among with the other card information not sensitive
            this.state.stripe.createToken(this.state.cardNumberElement, options).then((result) => {                
                if (result.error) {
                    // Inform the customer that there was an error generating the token.
                    this.props.setPaymentError(result.error.message);
                } else {
                    this.setState({ paymentToken: result.token.id });
                    this.setState({ cardNumberValue: '************' + result.token.card.last4 });
                    this.setState({ expireMonth: result.token.card.exp_month });
                    this.setState({ expireYear: result.token.card.exp_year });
                    this.setState({ postalCode: this.state.cardZipCode });
                    this.setState({ ccv: 123 });
                    this.props.postAccountQuickPay(this.state);
                }
            });
        } else if (this.props.systemProperties.isSlimCDEnabled && this.state.tenderTypeID === '1') {
            let processor = this.state.slimCD;
            let expireMonth = 0;
            let expireYear = 0;
            let firstName = "";
            let lastName = "";
            let props = this.props;
            let state = this.state;

            if (this.state.expiration.split('/').length === 2) {
                expireMonth = this.state.expiration.split('/')[0];
                expireYear = this.state.expiration.split('/')[1];
            }
            if (this.state.nameOnAccount.split(' ').length === 1) {
                firstName = this.state.nameOnAccount.split(' ')[0];
            }
            if (this.state.nameOnAccount.split(' ').length >= 2) {
                firstName = this.state.nameOnAccount.split(' ')[0];
                lastName = this.state.nameOnAccount.split(' ')[1];
            }

            processor.Transact.ProcessTransaction({
                "username": props.systemProperties.slimCDUsername,
                "transtype": "LOAD",
                "amount": "0.00",
                "cardnumber": this.state.cardNumberValue,
                "expmonth": expireMonth,
                "expyear": expireYear,
                "cvv2": this.state.ccv,
                "first_name": firstName,
                "last_name": lastName
            }, function (reply) {
                if (reply.datablock != undefined && reply.datablock.gateid != undefined)
                    if (reply.response == "Success") {
                        state.public_token = reply.datablock.gateid;
                        props.postAccountQuickPay(state);
                    }
                    else {
                        alert('There was a problem processing the payment:\r\n' + reply.description);
                    }
            });
        }
        else {
            if (this.isFormValid()) {
                this.props.postAccountQuickPay(this.state);
            }
        }
    }

    openCreditCardFeeModal = () => {
        this.setState({ infoCreditCardFeeModalVisible: true });
    }

    closeCreditCardFeeModal = () => {
        this.setState({ infoCreditCardFeeModalVisible: false });
    }


    reactPlaidSuccess = (token, metaData) => {
        this.setState({ account_id: metaData.account_id, public_token: metaData.public_token, reactPlaidOpen: false });
    }

    onTokenizeCreditDetailsClick = (e) => {
        this.setState({ tokenizeCreditDetails: !this.state.tokenizeCreditDetails });
    }

    render() {
        return (
            <Modal className="quick-pay-modal" isOpen={this.props.quickPayModalVisible} size="lg">
                <ModalHeader toggle={this.toggle} className="bg-primary"><span className="text-white">{T.translate('quickPay.title')}</span></ModalHeader>
                <ModalBody>

                    {this.state.step === 1 && <div>
                        <div className="form-group">
                            <label htmlFor="accountNumber">{T.translate('quickPay.accountNumber')}</label>
                            <input type="text" id="accountNumber" name="accountNumber" value={this.state.accountNumber} onChange={this.onChange} className={"form-control " + (this.state.fields[0].isRequiredError ? 'is-invalid' : '')} />
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">{T.translate('quickPay.lastName')}</label>
                            <input type="text" id="lastName" name="lastName" value={this.state.lastName} onChange={this.onChange} className={"form-control " + (this.state.fields[1].isRequiredError ? 'is-invalid' : '')} />
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="zipCode">{T.translate('quickPay.mailingAddressZipCode')}</label>
                            <input type="text" id="zipCode" name="zipCode" value={this.state.zipCode} onChange={this.onChange} className={"form-control " + (this.state.fields[2].isRequiredError ? 'is-invalid' : '')} />
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="payment-type">{T.translate('quickPay.paymentType')}</label>
                            <select className="form-control" id="tenderTypeID" name="tenderTypeID" onChange={this.onChange} value={this.state.tenderTypeID}>
                                {this.props.systemProperties.myAcctPaymentTenderTypes.creditCard && <option value="1">{T.translate('quickPay.creditCard')}</option>}
                                {this.props.systemProperties.myAcctPaymentTenderTypes.eCheck && <option value="2">{T.translate('quickPay.eCheck')}</option>}
                            </select>
                        </div>

                        <MessageError>{this.props.quickPayErrorMessage}</MessageError>

                        <LoadingButton
                            className="btn btn-primary btn-lg pull-right"
                            disabled={this.props.userLoading || this.props.commonLoading}
                            loading={this.props.userLoading || this.props.commonLoading ? 'true' : 'false'}
                            onClick={this.next}>
                            {T.translate('quickPay.nextStep')}
                        </LoadingButton>

                    </div>}

                    {this.state.step === 2 && <div>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <div className="card h-100">
                                    <div className="card-body d-flex">
                                        <div className="super-sever-flex-box position-relative">
                                            <img src={time} alt="time icon" />
                                            <div className="align-self-center">
                                                <p className="lead font-weight-normal font-italic mb-0">{this.props.quickPayAccount.accountBillingTypeID === 1 ? T.translate('quickPay.daysRemaining') : T.translate('quickPay.amountDue') }: </p>
                                                {this.props.showCreditBalanceOnQuickPay &&
                                                    <h6 className="card-title-lg mb-0">{this.props.quickPayAccount.accountBillingTypeID === 1 ? this.props.quickPayAccount.daysRemaining : '$' + CommonStore.formatDecimal(this.props.quickPayAccount.totalAmountDue)}</h6>
                                                }
                                                {!this.props.showCreditBalanceOnQuickPay &&
                                                    <h6 className="card-title-lg">{this.props.quickPayAccount.accountBillingTypeID === 1 ? this.props.quickPayAccount.daysRemaining : '$' + CommonStore.formatDecimal(this.props.quickPayAccount.totalAmountDue)}</h6>
                                                }
                                                {this.props.showCreditBalanceOnQuickPay && (this.props.quickPayAccount.totalAmountDue <= 0 && this.props.quickPayAccount.accountBalance > 0) &&
                                                    <p class="lead font-weight-normal font-italic mb-0 cb-label">
                                                        <b>{T.translate('quickPay.creditBalance') + ' ($' + CommonStore.formatDecimal(this.props.quickPayAccount.accountBalance) + ')'} </b>
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <div className="card h-100">
                                    <div className="card-body d-flex">
                                        <div className="super-sever-flex-box position-relative"> <img src={bag} alt="time icon" />
                                            <div className="align-self-center">
                                                <p className="lead font-weight-normal font-italic mb-0">{this.props.quickPayAccount.accountBillingTypeID === 1 ? T.translate('quickPay.availableBalance') : T.translate('quickPay.paymentDue') }: </p>
                                                <h6 className="card-title-lg">
                                                    {this.props.quickPayAccount.accountBillingTypeID === 1 ? '$' + CommonStore.formatDecimal(this.props.quickPayAccount.availableBalance) : CommonStore.formatDate(this.props.quickPayAccount.paymentDueDate) }
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 mb-4">
                            <div className="col-lg-6 form-group">
                                <a href="javascript:;" className="btn btn-outline-dark btn-block">
                                    {this.props.quickPayAccount.accountBillingTypeID === 1 ? T.translate('quickPay.paySuggestedAmount') + CommonStore.formatDecimal(this.props.quickPayAccount.suggestedPayment) : T.translate('quickPay.payTotalDue') + this.props.quickPayAccount.totalAmountDue}
                                </a>
                            </div>
                            <div className="col-lg-6 form-group">
                                <div className="pay-with-diffrent">
                                    <label htmlFor="amount">{T.translate('quickPay.payDifferentAmount')}</label>
                                    <input type="text" id="amount" name="amount" value={this.state.amount} onChange={this.onChange} className={"form-control " + (this.state.fields[3].isNumericError || this.state.fields[3].isMinimumAmountError ? 'is-invalid' : '')} />
                                    <div className="invalid-feedback">{this.state.fields[3].isMinimumAmountError ? T.translate('common.thisFieldIsLessThanMinimum') : T.translate('common.thisFieldIsNotValid') }</div>
                                </div>
                            </div>
                        </div>
                        <form>
                            {this.state.tenderTypeID === '1' && <div>
                                {!this.props.getPaymentStripePK() && <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group credit-card-input">
                                            <label htmlFor="cardNumberValue">{T.translate('quickPay.cardNumber')}</label>
                                            <InputMask mask="9999-9999-9999-9999" placeholder="#### #### #### ####" id="cardNumberValue" name="cardNumberValue" value={this.state.cardNumberValue} onChange={this.onChange} className={"form-control " + (this.state.fields[4].isRequiredError ? 'is-invalid' : '')} />
                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="expiration">{T.translate('quickPay.expiration')}</label>
                                            <InputMask mask="99/99" placeholder="MM/YY" id="expiration" name="expiration" value={this.state.expiration} onChange={this.onChange} className={"form-control " + (this.state.fields[5].isRequiredError ? 'is-invalid' : '')} />
                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="ccv">{T.translate('quickPay.securityCode')}</label>
                                            <input type="text" id="ccv" name="ccv" maxLength={4} value={this.state.ccv} onChange={this.onChange} className={"form-control " + (this.state.fields[6].isRequiredError ? 'is-invalid' : '')} />
                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                        </div>
                                    </div>
                                </div>}

                                {this.props.getPaymentStripePK() &&
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group credit-card-input">
                                                    <label htmlFor="cardNumberValue">{T.translate('accountPay.cardNumber')}</label>
                                                    <div id="txtCreditCardStripe" style={{ border: '1px solid #f1f3f4', height: '54px', padding: '18px 60px', borderRadius: '2rem', backgroundColor: '#f1f3f4' }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="expiration">{T.translate('accountPay.expiration')}</label>
                                                    <div id="txtExpireStripe" style={{ border: '1px solid #f1f3f4', height: '54px', padding: '18px 17px', borderRadius: '2rem', backgroundColor: '#f1f3f4' }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="ccv">{T.translate('accountPay.securityCode')}</label>
                                                    <div id="txtCcvStripe" style={{ border: '1px solid #f1f3f4', height: '54px', padding: '18px 17px', borderRadius: '2rem', backgroundColor: '#f1f3f4' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="nameOnAccount">{T.translate('quickPay.nameOnCard')}</label>
                                            <input type="text" id="nameOnAccount" name="nameOnAccount" value={this.state.nameOnAccount} onChange={this.onChange} className={"form-control " + (this.state.fields[7].isRequiredError ? 'is-invalid' : '')} />
                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="cardZipCode">{T.translate('quickPay.billingZip')}</label>
                                            <input type="text" name="cardZipCode" id="cardZipCode" value={this.state.cardZipCode} onChange={this.onChange} className={"form-control " + (this.state.fields[8].isRequiredError ? 'is-invalid' : '')} />
                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                        </div>
                                    </div>
                                </div>
                                {this.props.systemProperties.paymentusCreditCardCharge && this.props.systemProperties.paymentusCreditCardCharge != "" &&
                                    <React.Fragment>

                                        {!!this.props.systemProperties.paymentusCreditCardCharge &&
                                           this.props.systemProperties.paymentusCreditCardCharge > 0 &&
                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <p><FontAwesomeIcon className="card-flex-icon mr-2" icon={faInfoCircle} onClick={this.openCreditCardFeeModal} /> {T.translate('quickPay.paymentusCreditCardFeeWarning', { paymentusCreditCardFee: this.props.systemProperties.paymentusCreditCardCharge })}
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        
                                        <ConfirmModal
                                            title={T.translate('suggestedPayment.paymentsIncursTitle', { paymentMethod: T.translate('quickPay.paymentusCreditCard') })}
                                            open={this.state.infoCreditCardFeeModalVisible}
                                            onToggle={this.closeCreditCardFeeModal}>
                                            <div>
                                                <p>{T.translate('suggestedPayment.paymentsIncursBodyOne')}</p>
                                            </div>
                                        </ConfirmModal>
                                    </React.Fragment>
                                }                                
                            </div>}

                            {this.state.tenderTypeID === '2' && <div>

                                {!this.props.paymentStripe && <div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="accountNumber">{T.translate('accountPay.bankAccountNumber')}</label>
                                                <input type="text" id="bankAccountNumber" name="bankAccountNumber" maxLength={17} value={this.state.bankAccountNumber} onChange={this.onChange} className={"form-control " + (this.state.fields[9].isRequiredError ? 'is-invalid' : '')} />
                                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="bankAccountType">{T.translate('autopay.bankAccountType')}</label>
                                                <select className="form-control" id="bankAccountType" name="bankAccountType" value={this.state.bankAccountType} onChange={this.onChange}>
                                                    {
                                                        this.props.systemProperties.bankAccountTypes.map(function (type) {
                                                            return <option key={type.value} value={type.value}>{type.name}</option>;
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="routingNumber">{T.translate('accountPay.routingNumber')}</label>
                                                <input id="routingNumber" name="routingNumber" maxLength={9} value={this.state.routingNumber} onChange={this.onChange} className={"form-control " + (this.state.fields[10].isRequiredError ? 'is-invalid' : '')} />
                                                <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                            </div>
                                        </div>
                                        {this.props.systemProperties.paymentusECheckCharge && this.props.systemProperties.paymentusECheckCharge != "" &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <br />
                                                    <p>{T.translate('quickPay.paymentuseCheckFeeWarning', { paymentuseCheckFee: this.props.systemProperties.paymentusECheckCharge })}</p>
                                                </div>
                                            </div>}
                                    </div>
                                </div>}

                                {this.props.paymentStripe && <div>

                                    <div className="row form-group">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button type="button" className="btn" onClick={() => this.setState({ reactPlaidOpen: true })}>{T.translate('accountPay.clickSelectBankAccount')}</button>
                                                {this.state.fields[11].isRequiredError && <div className="invalid-feedback" style={{ display: 'block' }}>{T.translate('common.thisFieldIsRequired')}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <ReactPlaid
                                        clientName={this.props.paymentStripe.clientName}
                                        product={this.props.paymentStripe.product}
                                        apiKey={this.props.paymentStripe.apiKey}
                                        env={this.props.paymentStripe.env}
                                        selectAccount={this.props.paymentStripe.selectAccount}
                                        open={this.state.reactPlaidOpen}
                                        onSuccess={(token, metaData) => this.reactPlaidSuccess(token, metaData)}
                                        onExit={() => this.setState({ reactPlaidOpen: false })}
                                    />
                                </div>}

                            </div>}                           
                            
                            <MessageError>{this.props.quickPayErrorMessage}</MessageError>

                            <div className="diverter mt-0 mb-4" /> 

                            <div>
                                <p>{T.translate("accountPay.tokenizeCreditDetails")}</p>
                            </div>

                            <div className="checkbox">
                                <label>
                                    <input className="coupon_question sign-up-checkbox mr-1" name="tokenizeCreditDetails" id="tokenizeCreditDetails" value="1" type="checkbox" checked={this.state.tokenizeCreditDetails} onClick={this.onTokenizeCreditDetailsClick} />
                                        {T.translate("accountPay.agreeOnTokenizeCreditDetails")}
                                 </label>
                             </div>

                            <button type="button" className="btn" disabled={this.props.userLoading} onClick={this.back}>{T.translate('common.back')}</button>

                            <LoadingButton
                                type="button"
                                className="btn btn-primary pull-right"
                                disabled={this.props.userLoading || !this.state.tokenizeCreditDetails}
                                loading={this.props.userLoading ? 'true' : 'false'}
                                onClick={this.pay}>
                                {T.translate('quickPay.payNow')}
                            </LoadingButton>

                        </form>
                    </div>}

                    {this.state.step === 3 && <div>
                        <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                            <div className="d-flex">
                                <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                <div>
                                    <p>{T.translate('quickPay.yourPaymentHasBeenAccepted')}</p>
                                    <p className="font-weight-bold">{T.translate('quickPay.yourPaymentConfirmationNumberIs')}</p>
                                    <p>{this.props.quickPayConfirmationNumber}</p>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn pull-right" onClick={this.toggle}>{T.translate('common.close')}</button>
                    </div>}

                </ModalBody>
            </Modal>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.common, ...state.payment }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators }, dispatch)
)(QuickPayModal);
