import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as CommonStore from '../store/Common';
import * as AccountStore from '../store/Account';
import * as UserStore from '../store/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import MessageError from './shared/MessageError';
import LoadingButton from './shared/LoadingButton';
import LoadingOverlay from './shared/LoadingOverlay';
import ConfirmModal from './shared/ConfirmModal';
import Password from './shared/PasswordInput';

import T from 'i18n-react';

const Scroll = require('react-scroll');
const qs = require('querystringify');

class AccountResetPassword extends Component {

    constructor(props) {
        super(props);

        const params = qs.parse(props.location.search);
        var fields = [];

        fields['new-password'] = { isRequired: true, isValid: true, value: '', isPassword: true, errorType: '' };
        fields['re-enter-new-password'] = { isRequired: true, isValid: true, value: '', matchField: 'new-password', errorType: '' };

        this.state = {
            fields: fields,
            token: params.lit,
            loginStarted: false,
            passwordTooltipOpen: false
        };

    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('accountResetPassword.title');

        this.props.clearUserState();
    }

    componentDidUpdate(prevProps) {
        if (this.props.initiated !== prevProps.initiated) {
            this.validatePasswordReset();
        }
    }

    validatePasswordReset = () => {
        const params = qs.parse(this.props.location.search);
        if (params.lit) {
            this.props.validatePasswordReset({ token: this.state.token });
        }
    }

    onRequestNewLink = (e) => {
        this.props.newPasswordResetLink({ token: this.state.token });        
    }

    goToLogin = () => {
        this.props.history.push('/');
    }

    updatePassword = () => {
        if (this.isFormValid()) {
            this.props.updatePasswordWithToken({ token: this.props.resetPwdInfo.resetToken, fields: this.state.fields });
        }
    }

    onChange = (e) => {

        this.hidePasswordTooltip();

        let state = this.state;
        var field = state.fields[e.target.id];

        field.value = e.target.value.trim();

        if (field.isRequired) {
            if (!field.isValid) {

                switch (field.errorType) {
                    case 'thisFieldIsRequired':
                        if (field.value.trim() !== '') {
                            field.isValid = true;
                        }
                        break;
                    case 'thePasswordDoesNotMatch':
                        var matchFieldValue = state.fields[field.matchField].value;

                        if (field.value === matchFieldValue) {
                            field.isValid = true;
                        }
                        break;
                    case 'passwordRulesError':
                        if (field.isPassword && CommonStore.checkPasswordRules(field.value)) {
                            field.isValid = true;
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        this.setState({ state });
    }

    isFormValid = () => {
        let state = this.state;
        var valid = true;
        state.passwordTooltipOpen = false;

        var objProperties = Object.keys(state.fields);
        objProperties.map((propertyName) => {
            var field = state.fields[propertyName];
            field.isValid = true;
            field.messageError = '';
            field.errorType = '';

            if (field.isRequired && field.value.trim() === '') {
                field.isValid = false;
                field.errorType = 'thisFieldIsRequired';
                field.messageError = T.translate('common.thisFieldIsRequired');
            }

            if (field.isValid && field.matchField) {
                var matchFieldValue = state.fields[field.matchField].value;
                field.isValid = field.value === matchFieldValue;
                field.errorType = 'thePasswordDoesNotMatch';
                field.messageError = T.translate('accountResetPassword.thePasswordDoesNotMatch');
            }

            if (field.isValid && field.isPassword) {
                field.isValid = CommonStore.checkPasswordRules(field.value);
                field.errorType = 'passwordRulesError';
                field.messageError = T.translate('common.passwordRulesError');

                if (!field.isValid) {
                    state.passwordTooltipOpen = true;
                }
            }

            if (field.isValid && field.messageError === undefined) {
                field.errorType = 'thisFieldIsRequired';
                field.messageError = T.translate('common.thisFieldIsRequired');
            }

            valid = valid && field.isValid;

            return field;
        });

        this.setState(state.fields);

        if (valid) {
            this.props.setCommonError('');
        } else {
            this.props.setCommonError(T.translate('common.thereAreMissingFields'));
        }

        return valid;
    }

    setValidationStyle = (fieldName) => {
        let state = this.state;
        var field = state.fields[fieldName];
        return field.isValid ? '' : 'is-invalid';
    }

    showPasswordTooltip = () => {
        this.setState({ passwordTooltipOpen: true });
    }

    hidePasswordTooltip = () => {
        this.setState({ passwordTooltipOpen: false });
    }

    getMessageError = () => {
        var errorMessage = this.props.userErrorMessage + this.props.accountErrorMessage + this.props.commonErrorMessage;

        if (this.props.userSourceError === 'ExpiredToken') {
            var textToAppend = T.translate('accountResetPassword.requestNewToken');
            return errorMessage + ' ' + textToAppend;
        }

        return errorMessage;
    }

    render() {
        return (
            <div className="my-account-md-container">
                <div className="my-account-md-content">
                    <div className="page-header">
                        <div className="page-title">{T.translate('accountResetPassword.pageTitle')}</div>
                    </div>
                    <div className="diverter" />

                    <LoadingOverlay loading={this.props.userLoading || this.props.accountLoading} />

                    {!this.props.forgotPasswordConfirmation && this.getMessageError() &&
                        <MessageError>
                            <div>
                                <p>{this.getMessageError()}</p>
                                {this.props.userSourceError === 'ExpiredToken' &&
                                    <LoadingButton
                                        type="button"
                                        className="btn btn-light"
                                        onClick={this.onRequestNewLink}
                                        loading={this.props.userLoading ? 'true' : 'false'}>
                                        {T.translate('accountResetPassword.newResetPasswordLink')}
                                    </LoadingButton>
                                }
                            </div>
                        </MessageError>
                    }

                    {this.props.forgotPasswordConfirmation &&
                        <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                            <div className="d-flex">
                                <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                <p>
                                {T.translate('accountResetPassword.ifEmailAssociated', this.props.systemProperties)}
                                </p>
                            </div>
                        </div>
                    }

                    {this.props.resetPwdInfo && this.props.resetPwdInfo.resetToken !== '' && <React.Fragment>
                        <h6 className="card-title mb-3">{T.translate('accountResetPassword.resetYourPassword')}</h6>
                        <p>{T.translate('accountResetPassword.resetPasswordParagraph1')}</p>
                        <p>{T.translate('accountResetPassword.resetPasswordParagraph2')}</p>
                        <div className="form-group mt-4" >
                            <Password id="new-password"
                                className={this.setValidationStyle('new-password')}
                                labelText={T.translate('accountResetPassword.newPassword')}
                                maxLength={30}
                                messageError={this.state.fields['new-password'].messageError}
                                onChange={this.onChange}
                                showPasswordRules
                                value=""
                                showTooltip={this.showPasswordTooltip}
                                hideTooltip={this.hidePasswordTooltip}
                                tooltipOpen={this.state.passwordTooltipOpen}
                            />
                        </div>
                        <div className="form-group mt-4">
                            <Password
                                id="re-enter-new-password"
                                className={this.setValidationStyle('re-enter-new-password')}
                                labelText={T.translate('accountResetPassword.reenterPassword')}
                                maxLength={30}
                                messageError={this.state.fields['re-enter-new-password'].messageError}
                                onChange={this.onChange}
                                value=""
                            />
                        </div>

                        <LoadingButton className="btn btn-primary btn-lg" onClick={this.updatePassword}>{T.translate('accountResetPassword.continue')}</LoadingButton>
                    </React.Fragment>}

                    <ConfirmModal
                        title={T.translate('accountResetPassword.confirmTitle')}
                        confirmButtonText={T.translate('accountResetPassword.continue')}
                        open={this.props.resetPwdInfo && this.props.resetPwdInfo.resetTokenSuccess}
                        onConfirm={this.goToLogin}>
                        {T.translate('accountResetPassword.confirmText')}
                    </ConfirmModal>

                    <NavLink to="/" className="a-link ml-0 ml-sm-4 mt-3 mt-sm-0 d-block d-sm-inline-block">{T.translate('accountResetPassword.backToLogin')}</NavLink>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(AccountResetPassword);
